import axios from "axios";

export const dispatchOrder = (token, data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/order/dispatch/";
  return axios.patch(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const dispatchPreview = (token, formData) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/order/dispatchPreview";
  return axios.post(url, formData, {
      headers: { 
        Authorization: `Bearer ${token}`
      },
    }
  );
}

export const sendDispatchMail = (token, formData) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/order/send-dispatch-mail";
  return axios.post(url, formData, {
      headers: { 
        Authorization: `Bearer ${token}`
      },
    }
  );
}

export const masterImportTrigger = (token, formData) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/order/send-dispatch-details";
  return axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
