import React, { useState } from "react";
import "./support.css";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { supportQuery } from "../../Services/userService";
import { useSelector } from "react-redux";
import { errorToast, successToast } from "../../Services/toastService";
import { useLocale } from "../../lib/customHooks/useLocale";

const Support = () => {
  const {t} = useLocale("common")
  let [supportFile, setSupportFile] = useState(null);
  let [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.token);
  const [supportForm, setSupportForm] = useState({
    issueDesc: "",
    details: "",
  });

  const submitSupport = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("supportAttachment", supportFile);
      formData.append("issueDesc", supportForm.issueDesc);
      formData.append("details", supportForm.details);
      const response = await supportQuery(formData, token);

      if (response.status === 200) {
        successToast("Support request sent")
        setIsLoading(false);
        e.target.reset();
      }
    } catch (error) {
      errorToast("Something went wrong")
      setIsLoading(false);
    }
  };

  const handleInputs = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSupportForm({ ...supportForm, [name]: value });
  };
  return (
    <Container>
      <Form onSubmit={submitSupport}>
        <h2 className="my-4">{t("Support")}</h2>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>{t("Issue Description")}</Form.Label>
          <Form.Control
            type="text"
            name="issueDesc"
            placeholder=""
            required
            onChange={handleInputs}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>{t("Describe your issue in details")}</Form.Label>
          <Form.Control
            as="textarea"
            name="details"
            rows={3}
            required
            onChange={handleInputs}
          />
        </Form.Group>

        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>{t("Attachment (optional)")}</Form.Label>
          <Form.Control
            type="file"
            name="file"
            onChange={(e) => {
              setSupportFile(e.target.files[0]);
            }}
          />
        </Form.Group>

        <Button variant="dark" type="submit" className="mb-4" disabled={isLoading}>
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            t("Send")
          )}
        </Button>
      </Form>
    </Container>
  );
};

export default Support;
