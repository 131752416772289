import axios from "axios";

export const addShipment = (token, data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/shipment/add";
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getShipment = (token) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/shipment/list";
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateShipment = (token, data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/shipment/update";
  return axios.patch(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getCountryList = (token) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/country/list";
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addPriceSlab = (token, data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/shipment/add-price-slabs";
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getPriceSlabList = (token,id) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/shipment/get-price-slabs";
  let params = {};
  params.id = id;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params
  });
};

export const updatePriceSlab = (token,data) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/shipment/update-price-slabs";
  return axios.patch(url,data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
