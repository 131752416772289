import React, { useState } from "react";
import "./login.css";
import { useDispatch } from "react-redux";
import { setLogin } from "../../State/store";
import { Spinner } from "react-bootstrap";
import { userLogin } from "../../Services/userService";

function Login() {
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [loginMessage, setLoginMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const validateEmail = (input) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(input);
  };

  const login = async () => {
    if (!validateEmail(loginForm.email)) {
      setIsValidEmail(false);
      setIsLoading(false);
      return;
    } else {
      setIsValidEmail(true)
    }
    try {
      setIsLoading(true)
      const response = await userLogin(loginForm.email, loginForm.password);
      if (response.status === 200) {
        dispatch(
          setLogin({
            user: response.data.user,
            token: response.data.token,
          })
        );
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (err.response?.status === 401) {
        setLoginMessage("Invalid Credentials");
      } else if (err.response?.status === 403) {
        setLoginMessage("Access Revoked");
      } else {
        setLoginMessage("Something went wrong");
      }
    }
  };

  const loginOnEnter = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  return (
    <div
      className="login template d-flex justify-content-center align-items-center vh-100"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(19, 91, 117, 0.73)), url('${process.env.PUBLIC_URL}/images/home-img.jpg')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="form_container p-5 rounded bg-white">
        <form>
          <img
            src="/images/cynoLogo.png"
            className="cyno-logo"
            alt="Cyno Logo"
          />
          <div className="mb-2">
            <label className="login_label">Email</label>
            <input
              type="email"
              placeholder="Enter Email"
              className="form-control"
              value={loginForm.email || ""}
              onChange={(e) => setLoginForm({ ...loginForm, email: e.target.value })}
              required
            />
            {!isValidEmail && (
              <p className="text-danger">Please enter a valid email</p>
            )}
          </div>

          <div className="mb-4">
            <label className="login_label">Password</label>
            <input
              type="password"
              placeholder="Enter Password"
              className="form-control"
              value={loginForm.password || ""}
              onKeyDown={loginOnEnter}
              onChange={(e) => setLoginForm({ ...loginForm, password: e.target.value })}
              required
              autoComplete="on"
            />
          </div>

          {loginMessage && (
            <p className="text-danger text-center">{loginMessage}</p>
          )}
          <div className="d-grid">
              {
                isLoading?
                (
                  <div className="text-center ">
                <Spinner animation="border" role="status" className="text-primary">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
                ):(
                  <button
              className="btn btn-primary"
              type="button"
              onClick={login}
            >
              Sign In
            </button>
                )
              }
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
