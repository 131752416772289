import React, { useState } from "react";
import { getTrackingDetails } from "../../Services/tracking";
import { errorToast } from "../../Services/toastService";
import { useSelector } from "react-redux";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import TrackingOrderCard from "./trackingOrderCard";
import moment from "moment";

const TrackingDialog = ({ item }) => {
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const theme = useSelector((state) => state.mode);
  const [trackingModalShow, setTrackingModalShow] = useState(false);
  const [eventDetails, setEventDetails] = useState([]);
  const [trackingDetails, setTrackingDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [trackingValue, setTrackingValue] = useState(1);
  const invalidStr = ["NYD", "PROCESSING", "HOLD", "CANCELED"];

  const handleTrackingModal = async (id) => {
    try {
      setIsLoading(true);
      setTrackingModalShow(true);
      const res = await getTrackingDetails(token, id);
      const data = res.data;
      setEventDetails(data.events);
      setTrackingDetails(data);
      if (data.shipment.statusMilestone === "pending") setTrackingValue(1);
      else if (data.shipment.statusMilestone === "info_received")
        setTrackingValue(2);
      else if (data.shipment.statusMilestone === "in_transit")
        setTrackingValue(3);
      else if (
        ["out_for_delivery", "available_for_pickup", "failed_attempt"].includes(
          data.shipment.statusMilestone
        )
      )
        setTrackingValue(4);
      else if (data.shipment.statusMilestone === "delivered")
        setTrackingValue(5);
      setIsLoading(false);
    } catch (err) {
      setTrackingModalShow(false);
      errorToast(err.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <>
      <div
        key={item.id}
        onClick={() => {
          !invalidStr.includes(item?.trackingId?.toUpperCase()) && handleTrackingModal(item.id);
        }}
        className={!invalidStr.includes(item?.trackingId?.toUpperCase()) ? "text-primary" : ""}
        role={!invalidStr.includes(item?.trackingId?.toUpperCase())? "button" : ""}
      >
        { item.trackingId }
      </div>

      <Modal
        show={trackingModalShow}
        onHide={() => {
          setTrackingModalShow(!trackingModalShow);
        }}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Tracking Order</Modal.Title>
        </Modal.Header>
        {isLoading ? (
          <div className="text-center m-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Modal.Body>
            {trackingDetails &&
              trackingDetails.shipment &&
              trackingDetails.shipment.originCountryCode &&
              trackingDetails.shipment.destinationCountryCode && (
                <div className="text-center d-flex justify-content-evenly p-2 bg-light flag_custom">
                  <div className="d-flex flex-column">
                    <span>
                      <img
                        className="img_custom"
                        src={`https://flagpedia.net/data/flags/normal/${trackingDetails.shipment.originCountryCode.toLowerCase()}.png`}
                        alt=""
                      />
                    </span>
                    <span>{trackingDetails.shipment.originCountryCode}</span>
                  </div>

                  <span className="d-flex align-items-center justify-content-center">
                    -&gt;
                  </span>
                  <div className="d-flex flex-column">
                    <span>
                      <img
                        className="img_custom"
                        src={`https://flagpedia.net/data/flags/normal/${trackingDetails.shipment.destinationCountryCode.toLowerCase()}.png`}
                        alt=""
                      />
                    </span>
                    <span>
                      {trackingDetails.shipment.destinationCountryCode}
                    </span>
                  </div>
                </div>
              )}

            {trackingDetails ? (
              <>
                {trackingDetails.shipment.statusMilestone === "exception" ? (
                  <div className="alert alert-danger mt-2 p-2" role="alert">
                    <i
                      className="fa-solid fa-triangle-exclamation"
                      style={{ color: "#d50005" }}
                    ></i>
                    <span className="ms-2">
                      {trackingDetails.shipment.statusCode}
                    </span>
                  </div>
                ) : (
                  <>
                    <div className="range">
                      <input
                        type="range"
                        min="0"
                        max="5"
                        step="1"
                        id="rangeSlider"
                        value={trackingValue}
                        disabled
                        style={{
                          background: `linear-gradient(150deg, green ${
                            trackingValue * 10
                          }%, #ccc ${trackingValue * 23}%)`,
                        }}
                      />
                      <div className="blue-circle"></div>
                    </div>
                    <ul className="range-labels">
                      <li
                        className={`${
                          trackingValue === 1 ? "active selected" : ""
                        } range_labels`}
                      >
                        Order Placed
                      </li>
                      <li
                        className={`${
                          trackingValue === 2 ? "active selected" : ""
                        } range_labels`}
                      >
                        Dispatched
                      </li>
                      <li
                        className={`${
                          trackingValue === 3 ? "active selected" : ""
                        } range_labels`}
                      >
                        In Transit
                      </li>
                      <li
                        className={`${
                          trackingValue === 4 ? "active selected" : ""
                        } range_labels`}
                      >
                        Out For Delivery
                      </li>
                      <li
                        className={`${
                          trackingValue === 5 ? "active selected" : ""
                        } range_labels`}
                      >
                        Delivered
                      </li>
                    </ul>
                    {[
                      "available_for_pickup",
                      "out_for_delivery",
                      "delivered",
                    ].includes(trackingDetails.shipment.statusMilestone) && (
                      <div
                        className="alert alert-success mt-2 p-2"
                        role="alert"
                      >
                        <span className="ms-2">
                          {trackingDetails.shipment.statusCode}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              "Nothing to Show"
            )}

            <hr />
            <h6 className="fw-bold">Timeline :</h6>
            <div className="overflow-auto w-100 text-center m-2 card_custom">
              {eventDetails.map((data, i) => (
                <TrackingOrderCard
                  key={i}
                  location={data.location}
                  time={moment(data.datetime).format("DD/MMM/YYYY HH:mm:ss")}
                  status={data.status}
                />
              ))}
            </div>
            {user.role !== "client" && (
              <>
                <hr />
                { trackingDetails && (<Container>
                  <div className="text-center fw-bold">Timestamps</div>
                  <div className="m-2">
                    <Row className="justify-content-center">
                      {
                        Object.keys(trackingDetails.statistics.timestamps).map((key)=>(
                          <Col className={`p-1 text-center m-1 rounded ${theme==="dark"?'bg-dark':'bg-light'}`} xs={5} key={key}>
                            <span className="fw-bold">{key.slice(0,-8)}</span>:{" "}
                            <br />
                            {trackingDetails.statistics.timestamps[key] === null
                              ? "-"
                              : moment(
                                  trackingDetails.statistics.timestamps[key]
                                ).format("DD/MM/YYYY")}
                        </Col>
                        ))
                      }
                    </Row>
                  </div>
                </Container>)}
              </>
            )}
          </Modal.Body>
        )}
      </Modal>

    </>
  );
};

export default TrackingDialog;
