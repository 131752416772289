import axios from "axios";

export const uploadOrder = (formData, token) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/order/upload",
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const verifyOrder = (tableData, token, clientID) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/order/verify",
    {
      orderList: tableData,
      clientID,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const uploadVerifiedOrder = (formData, token) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/order/submit",
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const orderSearch = (token, offSet, filterArr, limit, orderBy) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/order/search";
  let obj = { filterArr };
  if (offSet) {
    obj.offSet = offSet;
  }
  if (limit) {
    obj.limit = limit;
  }
  if (orderBy) {
    obj.orderBy = orderBy;
  }
  return axios.post(url, obj, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const fetchOrder = (token, orderId) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/order/fetch-order";
  if (orderId) {
    url += `?orderId=${orderId}`;
  }
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateOrderItem = (token, obj) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/order/update";
  return axios.patch(
    url,
    obj,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export const orderItemLists = (token, offSet, filterArr, limit, orderBy) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/order/orderItem-history/";
  let obj = { filterArr }
  if (offSet) {
    obj.offSet = offSet;
  }
  if (limit) {
    obj.limit = limit
  }
  if (orderBy) {
    obj.orderBy = orderBy
  }
  return axios.post(url, obj, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateOrder = (token, obj) => {
  return axios.patch(
    process.env.REACT_APP_API_URL + "/api/v1/order/updateOrderStatus",
    obj,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const reprocessOrder = (token, id) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/order/reprocessOrder",
    { id: id },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const reprocessDispatch = (token, id) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/order/dispatch-reprocess",
    { id },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const cancelOrderItem = (token, data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/order/cancelOrderItem",
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const updateItemName = (token, data) => {
  return axios.patch(
    process.env.REACT_APP_API_URL + "/api/v1/order/update-item-name",
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const uploadMiscellaneousOrder = (token, data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/order/upload-misc",
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}

export const placeMiscellaneousOrder = (token, data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/order/misc-submit",
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}

export const updatePrice = (token, data) => {
  return axios.patch(
    process.env.REACT_APP_API_URL + "/api/v1/order/update-item-price",
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}

export const updateFilename = (token, data) => {
  return axios.patch(
    process.env.REACT_APP_API_URL + "/api/v1/order/update-filename",
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}

export const cancelOrder = (token, id) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/order/cancel-order",
    { id: id },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}

export const changeOrderItemName = (token, data) => {
  return axios.patch(
    process.env.REACT_APP_API_URL + "/api/v1/order/update-processed-item",
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}

export const updateIgnoreNotification = (token, data) => {
  return axios.patch(
    process.env.REACT_APP_API_URL + "/api/v1/order/update-ignore-notification",
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}

export const addHistoryComment = (token, data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/order/item-add-comment",
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}

export const deleteOrder = (token, fileName) => {
  return axios.delete(
    process.env.REACT_APP_API_URL + "/api/v1/order/delete",
    {
      headers: { Authorization: `Bearer ${token}` },
      params: { fileName }
    }
  )
}

export const previewDispatch = (token) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/order/dispatchPreview", undefined,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}