import axios from "axios";

export const userLogin = (email, password) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/v1/user/login", {
    email,
    password,
  });
};

export const verifyToken = (token) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/user/verify-token",
    null,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const adminCreateUser = (
  userId,
  name,
  email,
  password,
  role,
  currencyCode,
  creditLimit,
  token,
  accountantEmail,
  countryArr,
  priceGroup,
  defaultPriceGroup
) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/user/create",
    {
      id: userId,
      name: name,
      email: email,
      password: password,
      role: role,
      currencyCode: currencyCode,
      creditLimit: creditLimit,
      accountantEmail: accountantEmail,
      countryArr: countryArr,
      priceGroup: priceGroup,
      defaultPriceGroup: defaultPriceGroup
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const getUser = (nameOrEmail, token, offSet, limit) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/user/search";

  let params = {};
  if (offSet) {
    params.offSet = offSet;
  }
  if (limit) {
    params.limit = limit;
  }
  if (nameOrEmail.includes("@")) {
    params.email = nameOrEmail;
  } else if (nameOrEmail) {
    params.name = nameOrEmail;
  }

  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
};

export const changePassword = async (currentPassword, password, token, logoutOtherDevices) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/user/change-password";
  return axios.post(
    url,
    {
      currentPassword,
      password,
      logoutOtherDevices
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const updateUser = async (userid, email, name, role, active, token, accountantEmail, countryArr) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/user/update";
  return axios.patch(
    url,
    {
      userid,
      email,
      name,
      role,
      active,
      accountantEmail,
      countries: countryArr
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const changePasswordByEmail = async (email, token, newPassword, logoutFromDevices) => {
  let url =
    process.env.REACT_APP_API_URL + "/api/v1/user/change-password-by-email";
  return axios.post(
    url,
    {
      email,
      newPassword,
      logoutFromDevices
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const supportQuery = (data, token) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/user/support",
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const getWalletInfo = (token) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/wallet/info"
  return axios.get(
    url,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}

export const getLedgerInfo = (token, params) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/wallet/ledger-info"
  return axios.get(
    url,
    {
      headers: { Authorization: `Bearer ${token}` },
      params
    }
  )
}

export const logout = (token) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/user/logout"
  return axios.post(
    url,
    { token: token },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}

export const logoutAllDevice = (token, userId) => {
  let url = process.env.REACT_APP_API_URL + "/api/v1/user/all-device-logout"
  return axios.post(
    url,
    { userId: userId },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}

