import React, { useState } from "react";
import "./historyComment.css";
import { Button, Form } from "react-bootstrap";
import { addHistoryComment } from "../../Services/orderService";
import { useSelector } from "react-redux";
import { errorToast, successToast } from "../../Services/toastService";


export const HistoryComment = ({selectedOrderItem, setInfoObj}) => {
    const [comment, setComment] = useState("");
    const [isCommentLoading, setIsCommentLoading] = useState(false);
    const token = useSelector((state) => state.token);
    const user = useSelector((state) => state.user);

    const handleCommentChange = (event) => {
        setComment(event.target.value);
      };

    const handleCommentSubmit = async (e) => {
        try{
            setIsCommentLoading(!isCommentLoading);
            e.preventDefault();
            const data = 
            {
                "id": selectedOrderItem.id,
                "comment": comment
            }
    
            const res = await addHistoryComment(token, data)
    
            if (res.status === 200) {
                let oldState = JSON.parse(JSON.stringify(selectedOrderItem));
                const addedComment = 
                {
                    event: comment,
                    user: {
                        email: user.email,
                        id: user.id,
                        role: user.role
                    },
                    createdAt: new Date()

                }
                oldState.historyMetadata = oldState.historyMetadata ? [...oldState.historyMetadata, addedComment] : [addedComment]
                
                setInfoObj(oldState)
                successToast(res.data.message)
              }
            // Clear the comment input after submission
            setComment("");
        } catch (err) {
            return errorToast(err.response?.data?.message || "Something went wrong1")
          } finally {
            setIsCommentLoading(false);
          }

    }

    return(
        <Form autoComplete="off" onSubmit={handleCommentSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Add Comment/Remark</Form.Label>
          <Form.Control
            type="text"
            required
            value={comment}
            onChange={handleCommentChange}
          />
        </Form.Group>
        <Button
          variant="warning"
          type="submit"
          disabled={isCommentLoading}
        >
          Add
        </Button>
      </Form>
    )
}
export default HistoryComment;