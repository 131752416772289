// import React, { useEffect, useState } from "react";
// import  Button from "react-bootstrap/Button";
// import  Container from "react-bootstrap/Container";
// import  Form from "react-bootstrap/Form";
// import  Modal from "react-bootstrap/Modal";
// import  Table  from "react-bootstrap/Table";
// import { errorToast, successToast } from "../../../Services/toastService";
// import {
//   addCountry,
//   getCountries,
//   updateCountry,
// } from "../../../Services/adminService";
// import { useSelector } from "react-redux";

// const Country = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [tableData, setTableData] = useState(null);
//   const token = useSelector((state) => state.token);
//   const [countryModal, setCountryModal] = useState(false);
//   const [updateSelected, setUpdateSelected] = useState(false);
//   const [countryObj, setCountryObj] = useState({
//     code: "",
//     name: "",
//     isBlackListed: false,
//   });

//   const handleModal = () => {
//     setCountryModal(!countryModal);
//   };

//   const handleModalClose = () => {
//     setCountryModal(false);
//     setCountryObj({
//       code: "",
//       name: "",
//       isBlackListed: false,
//     });
//   };

//   const updateCurrencyDialog = (item) => {
//     handleModal();
//     setCountryObj({
//       id: item.id,
//       code: item.code,
//       name: item.name,
//       isBlackListed: item.isBlackListed,
//     });
//   };

//   const updateBtn = async () => {
//     setUpdateSelected(true);
//     try {
//       setIsLoading(true);
//       if (!countryObj.code.trim() || !countryObj.name.trim()) {
//         errorToast("All fields are mandatory!");
//         setIsLoading(false);
//         return;
//       }
//       const res = await updateCountry(token, countryObj);
//       if (res.status === 200) {
//         successToast("Updated Successfully");
//       }
//       const updatedData = tableData.map((data) => {
//         if (data.id === res.data.id) {
//           return { ...data, ...res.data };
//         }
//         return data;
//       });
//       setTableData(updatedData);
//       handleModal();
//       setCountryObj({
//         code: "",
//         name: "",
//         isBlackListed: false,
//       });
//       setIsLoading(false);
//     } catch (err) {
//       setIsLoading(false);
//       errorToast("Something Went Wrong");
//     }
//   };

//   const handleAddBtn = async () => {
//     setUpdateSelected(false);
//     handleModal();
//   };

//   const fetchCountry = async () => {
//     try {
//       const res = await getCountries(token);
//       setTableData(res.data.countries);
//     } catch {
//       errorToast("Something Went Wrong");
//     }
//   };

//   const addBtn = async () => {
//     try {
//       setIsLoading(true);
//       if (!countryObj.code.trim() || !countryObj.name.trim()) {
//         errorToast("All fields are mandatory!");
//         setIsLoading(false);
//         return;
//       }
//       const res = await addCountry(token, countryObj);
//       if (res.status === 200) {
//         successToast("Add Successfully");
//         fetchCountry();
//         handleModalClose();
//       }
//       setIsLoading(false);
//     } catch (err) {
//       setIsLoading(false);
//       if (err.response?.status === 400) {
//         errorToast("Currency code already exist");
//       } else {
//         errorToast("Something Went Wrong");
//       }
//     }
//   };

//   useEffect(() => {
//     fetchCountry();
//     // eslint-disable-next-line
//   }, []);

//   return (
//     <Container>
//       <div className="mb-2">
//         <Button
//           className="me-2"
//           variant="outline-secondary"
//           id="button-addon1"
//           onClick={handleAddBtn}
//         >
//           <i className="fa-solid fa-plus"></i> Add
//         </Button>
//       </div>
//       {tableData && !!tableData.length && (
//         <Table bordered responsive>
//           <thead>
//             <tr>
//               <th>Code</th>
//               <th>Name</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {tableData.map((item, index) => (
//               <tr key={index}>
//                 <td>{item.code}</td>
//                 <td>{item.name}</td>
//                 <td key={item.id} className="w-25">
//                   <Button
//                     className="me-2"
//                     size="sm"
//                     variant="primary"
//                     onClick={() => {
//                       updateCurrencyDialog(item);
//                       setUpdateSelected(true);
//                     }}
//                   >
//                     Update
//                   </Button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//       )}

//       {/* Modal for update currency */}
//       <Modal show={countryModal} onHide={handleModalClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>
//             {updateSelected ? "Update Country" : "Add Country"}
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form autoComplete="off">
//             <Form.Group className="mb-3">
//               <Form.Label>Country Code</Form.Label>
//               <Form.Control
//                 type="text"
//                 required
//                 placeholder="Enter Country Code"
//                 value={countryObj.code}
//                 onChange={(e) =>
//                   setCountryObj({
//                     ...countryObj,
//                     code: e.target.value.toUpperCase(),
//                   })
//                 }
//                 disabled={updateSelected}
//               />
//             </Form.Group>
//             <Form.Group className="mb-3">
//               <Form.Label>Country Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Country Name"
//                 required
//                 value={countryObj.name}
//                 onChange={(e) =>
//                   setCountryObj({ ...countryObj, name: e.target.value })
//                 }
//               />
//             </Form.Group>
//             {updateSelected && (
//               <Form.Check
//                 type="switch"
//                 label="inactive"
//                 className="light_class"
//                 checked={countryObj.isBlackListed}
//                 onChange={(e) =>
//                   setCountryObj({
//                     ...countryObj,
//                     isBlackListed: e.target.checked,
//                   })
//                 }
//               />
//             )}
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleModalClose}>
//             Close
//           </Button>
//           <Button
//             variant="primary"
//             onClick={updateSelected ? updateBtn : addBtn}
//             disabled={isLoading}
//           >
//             {updateSelected ? "Update" : "Add"}
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </Container>
//   );
// };

// export default Country;

import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Container,
  Form,
  InputGroup,
  Modal,
  Table,
} from "react-bootstrap";
import { errorToast, successToast } from "../../../Services/toastService";
import { useSelector } from "react-redux";
import {
  createCountryTracker,
  getCountryTracker,
  updateCountryTracker,
} from "../../../Services/tracking";

const Country = () => {
  const [addCountryModal, setAddCountryModal] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [addIdentifiers, setAddIdentifiers] = useState("");
  const [dataCount, setDataCount] = useState();
  const token = useSelector((state) => state.token);
  const [searchText, setSearchText] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryData, setCountryData] = useState({
    id: "",
    name: "",
    code: "",
    trackingIdentifiers: [],
    isBlackListed: false
  });
  const [Loading, setLoading] = useState(false);
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      if (addIdentifiers.length !== 0) {
        handleAddIdentifier(addIdentifiers);
      } else {
        errorToast("Please enter something for adding Identifiers");
      }
    }
  };

  const handleAddIdentifier = (data) => {
    setCountryData((prev) => {
      prev.trackingIdentifiers = prev.trackingIdentifiers || []
      if (prev.trackingIdentifiers.includes(data)) {
        errorToast("Identifier already present");
        return prev;
      } else {
        return {
          ...prev,
          trackingIdentifiers: [...prev.trackingIdentifiers, data],
        };
      }
    });
    setAddIdentifiers("");
  };

  const handleRemoveEmail = (data) => {
    setCountryData((prev) => ({
      ...prev,
      trackingIdentifiers: prev.trackingIdentifiers.filter((item) => item !== data),
    }));
  };

  const badgeStyle = {
    marginRight: "5px",
    marginTop: "3px",
    fontSize: "13px",
  };

  const iconStyle = {
    cursor: "pointer",
    fontSize: "13px",
    marginLeft: "5px",
  };

  const modalClose = () => {
    if (selectedCountry) {
      setSelectedCountry(null);
      setCountryData({
        id: "",
        name: "",
        code: "",
        trackingIdentifiers: [],
        isBlackListed: false
      });
    }
    setAddCountryModal(false);
  };

  const handleAddCountry = async (e) => {
    try {
      setLoading(true)
      if (countryData.code === "" || countryData.name === "" |!countryData.trackingIdentifiers.length) {
        errorToast("Field can not be empty");
        return;
      }
      await createCountryTracker(token, countryData);
      setCountryData({
        id: "",
        name: "",
        code: "",
        trackingIdentifiers: [],
        isBlackListed: false
      });
      setAddCountryModal(false);
      successToast("created successfully");
      search();
    } catch (err) {
      console.log(err);
      if (err.response?.status === 400) {
        errorToast(err.response.data.message);
      } else {
        errorToast("something went wrong");
      }
    }finally{
      setLoading(false)
    }
  };

  const search = async () => {
    try {
      const response = await getCountryTracker(token, searchText);
      const data = response.data.countryTrackers;
      const count = response.data.countryTrackersCount;
      setTableData(data);
      setDataCount(count);
      setSearchText("");
    } catch (err) {
      console.log(err);
      errorToast("No Country Found");
    }
  };

  const handleLoadMore = async () => {
    try {
      setLoading(true)
      const response = await getCountryTracker(
        token,
        searchText,
        tableData.length
      );
      let table = [...tableData];
      table.push(...response.data.countryTrackers);
      setTableData(table);
    } catch (err) {
      console.log(err);
    }finally{
      setLoading(false)
    }
  };

  const searchOnEnter = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  const updateDialog = (item) => {
    setSelectedCountry(item);
    setCountryData({
      id: item.id,
      name: item.name,
      code: item.code,
      trackingIdentifiers: item.trackingIdentifiers,
      isBlackListed: item.isBlackListed
    });
    setAddCountryModal(true);
  };

  const handleUpdateCountry = async () => {
    try {
      setLoading(true)
      if(!countryData.code || !countryData.name || !countryData.trackingIdentifiers.length){
        errorToast("Field can not be empty");
        return;
      }
      const res = await updateCountryTracker(token, countryData);
      successToast("update Successfully");
      setSelectedCountry(null);
      setCountryData({
        id: "",
        name: "",
        code: "",
        trackingIdentifiers: [],
        isBlackListed: false
      });
      setAddCountryModal(false);
      const updatedTable = tableData.map((data) => {
        if (data.id === res.data.id) {
          return { ...data, ...res.data };
        }
        return data;
      });
      setTableData(updatedTable);
    } catch (err) {
      console.log(err);
    }finally{
      setLoading(false)
    }
  };

  const handleModalSubmit = () => {
    if (selectedCountry) {
      handleUpdateCountry();
    } else {
      handleAddCountry();
    }
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <>
        <div className="my-4">
          <div className="d-flex justify-content-between">
            <div>
              <Button
                className=""
                variant="outline-secondary"
                id="button-addon1"
                onClick={() => setAddCountryModal(!addCountryModal)}
              >
                <i className="fa-solid fa-plus"></i> Add
              </Button>
            </div>

            <div>
              <InputGroup>
                <Form.Control
                  placeholder="Enter Country Name"
                  aria-describedby="basic-addon2"
                  onKeyDown={searchOnEnter}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  onClick={() => search()}
                >
                  Search
                </Button>
              </InputGroup>
            </div>
          </div>
        </div>

        {
          !tableData && (
            <h4>NO DATA AVAILABLE</h4>
          )
        }

        {tableData && (
          <Table bordered responsive>
            <thead>
              <tr>
                <th>Country Code</th>
                <th>Country Name</th>
                <th>Identifiers</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index}>
                  <td>{item.code}</td>
                  <td>{item.name}</td>
                  <td>{item.trackingIdentifiers ? item.trackingIdentifiers?.join(", ") : "-"}</td>
                  <td key={item.id}>
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={() => updateDialog(item)}
                    >
                      Update
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {tableData && !!tableData.length && (
          <div>
            {tableData.length} / {dataCount}
          </div>
        )}
        <div className="text-center my-2">
          {tableData && dataCount > tableData.length ? (
            <Button variant="dark" onClick={handleLoadMore} disabled={Loading}>
              Load More
            </Button>
          ) : null}
        </div>
      </>
      {/* ==============================> Modal  */}
      <Modal show={addCountryModal} onHide={modalClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedCountry ? "update Country Details" : "Add Country Details"}
          </Modal.Title>
        </Modal.Header>
        <Form autoComplete="off">
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Country Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Country Code"
                value={countryData.code}
                onChange={(e) =>
                  setCountryData({
                    ...countryData,
                    code: e.target.value,
                  })
                }
                required
              // disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Country Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Country Name"
                value={countryData.name}
                onChange={(e) =>
                  setCountryData({ ...countryData, name: e.target.value })
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>City Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="City Name"
                value={addIdentifiers}
                onChange={(e) => setAddIdentifiers(e.target.value)}
                onKeyDown={(e) => handleKeyPress(e)}
              />
              {countryData.trackingIdentifiers &&
                countryData.trackingIdentifiers.map((data, index) => (
                  <Badge key={index} className="mr-2" style={badgeStyle}>
                    {data}
                    <span
                      className="ml-2"
                      onClick={() => handleRemoveEmail(data)}
                      style={iconStyle}
                    >
                      <i className="fa-solid fa-x"></i>
                    </span>
                  </Badge>
                ))}
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="switch"
                label="Country Blacklist"
                className="light_class"
                checked={countryData.isBlackListed}
                onChange={(e) =>
                  setCountryData({
                    ...countryData,
                    isBlackListed: e.target.checked,
                  })
                }
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="secondary"
              onClick={() => modalClose()}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleModalSubmit} disabled={Loading}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default Country;
