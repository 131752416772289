import React, { useState } from "react";
import "./dispatcher.css";
import { Button, Container, Form, Modal, Spinner, Table } from "react-bootstrap";
import { dispatchOrder, dispatchPreview } from "../../Services/dispatch";
import { useSelector } from "react-redux";
import { errorToast, successToast } from "../../Services/toastService";
// import TruncateCell from "../../SharedComponents/truncateCell/truncateCell";
import { mailDispatcher } from "../../Services/adminService";
import { previewDispatch } from "../../Services/orderService";

const Dispatch = () => {
  const [dispatchFile, setDispatchFile] = useState(null);
  const [isClickable, setIsClickable] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [containsInvalid, setContainsInvalid] = useState(false);
  const [dispatchPreviewLoading, setDispatchPreviewLoading] = useState(false);
  const [isUpdateCronLoading, setIsUpdateCronLoading] = useState(false);
  const [dispatchLoading, setDispatchLoading] = useState(false);
  const [triggerModal, setTriggerModal] = useState(false)
  const token = useSelector((state) => state.token);
  const [triggerLoading, setTriggerLoading] = useState(false);
  const [confirmData, setConfirmData] = useState(null);

  const handlePreview = async () => {
    try {
      if (!dispatchFile) {
        return errorToast("Please choose file first");
      }
      setDispatchPreviewLoading(true);
      const formData = new FormData();
      formData.append("xlsx", dispatchFile);
      const res = await dispatchPreview(token, formData);
      const dispatchList = res.data.data;
      if (dispatchList.containsInvalid) {
        setContainsInvalid(true);
        delete dispatchList.containsInvalid;
        errorToast("There are some error in the file! please check it again❌")
      }
      setTableData(dispatchList);
      if (res.status === 200) {
        setDispatchPreviewLoading(false);
      }
    } catch (err) {
      if (!err.response) {
        return errorToast("Something went wrong");
      }
      errorToast(err.response.data.message);
      setDispatchPreviewLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      setDispatchLoading(true);
      const formData = new FormData();
      if (confirmData) {
        formData.append("data", JSON.stringify(confirmData))
      } else {
        formData.append("xlsx", dispatchFile);
      }
      const res = await dispatchOrder(token, formData);
      if (res.status === 200) {
        // const data = res.data.updatedOrderItems;
        // setProducts(data);
        successToast("Tracking Id Updated");
        setIsClickable(!isClickable);
        setDispatchLoading(false);
        setTableData(null);
        setDispatchFile(null);
        setContainsInvalid(null);
      };
    } catch (err) {
      setDispatchLoading(false);
      if (!err.response) {
        return errorToast("Something went wrong");
      }
      errorToast(err.response.data.message);
    }
  };

  const handleTrigger = async () => {
    try {
      setTriggerLoading(true);
      const res = await mailDispatcher(token);
      successToast(res.data.message);
      triggerModalHandle();
    } catch (err) {
      console.log(err);
      errorToast("Something went wrong");
    } finally {
      setTriggerLoading(false);
    }
  };

  const handleUpdateDispatchMSBC = async () => {
    try {
      const isConfirmed = window.confirm('Are you sure you want to update tracking details from msbc?');
      if (isConfirmed) {
        setIsUpdateCronLoading(true);
        const res = await previewDispatch(token);
        setTableData(res.data.data);
        setConfirmData(res.data.dataForConfirm)
        setIsUpdateCronLoading(false);
      }
    } catch (err) {
      errorToast(err.response.data.message)
      setIsUpdateCronLoading(false);
    }
  }

  const goBackBtn = () => {
    setTableData(null);
    setDispatchFile(null)
    setContainsInvalid(null);
  }

  const triggerModalHandle = () => {
    setTriggerModal(!triggerModal)
  }

  return (
    <Container className="position-relative">
      <div className="float-end">
        <Button onClick={triggerModalHandle} className="m-2">
          Trigger Tracking Emails
        </Button>
        <Button
          className="m-2"
          disabled={isUpdateCronLoading}
          variant="warning"
          onClick={handleUpdateDispatchMSBC}
        >
          {isUpdateCronLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            "Trigger Update From MSBC"
          )}
        </Button>
      </div>
      <h2 className="my-4">Dispatch File</h2>
      {!tableData ? (
        <>



          <div>
            <Form.Group controlId="formFile" className="file-inp">
              <Form.Label>Dispatch File</Form.Label>
              <Form.Control
                type="file"
                accept=".xlsx, .xls"
                onChange={(e) => {
                  setDispatchFile(e.target.files[0]);
                  setIsClickable(!isClickable);
                }}
              />
            </Form.Group>
            <small className="text-danger">
              Upload only xls or xlsx file <br />
              <a
                className="text-primary download_Link"
                href="/dispatchSample.xlsx"
                download="Sample File"
              >
                Download Sample File
              </a>
            </small>
          </div>
          <Button
            className="mt-2"
            disabled={dispatchPreviewLoading}
            onClick={handlePreview}
          >
            {dispatchPreviewLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Preview Dispatch"
            )}
          </Button>
        </>
      ) :
        <>
          <div className="mb-3">
            <Button variant="info" className="marg_rt" onClick={goBackBtn}>
              <i className="fa-solid fa-arrow-left"></i> Go back
            </Button>
            {
              (!containsInvalid && Object.keys(tableData).length) ?
                <Button
                  className=""
                  disabled={dispatchLoading}
                  onClick={handleSubmit}
                >
                  {dispatchLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Submit"
                  )}
                </Button>
                :
                ""
            }

          </div>
          {
            Object.keys(tableData).length ? (
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>EDN</th>
                    <th>Product Name</th>
                    <th>Consignee Name</th>
                    <th>Old Tracking ID</th>
                    <th>New Tracking ID</th>
                    {containsInvalid
                      ?
                      <th>Error Message</th>
                      :
                      ""}

                  </tr>
                </thead>
                <tbody>
                  {tableData ? Object.keys(tableData).map((key, i) => (
                    <tr key={i} style={tableData[key].status ? { border: "2px solid red" } : {}}>
                      <td> {i + 1}</td>
                      <td> {key}</td>
                      <td> {tableData[key].productName || ""}</td>
                      <td> {tableData[key].consigneeName || ""}</td>
                      <td> {tableData[key].oldTrackingId || 'NYD'} </td>
                      {tableData[key].newTrackingId ?
                        <td> {tableData[key].newTrackingId} </td>
                        : <td></td>}
                      {containsInvalid ?
                        <td> {tableData[key].status} </td>
                        : ""}
                    </tr>
                  ))
                    : <tr>
                      <td>No data</td>
                      <td>No data</td>
                    </tr>}
                </tbody>
              </Table>
            ) : (
              <h4 className="text-center mt-2">Nothing To Update !!!</h4>
            )
          }
        </>
      }
      <Modal show={triggerModal} onHide={triggerModalHandle}>
        <Modal.Header closeButton>
          <Modal.Title>Trigger Tacking Emails</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you wanna send dispatch mails ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={triggerModalHandle}>
            No
          </Button>
          <Button variant="primary" onClick={handleTrigger} disabled={triggerLoading}>
            {triggerLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Yes"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Dispatch;
