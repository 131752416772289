import React, { useEffect, useState } from 'react'
import { Badge, Button, Container, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import { getNotificationData, updateNotificationData } from '../../../Services/adminService';
import { useSelector } from 'react-redux';
import { errorToast, successToast } from '../../../Services/toastService';


const Notification = () => {
    const token = useSelector((state) => state.token);
    const [tableData, setTableData] = useState(null)
    const [reqObj, setReqObj] = useState({
        id: "",
        escalation: {
            L1: {
                email: [],
                sla: 0
            },
            L2: {
                email: [],
                sla: 0
            },
            L3: {
                email: [],
                sla: 0
            },
            L4: {
                email: [],
                sla: 0
            },
            type:"",
            cronTime: [],
        }
    })
    const [l1Email, setL1Email] = useState("")
    const [l2Email, setL2Email] = useState("")
    const [l3Email, setL3Email] = useState("")
    const [l4Email, setL4Email] = useState("")
    const [cronTime, setCronTime] = useState("")
    const [showModal, setShowModal] = useState(false)
    const escalation = ['L1', 'L2', 'L3', 'L4']
    const emailValue = [l1Email, l2Email, l3Email, l4Email]
    const hourlyNotifications = ['orderStatus', 'accountingStatus']

    const iconStyle = {
        cursor: "pointer",
        fontSize: "13px",
        marginLeft: "5px",
    };

    const badgeStyle = {
        marginRight: "5px",
        marginTop: "3px",
        fontSize: "13px",
    };

    const getData = async () => {
      try {
        const res = await getNotificationData(token);
        setTableData(res.data);
      } catch (error) {
        errorToast(error.response?.data?.message || "Something went wrong");
      }
    };

    const modalCloseHandle = () => {
        setShowModal(false)
        setReqObj({
            id: "",
            escalation: {
                L1: {
                    email: [],
                    sla: 0
                },
                L2: {
                    email: [],
                    sla: 0
                },
                L3: {
                    email: [],
                    sla: 0
                },
                L4: {
                    email: [],
                    sla: 0
                },
                cronTime: []
            },
            type:"",
            cronTime:[]
        })
        setL1Email("")
        setL2Email("")
        setL3Email("")
        setL4Email("")
        setCronTime("")
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleAddEmail = (email, arrayType) => {
        setReqObj((prev) => {
            if (prev?.escalation[arrayType]?.email?.includes(email)) {
                errorToast(`Email already exists for ${arrayType}`);
                return prev;
            } else {
                let temp = JSON.parse(JSON.stringify(prev))
                temp.escalation[arrayType].email = [...temp.escalation[arrayType].email, email]
                return temp;
            }
        });
        if (arrayType === "L1") {
            setL1Email("")
        }
        if (arrayType === "L2") {
            setL2Email("")
        }
        if (arrayType === "L3") {
            setL3Email("")
        }
        if (arrayType === "L4") {
            setL4Email("")
        }
    };

    const handleAddTime = (time) => {
        setReqObj((prev) => {
            if (prev?.escalation["cronTime"]?.includes(time)) {
                errorToast(`Time already exists`);
                return prev;
            } else {
                let temp = JSON.parse(JSON.stringify(prev))
                temp.escalation["cronTime"] = [...temp.escalation["cronTime"], time]
                return temp;
            }
        });
        setCronTime("");
    };

    const handleKeyPress = (e, arrayType) => {
        if (e.keyCode === 13) {
            if (arrayType === "L1") {
                if (isValidEmail(l1Email)) {
                    handleAddEmail(l1Email, "L1");
                }
                else {
                    errorToast("Invalid email type");
                }
            } else if (arrayType === "L2") {
                if (isValidEmail(l2Email)) {
                    handleAddEmail(l2Email, "L2");
                }
                else {
                    errorToast("Invalid email type");
                }
            }
            else if (arrayType === "L3") {
                if (isValidEmail(l3Email)) {
                    handleAddEmail(l3Email, "L3");
                }
                else {
                    errorToast("Invalid email type");
                }
            }
            else if (arrayType === "L4") {
                if (isValidEmail(l4Email)) {
                    handleAddEmail(l4Email, "L4");
                }
                else {
                    errorToast("Invalid email type");
                }
            }
            else if (arrayType === "cronTime") {
                if (cronTime !== "") {
                    handleAddTime(cronTime)
                } else {
                    errorToast("Time cannot be empty")
                }
            } else {
                errorToast("Type is not suppported")
            }
        }
    };

    const handleRemoveEmail = (email, arrayType) => {
        let prev = { ...reqObj }
        prev.escalation[arrayType].email = prev.escalation[arrayType].email.filter((item) => item !== email)
        setReqObj(prev)
    };

    const handleRemoveTime = (time) => {
        let prev = { ...reqObj }
        prev.escalation["cronTime"] = prev.escalation["cronTime"].filter((item) => item !== time)
        setReqObj(prev);
    }

    const valueChangeHandle = (value, escalation) => {
        if (escalation === "L1") {
            setL1Email(value)
        } else if (escalation === "L2") {
            setL2Email(value)
        } else if (escalation === "L3") {
            setL3Email(value)
        } else if (escalation === "L4") {
            setL4Email(value)
        } else if (escalation === "cronTime") {
            setCronTime(value)
        } else {
            errorToast("Escalation Value doesnt not exist")
        }
    }

    const handleUpdate = async () => {
        try {
            const res = await updateNotificationData(token, reqObj);
            if (res.status === 200) {
                successToast(res?.data?.message)
                const updatedData = tableData.map((item) => {
                    if (item.id === reqObj.id) {
                        return { ...item, ...reqObj }
                    }
                    return item;
                })
                setTableData(updatedData)
                modalCloseHandle()
            }
        } catch (error) {
            errorToast(error.response?.data?.message || "Something went wrong")
        }
    }


    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])
    return (
        <Container>
            <Table stripped="true" bordered hover>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>L1</th>
                        <th>L2</th>
                        <th>L3</th>
                        <th>L4</th>
                        <th>Cron time(24 hours)</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tableData?.map((item) => (
                            <tr key={item.id}>
                                <td>{item.type}</td>
                                <td>{item.escalation.L1.sla} {!hourlyNotifications.includes(item.type) ? "Days" : "Hours"}</td>
                                <td>{item.escalation.L2.sla} {!hourlyNotifications.includes(item.type) ? "Days" : "Hours"}</td>
                                <td>{item.escalation.L3.sla} {!hourlyNotifications.includes(item.type) ? "Days" : "Hours"}</td>
                                <td>{item.escalation.L4.sla} {!hourlyNotifications.includes(item.type) ? "Days" : "Hours"}</td>
                                <td>{item.escalation.cronTime.join(", ")}</td>
                                <td>
                                    <Button onClick={() => {
                                        setShowModal(true)
                                        setReqObj(item)
                                    }}>Update</Button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>

            {/* modal */}
            <Modal show={showModal} onHide={modalCloseHandle}>
                <Modal.Header closeButton>
                    <Modal.Title>Update</Modal.Title>
                </Modal.Header>
                <small className='text-danger ms-3'>*Press enter after entering email or time then click update button</small>
                <Modal.Body>
                    <Form autoComplete="off">

                        {escalation.map((item, index) => (
                            <div key={index}>
                                <Form.Label>{item}</Form.Label>
                                <InputGroup className="mb-2">
                                    <Form.Control
                                        type="number"
                                        value={reqObj.escalation[item].sla}
                                        onChange={(e) => {
                                            let temp = JSON.parse(JSON.stringify(reqObj));
                                            temp.escalation[item].sla = Number(e.target.value)
                                            setReqObj(temp)
                                        }}
                                    />
                                    <InputGroup.Text>{item} {!hourlyNotifications.includes(reqObj.type) ? "Days" : "Hours"}</InputGroup.Text>
                                </InputGroup>
                                <InputGroup className="mb-1">
                                    <Form.Control
                                        type="email"
                                        placeholder=' Enter Email'
                                        value={emailValue[index]}
                                        onChange={(e) => valueChangeHandle(e.target.value.toLowerCase(), item)}
                                        onKeyDown={(e) => handleKeyPress(e, item)}
                                    />
                                    <InputGroup.Text>{item} Email</InputGroup.Text>
                                </InputGroup>
                                <div>
                                    {
                                        reqObj.escalation[item].email.map((email, index) => (
                                            <Badge key={index} className="mr-2" style={badgeStyle}>
                                                {email}
                                                <span
                                                    className="mr-2"
                                                    onClick={() => handleRemoveEmail(email, item)}
                                                    style={iconStyle}
                                                >
                                                    <i className="fa-solid fa-x"></i>
                                                </span>
                                            </Badge>
                                        ))
                                    }
                                </div>
                            </div>
                        ))}
                        <label htmlFor="exampleTime" >Cron time:</label>
                        <input type="time" className="form-control" id="exampleTime"
                            value={cronTime}
                            onChange={(e) => valueChangeHandle(e.target.value, "cronTime")}
                            onKeyDown={(e) => handleKeyPress(e, "cronTime")}

                        />
                        <div>
                            {
                                reqObj.escalation["cronTime"].map((time, index) => (
                                    <Badge key={index} className="mr-2" style={badgeStyle}>
                                        {time}
                                        <span
                                            className="mr-2"
                                            onClick={() => handleRemoveTime(time, "cronTime")}
                                            style={iconStyle}
                                        >
                                            <i className="fa-solid fa-x"></i>
                                        </span>
                                    </Badge>
                                ))
                            }
                        </div>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalCloseHandle}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleUpdate}>
                        Update Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default Notification;