import React from "react";
import { Container, Nav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";

const Account = () => {

  return (
    <Container>
      <Nav variant="tabs" className="my-4">
        <Nav.Item variant="outline-secondary">
          <Nav.Link as={NavLink} to="/accounts/wallet">
            Wallet
          </Nav.Link>
        </Nav.Item>
        <Nav.Item variant="outline-secondary">
          <Nav.Link as={NavLink} to="/accounts/currency">
            Currency
          </Nav.Link>
        </Nav.Item>
        <Nav.Item variant="outline-secondary">
          <Nav.Link as={NavLink} to="/accounts/shipment">
            Shipment
          </Nav.Link>
        </Nav.Item>
        <Nav.Item variant="outline-secondary">
          <Nav.Link as={NavLink} to="/accounts/shipmentPriceSlab">
            Shipment Price Slab
          </Nav.Link>
        </Nav.Item>
        <Nav.Item variant="outline-secondary">
          <Nav.Link as={NavLink} to="/accounts/ledger">
            Ledger
          </Nav.Link>
        </Nav.Item>
        <Nav.Item variant="outline-secondary">
          <Nav.Link as={NavLink} to="/accounts/history">
            History
          </Nav.Link>
        </Nav.Item>
        <Nav.Item variant="outline-secondary">
          <Nav.Link as={NavLink} to="/accounts/price-group">
            Price Group
          </Nav.Link>
        </Nav.Item>
        <Nav.Item variant="outline-secondary">
          <Nav.Link as={NavLink} to="/accounts/price-entities">
            Price Entities
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Outlet />
    </Container>
  );
};

export default Account;
