import React, { useEffect, useRef, useState } from "react";
import "./placeOrder.css";
import {
  Button,
  Container,
  Form,
  Spinner,
  Table,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  uploadOrder,
  uploadVerifiedOrder,
  verifyOrder,
} from "../../Services/orderService";
import { successToast, errorToast } from "../../Services/toastService";
import { getAllClients } from "../../Services/adminService";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../lib/customHooks/useLocale";
import moment from "moment";

function Order() {
  const { t } = useLocale("common");
  const navigate = useNavigate();
  const [orderFile, setOrderFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const token = useSelector((state) => state.token);
  const [sCount, setSCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [manual, setManual] = useState(false);
  // const [sendFile, setSendFile] = useState(true);
  const [orderModal, setOrderModal] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(undefined);
  const role = useSelector((state) => state.user.role);
  const [alreadyOrdered, setAlreadyOrdered] = useState({});
  const [orderHash, setOrderHash] = useState("")
  const [reOrder, setReOrder] = useState(false)

  const loadData = async () => {
    try {
      // const response = await getConfigByKey(token, "sendRawFile");
      if (["admin", "dispatch_manager", "accountant"].includes(role)) {
        const clientData = await getAllClients(token);
        setClientList(clientData.data.clients);
      }
      // if (response.status === 200) {
      //   const keyValue = response.data.value;
      //   if (keyValue === "yes") {
      //     setSendFile(true);
      //   } else if (keyValue === "no") {
      //     setSendFile(false);
      //   }
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const fileInputRef = useRef(null);
  const user = useSelector((state) => state.user);

  async function handleUpload() {
    const formData = new FormData();
    formData.append("xlsx", orderFile);
    if (["admin", "dispatch_manager", "accountant"].includes(role)) {
      if (!selectedClient) {
        errorToast("Select client to continue");
        return;
      }
      formData.append("clientID", selectedClient);
    }
    try {
      setIsLoading(true);
      setManual(false);
      let response = await uploadOrder(formData, token);
      const orderList = response.data.orderList;
      setTableData(orderList);
      setTotalCount(orderList.length);
      const [s, t] = updateSuccessCount(orderList);
      if (response.status === 200) {
        setIsLoading(false);
        setOrderHash(response.data.hash)
        if (s === t) {
          successToast(`All items are valid`);
        } else {
          errorToast(`${t - s} / ${t} are invalid, edit to check it again`);
        }
      }
      if (response.data.alreadyOrderedOn) {
        setAlreadyOrdered(response.data.alreadyOrderedOn.Order)
      }
    } catch (err) {
      setIsLoading(false);
      if (!err.response) {
        return errorToast("Something went wrong");
      }
      if (err.response?.status === 500) {
        errorToast("Invalid File");
      }
    }
  }

  function handleInputChange(event, index, field) {
    const updatedTableData = [...tableData];
    updatedTableData[index][field] = event.target.value;
    setTableData(updatedTableData);
  }

  const verify = async () => {
    try {
      const response = await verifyOrder(tableData, token, selectedClient);
      const newData = response.data.orderList;
      setTableData(newData);
      const [s, t] = updateSuccessCount(newData);
      setTotalCount(newData.length);
      if (s === t) {
        successToast(`All items are valid`);
      } else {
        errorToast(`${t - s} / ${t} are invalid, edit to check it again`);
      }
    } catch (err) {
      console.log(err);
      errorToast("Something went wrong");
    }
  };

  const updateSuccessCount = (orderList) => {
    let successCount = 0;
    orderList.forEach((item) => {
      item.productCode && (successCount += 1);
    });
    setSCount(successCount);
    return [successCount, orderList.length];
  };

  // const checkBox = (e) => {
  //   setSendFile(e.target.checked);
  // };

  const successOrder = () => {
    setOrderModal(!orderModal);
    if (role === "client") navigate("/orderHistory")
  }

  const orderSubmit = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("xlsx", orderFile);
      formData.append("uploadedFilename", orderFile.name);
      formData.append("orderHash", orderHash);
      // if (sendFile) {
      // }
      if (["admin", "dispatch_manager", "accountant"].includes(role)) {
        formData.append("clientID", selectedClient);
        for (let i = 0; i < clientList.length; i++) {
          if (clientList[i].id === selectedClient) {
            formData.append("clientEmail", clientList[i].email);
            break;
          }
        }
      }
      formData.append("orderList", JSON.stringify(tableData));
      const response = await uploadVerifiedOrder(formData, token);
      if (response.status === 201) {
        // successToast("Order Created Successfully");
        setOrderModal(true);
        setTableData(null);
        setSCount();
        setTotalCount();
        setOrderFile();
        setSelectedClient(undefined);
        // fileInputRef.current.value = null;
      }
    } catch (err) {
      setManual(true);
      if (!err.response || err.response?.status === 500) {
        errorToast(err.response?.data?.message || "Something went wrong");
        setTableData(null);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const sendManually = () => {
    const cc = user.email;
    const subject = "Email subject";
    const content = "Email content";
    const to = "example@example.com";

    // Create the mailto URL
    const mailtoUrl = `mailto:${encodeURIComponent(to)}?cc=${encodeURIComponent(
      cc
    )}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      content
    )}`;

    // Open the email client
    window.open(mailtoUrl);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <h4 className="my-4">{t("Place Order")}</h4>

      {tableData ? (
        <>
          <Button
            variant="dark"
            className="mb-2"
            onClick={() => {
              setTableData(null);
              setOrderFile(null);
              setSelectedClient(undefined);
            }}
          >
            <i className="fa-solid fa-arrow-left"></i> {t("Go Back")}
          </Button>
        </>
      ) : (
        <>
          <div className="mb-3">
            <Form.Group controlId="formFile" className="file-inp">
              <Form.Label>{t("Order File")}</Form.Label>
              <Form.Control
                ref={fileInputRef}
                type="file"
                accept=".xlsx, .tsv, .xls, .txt"
                onChange={(e) => {
                  setOrderFile(e.target.files[0]);
                }}
              />
            </Form.Group>
            <small className="text-danger">
              {t("Upload only xls, xlsx or tsv file")} <br />
              <a
                className="text-primary download_Link"
                href="/AX_230811 (1) 1 copy (1).txt"
                download="Sample File"
              >
                {t("Download Sample File")}
              </a>
            </small>
          </div>
          {["admin", "dispatch_manager", "accountant"].includes(role) && (
            <>
              <Form.Label>Order on behalf of</Form.Label>
              <Form.Select
                onChange={(e) =>
                  setSelectedClient(
                    e.target.value !== "null" ? e.target.value : undefined
                  )
                }
                className="mb-3 w-25"
              >
                <option defaultValue value="null">
                  Select client
                </option>
                {clientList.map((client) => (
                  <option value={client.id} key={client.id}>
                    {client.name}
                  </option>
                ))}
              </Form.Select>
            </>
          )}
          <Button variant="dark" onClick={handleUpload} disabled={!orderFile}>
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              t("Preview Order")
            )}
          </Button>
        </>
      )}

      {manual ? (
        <div className="mt-2">
          <Button onClick={sendManually}>{t("Send Manually")}</Button>
        </div>
      ) : null}

      {tableData && !manual ? (
        <div>
          <Button variant="info" className="marg_rt" onClick={verify}>
            {t("Validate Again")}
          </Button>
          <Button variant="success" onClick={orderSubmit} disabled={isLoading}>
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              `${t("Confirm")} ${sCount}/${totalCount}`
            )}
          </Button>
        </div>
      ) : null}

      {tableData && (
        <>
          <h6 className="mt-2">
            <span className="text-danger">
              * {t("Invalid product name, shipment type or country, edit to check it again")}
            </span>
          </h6>
          <h6 className="mt-2">
            <span>* {t("Valid Input")}</span>
          </h6>

          {/* <Form.Check
            label="Send Original file"
            name="group1"
            type="checkbox"
            checked={sendFile}
            onChange={checkBox}
          /> */}

          <Table bordered variant="secondary" className="my-4" responsive>
            <thead>
              <tr>
                <th>{t("Product Name")}</th>
                {/* <th>Shipment Type</th> */}
                <th>{t("Consignee Name")}</th>
                <th>{t("Country")}</th>
                <th>{t("Delivery Address")}</th>
                <th>{t("Phone")}</th>
                <th>{t("Pincode")}</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index}>
                  <td>
                    {item.productCode ? (
                      item.displayName
                    ) : (
                      <InputGroup>
                        <InputGroup.Text>
                          <i className="fa-solid fa-pen-to-square"></i>
                        </InputGroup.Text>
                        <Form.Control
                          value={item.displayName}
                          className="text-danger border-danger"
                          onChange={(event) =>
                            handleInputChange(event, index, "displayName")
                          }
                          onBlur={verify}
                        />
                      </InputGroup>
                    )}
                  </td>
                  <td className={item.productCode ? "" : "text-danger"}>
                    {item.consigneeName}
                  </td>
                  <td className={item.productCode ? "" : "text-danger"}>
                    {item.productCode ? (
                      item.country
                    ) : (
                      <InputGroup>
                        <InputGroup.Text>
                          <i className="fa-solid fa-pen-to-square"></i>
                        </InputGroup.Text>
                        <Form.Control
                          value={item.country}
                          className="text-danger border-danger"
                          onChange={(event) =>
                            handleInputChange(event, index, "country")
                          }
                          onBlur={verify}
                        />
                      </InputGroup>
                    )}
                  </td>
                  <td className={item.productCode ? "" : "text-danger"}>
                    {item.deliveryAddress}
                  </td>
                  <td className={item.productCode ? "" : "text-danger"}>
                    {item.phone}
                  </td>
                  <td className={item.productCode ? "" : "text-danger"}>
                    {item.pincode}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      <Modal show={orderModal} onHide={successOrder} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t("Order Created Successfully")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Order Created Successfully check your mail for order details")}!!!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={successOrder}>
            {t("OK")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal for already order confirmation */}
      <Modal show={!!Object.keys(alreadyOrdered).length}
        onHide={() => {
          setTableData(null);
          setOrderFile(null);
          setSelectedClient(undefined);
          setAlreadyOrdered({});
        }}
        backdrop="static"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Duplicate Order Alert")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This Order is placed for {alreadyOrdered.fileName} on {moment(alreadyOrdered.createdAt).format("DD/MM/YYYY HH:mm A")}
          <Form.Check
            type={"checkbox"}
            label={"Are you sure you want to reorder this"}
            className="mt-2"
            checked={reOrder}
            onClick={(e) => { setReOrder(e.target.checked) }}
          />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary"
            onClick={() => {
              setTableData(null);
              setOrderFile(null);
              setSelectedClient(undefined);
              setAlreadyOrdered({});
            }}
          >
            {t("Go Back")}
          </Button>
          <Button variant="primary" onClick={() => setAlreadyOrdered({})} disabled={!reOrder}>
            {t("Continue")}
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
}

export default Order;
