import React, { useEffect, useState } from "react";
import { Button, Container, Form, Modal, Table } from "react-bootstrap";
import { errorToast, successToast } from "../../../Services/toastService";
import {
  addCurrency,
  getCurrencyCode,
  updateCurrency,
} from "../../../Services/adminService";
import { useSelector } from "react-redux";

const Currency = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const token = useSelector((state) => state.token);
  const [currencyModal, setCurrencyModal] = useState(false);
  const [updateSelected, setUpdateSelected] = useState(false);
  const [currencyData, setCurrencyData] = useState({
    code: "",
    name: "",
    rate: "",
    roundOff: 1,
  });

  const handleModal = () => {
    setCurrencyModal(!currencyModal);
  };

  const handleModalClose = () => {
    setCurrencyModal(false);
    setCurrencyData({
      code: "",
      name: "",
      rate: "",
      roundOff: 1,
    });
  };

  const updateCurrencyDialog = (item) => {
    handleModal();
    setCurrencyData({
      code: item.code,
      name: item.name,
      rate: item.rate,
      roundOff: item.roundOff,
    });
  };

  const updateBtn = async () => {
    setUpdateSelected(true);
    try {
      setIsLoading(true);
      if (
        !currencyData.code.trim() ||
        !currencyData.name.trim() ||
        !currencyData.rate.trim()
      ) {
        errorToast("All fields are mandatory!");
        setIsLoading(false);
        return;
      }
      const res = await updateCurrency(token, currencyData);
      if (res.status === 200) {
        successToast("Updated Successfully");
      }
      const updatedData = tableData.map((data) => {
        if (data.id === res.data.id) {
          return { ...data, ...res.data };
        }
        return data;
      });
      setTableData(updatedData);
      handleModal();
      setCurrencyData({
        code: "",
        name: "",
        rate: "",
        roundOff: 1,
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      errorToast("Something Went Wrong");
    }
  };

  const handleAddBtn = async () => {
    setUpdateSelected(false);
    handleModal();
  };

  const currencyList = async () => {
    try {
      const res = await getCurrencyCode(token);
      setTableData(res.data);
    } catch {
      errorToast("Something Went Wrong");
    }
  };

  const addBtn = async () => {
    try {
      setIsLoading(true);
      if (
        !currencyData.code.trim() ||
        !currencyData.name.trim() ||
        !currencyData.rate.trim()
      ) {
        errorToast("All fields are mandatory!");
        setIsLoading(false);
        return;
      }
      const res = await addCurrency(token, currencyData);
      if (res.status === 200) {
        successToast("Add Successfully");
        currencyList();
        handleModalClose();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err.response?.status === 400) {
        errorToast(err.response?.data?.message)
      } else {
        errorToast("Something Went Wrong");
      }
    }
  };

  useEffect(() => {
    currencyList();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className="mb-2">
        <Button
          className="me-2"
          variant="outline-secondary"
          id="button-addon1"
          onClick={handleAddBtn}
        >
          <i className="fa-solid fa-plus"></i> Add
        </Button>
      </div>
      {tableData && !!tableData.length && (
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Conversion Rate</th>
              <th>Round Off</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr key={index}>
                <td>{item.code}</td>
                <td>{item.name}</td>
                <td>{item.rate}</td>
                <td>{item.roundOff}</td>
                <td key={item.id} className="w-25">
                  <Button
                    className="me-2"
                    size="sm"
                    variant="primary"
                    onClick={() => {
                      updateCurrencyDialog(item);
                      setUpdateSelected(true);
                    }}
                  >
                    Update
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Modal for update currency */}
      <Modal show={currencyModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {updateSelected ? "Update Currency" : "Add Currency"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Form.Group className="mb-3">
              <Form.Label>Currency Code</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Enter Currency Code"
                value={currencyData.code}
                onChange={(e) =>
                  setCurrencyData({
                    ...currencyData,
                    code: e.target.value.toUpperCase(),
                  })
                }
                disabled={updateSelected}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Currency Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Currency Name"
                required
                value={currencyData.name}
                onChange={(e) =>
                  setCurrencyData({ ...currencyData, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Currency Rate</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Currency Rate"
                required
                value={currencyData.rate}
                onChange={(e) =>
                  setCurrencyData({ ...currencyData, rate: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Round Off</Form.Label>
              <Form.Select
                type="text"
                placeholder="Round off"
                value={currencyData.roundOff}
                onChange={(e) =>
                  setCurrencyData({ ...currencyData, roundOff: e.target.value })
                }
              >
                <option defaultValue value="1">
                  1
                </option>
                <option defaultValue value="0.5">
                  0.5
                </option>
                <option defaultValue value="10">
                  10
                </option>
                <option defaultValue value="100">
                  100
                </option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={updateSelected ? updateBtn : addBtn}
            disabled={isLoading}
          >
            {updateSelected ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Currency;
