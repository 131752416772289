import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { errorToast, successToast } from "../../../Services/toastService";
import {
  getWallet,
  addAmount,
  updateCreditLimit,
  getPriceGroup,
  rateConversion,
} from "../../../Services/adminService";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import DatePicker from "react-datepicker";

const Wallet = () => {
  const [tableData, setTableData] = useState(null);
  const token = useSelector((state) => state.token);
  const [totalCount, setTotalCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    operation: "add",
    walletId: "",
    name: "",
    amount: "",
    creditLimit: "",
    discount: 0,
    remark: "",
    priceGroup: "",
    defaultPriceGroup: "",
    refDate: undefined,
    sendTxnMail: false,
    dailyAccounting: false
  });
  const [priceGroupList, setPriceGroupList] = useState([])
  const [amount, setAmount] = useState("")
  const [conversionLoading, setConversionLoading] = useState(false)
  const [usdTOJpy, setUsdTOJpy] = useState(0)
  const [addBtnLoading, setAddBtnLoading] = useState(false)

  const walletList = async () => {
    try {
      const res = await getWallet(token);
      const wallets = res.data.wallets;
      setTableData(wallets);
      setTotalCount(res.data.totalCount);
    } catch {
      errorToast("Something Went Wrong");
    }
  };

  const handleLoadMore = async () => {
    try {
      const response = await getWallet(token, tableData.length);
      let table = [...tableData];
      table.push(...response.data.wallets);
      setTableData(table);
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowModal = (operation, item) => {
    const initialState = {
      operation,
      walletId: item?.id || "",
      name: item?.user?.name || "",
      amount: "",
      creditLimit: item?.creditLimit || "",
      discount: item?.discount || "",
      code: item?.currency?.code || "",
      priceGroup: item?.priceGroupId || "",
      defaultPriceGroup: item?.defaultPriceGroupId || "",
      sendTxnMail: false,
      dailyAccounting: item?.dailyAccounting
    };
    setShowModal(true);
    setFormData(initialState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setAddBtnLoading(true)
      if (formData.operation === "add") {
        const response = await addAmount(token, {
          walletId: formData.walletId,
          amount: formData.amount,
          remark: formData.remark,
          refDate: formData.refDate,
          usdAmount: amount,
          sendTxnMail: formData.sendTxnMail
        });
        if (response.status === 200) {
          successToast("Amount Added Successfully");
          const updatedData = tableData.map((item) => {
            if (item.id === formData.walletId) {
              return {
                ...item,
                balanceInLocal:
                  Math.round(
                    (parseFloat(item.balanceInLocal) +
                      parseFloat(formData.amount)) *
                    100
                  ) / 100,
              };
            }
            return item;
          });
          setTableData(updatedData);
          setShowModal(false);
          setAmount("");
          setUsdTOJpy(0)
        }
      } else if (formData.operation === "update") {
        if (formData.discount > 100 | formData.discount < -100) {
          return errorToast("Discount should be in the range of -100 to 100")
        }
        const res = await updateCreditLimit(token, {
          walletId: formData.walletId,
          creditLimit: formData.creditLimit,
          discount: formData.discount,
          priceGroup: formData.priceGroup,
          defaultPriceGroup: formData.defaultPriceGroup,
          dailyAccounting: formData.dailyAccounting
        });
        if (res.status === 200) {
          successToast("Update Successfully");
          const updatedData = tableData.map((data) => {
            if (data.id === res.data.updatedData.id) {
              return { ...data, ...res.data.updatedData };
            }
            return data;
          });
          setTableData(updatedData);
          setShowModal(false);
        }
      }
    } catch (err) {
      errorToast(err.response?.data?.message || "Something went wrong");
    } finally {
      setAddBtnLoading(false);
    }
  };

  const getPriceGroups = async () => {
    try {
      const res = await getPriceGroup(token)
      setPriceGroupList(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    walletList();
    getPriceGroups();
    // eslint-disable-next-line
  }, []);

  const handleConversion = async () => {
    try {
      setConversionLoading(true)
      const res = await rateConversion(amount);
      setFormData({ ...formData, amount: res?.data?.convertedValue })
      const oneDollarTOJpy = (Number(res?.data?.convertedValue) / Number(amount)).toFixed(2)
      setUsdTOJpy(oneDollarTOJpy)
    } catch (error) {
      errorToast("Something went wrong please try again")
    } finally {
      setConversionLoading(false)
    }
  }

  return (
    <Container>
      {tableData && !!tableData.length && (
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Client</th>
              <th>Balance</th>
              <th>Credit Limit</th>
              <th>Currency</th>
              <th>Price Group</th>
              <th>Default Price Group</th>
              <th>Adjustment Factor</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr key={index}>
                <td>{item.user.name}</td>
                <td>{item.balanceInLocal}</td>
                <td>{item.creditLimit}</td>
                <td>{item.currency.code}</td>
                <td>{item.priceGroupId ? item.priceGroupId : "-"}</td>
                <td>{item.defaultPriceGroupId ? item.defaultPriceGroupId : "-"}</td>
                <td>{item.discount} %</td>
                <td>{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                <td>{moment(item.updatedAt).format("DD/MM/YYYY HH:mm")}</td>
                <td key={item.id}>
                  <Button
                    size="sm"
                    variant="primary"
                    id="button-addon1"
                    className="me-1 mb-1"
                    onClick={() => {
                      handleShowModal("add", item); // Fixed this line
                    }}
                  >
                    Add Amount
                  </Button>
                  <Button
                    className="mb-1"
                    size="sm"
                    variant="primary"
                    onClick={() => {
                      handleShowModal("update", item); // Fixed this line
                    }}
                  >
                    Update Wallet
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {tableData && !tableData.length && (
        <div className="text-center mt-5">
          <h5>No wallet Found</h5>
        </div>
      )}

      {tableData && !!tableData.length && (
        <div>
          {tableData.length} / {totalCount}
        </div>
      )}

      <div className="text-center my-2">
        {tableData && totalCount > tableData.length ? (
          <Button variant="dark" onClick={handleLoadMore}>
            Load More
          </Button>
        ) : null}
      </div>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
          setAmount("")
          setUsdTOJpy(0)
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {formData.operation === "add"
              ? "Add Amount"
              : "Update Credit Limit"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Form.Group className="mb-3">
              <Form.Label>User</Form.Label>
              <Form.Control
                type="text"
                placeholder="User"
                required
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                disabled
              />
            </Form.Group>

            {formData.operation === "add" && (
              <>
                {
                  formData.code === "JPY" &&
                  <>
                    <Form.Label>Amount in USD {!!usdTOJpy && <span>( 1 USD ={usdTOJpy} JPY )</span>}</Form.Label>
                    <Form.Group className="mb-3 input-group">
                      <Form.Control
                        type="number"
                        placeholder="Enter amount in USD"
                        required
                        value={amount}
                        onChange={(e) =>
                          setAmount(e.target.value)
                        }
                      />
                      <Button
                        className="h-25"
                        onClick={handleConversion}
                        disabled={conversionLoading}
                      >
                        Convert
                      </Button>
                    </Form.Group>
                  </>
                }
                <Form.Group className="mb-3">
                  <Form.Label>Amount in {formData.code}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter amount"
                    required
                    value={formData.amount}
                    onChange={(e) =>
                      setFormData({ ...formData, amount: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>Remark (optional)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter remark"
                    value={formData.remark}
                    onChange={(e) =>
                      setFormData({ ...formData, remark: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3 w-100">
                  <Form.Label>Reference Date</Form.Label>
                  <DatePicker
                    className="date-picker-style w-100"
                    dateFormat="dd/MM/yyyy"
                    selected={formData.refDate}
                    onChange={(date) => {
                      if (date) {
                        date.setHours(12);
                      }
                      setFormData({ ...formData, refDate: date });
                    }}
                    required
                    disabledKeyboardNavigation
                    placeholderText=" Reference Date"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Check
                    type='checkbox'
                    label="Send Mail to Client"
                    checked={formData.sendTxnMail}
                    onChange={(e) =>
                      setFormData({ ...formData, sendTxnMail: e.target.checked })
                    }
                  />
                </Form.Group>
              </>
            )}
            {formData.operation === "update" && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Credit Limit in {formData.code}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Credit Limit"
                    value={formData.creditLimit}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        creditLimit: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Price Group</Form.Label>
                  <Form.Select
                    value={formData.priceGroup}
                    onChange={(e) =>
                      setFormData({ ...formData, priceGroup: e.target.value })
                    }
                  >
                    <option value="">Select Price Group</option>
                    {
                      priceGroupList.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))
                    }
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Default Price Group</Form.Label>
                  <Form.Select
                    value={formData.defaultPriceGroup}
                    onChange={(e) =>
                      setFormData({ ...formData, defaultPriceGroup: e.target.value })
                    }
                  >
                    <option value="">Select Default Price Group</option>
                    {
                      priceGroupList.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))
                    }
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Adjustment Factor (%)*</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Discount"
                    value={formData.discount}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        discount: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Check
                  className="mb-2"
                  type="switch"
                  label="Daily Accounting"
                  checked={formData.dailyAccounting}
                  onChange={(e) => setFormData({
                    ...formData,
                    dailyAccounting:e.target.checked
                  })}
                />
                <span className="light_class">*Positive value will charged extra while negative will be reduced from product cost</span>
              </>
            )}

            <Modal.Footer>
              <Button variant="secondary" onClick={() => {
                setShowModal(false)
                setAmount("")
                setUsdTOJpy(0)
              }}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={addBtnLoading}>
                {formData.operation === "add" ? "Add Amount" : "Update Wallet"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Wallet;
