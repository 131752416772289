import React, { useEffect, useState } from 'react'
import { Button, Container, Form, Modal, Table } from 'react-bootstrap'
import { addPriceGroup, getPriceGroup, updatePriceGroup } from '../../../Services/adminService'
import { useSelector } from 'react-redux';
import { errorToast, successToast } from '../../../Services/toastService';

const PriceGroup = () => {
  const token = useSelector((state) => state.token);
  const [priceGroupTable, setPriceGroupTable] = useState([])
  const [priceGroupUpdateModal, setPriceGroupUpdateModal] = useState(false)
  const [reqObj,setReqObj] = useState({
    id:"",
    name:""
  })
  const [updateSelected,setUpdateSelected] = useState(false)
  const priceGroupData = async () => {
    try {
      const res = await getPriceGroup(token)
      setPriceGroupTable(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateBtn = (item)=>{
    setReqObj({
      id:item.id,
      name:item.name
    })
    setUpdateSelected(true)
    setPriceGroupUpdateModal(true)
  }

  const updateHandle = async()=>{
    try {
      if(reqObj.name.length === 0){
        return errorToast("Name is required")
      }
      const res = await updatePriceGroup(token,reqObj)
      if(res.status===200){
        const updatedData = priceGroupTable?.map((item)=>{
          if(item.id === reqObj.id){
            return {
              ...item,...reqObj
            }
          }
          return item;
        })
        setPriceGroupTable(updatedData)
        setReqObj({
          id:"",
          name:""
        })
        successToast(res?.data?.message)
      }
      setUpdateSelected(false)
      setPriceGroupUpdateModal(false)
      setReqObj({
        id:"",
        name:""
      })
    } catch (err) {
      errorToast(err.response?.data?.message || "Something went wrong");
    }
  }

  const handleAddBtn = async ()=>{
    try {
      if(reqObj.name.length === 0){
        return errorToast("Name is required")
      }
      const res = await addPriceGroup(token,reqObj)
      if(res.status===200){
        const newData = priceGroupTable.concat(res.data.data)
        setPriceGroupTable(newData)
        successToast(res.data.message)
      }
      setPriceGroupUpdateModal(false)
      setReqObj({
        id:"",
        name:""
      })
    } catch (err) {
      errorToast(err.response?.data?.message || "Something went wrong");
    }
  }

  useEffect(() => {
    priceGroupData()
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      <h4 className='mb-2'>Price Group</h4>
      <Button className='mb-2 mt-2' onClick={()=>setPriceGroupUpdateModal(true)}>
        ADD
      </Button>
      <Table bordered>
        <thead>
          <tr>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            priceGroupTable?.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>
                  <Button onClick={()=>handleUpdateBtn(item)}>
                    Update
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>

      {/* price group modal for adding and updating price group */}

      <Modal show={priceGroupUpdateModal} onHide={()=>{
            setPriceGroupUpdateModal(false)
            setReqObj({
              id:"",
              name:""
            })
            setUpdateSelected(false)
        }}>
        <Modal.Header closeButton>
          <Modal.Title>{updateSelected?"Price Group Update":"Add Price Group"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder=" Enter Name"
                autoFocus
                value={reqObj.name}
                onChange={(e)=>setReqObj({
                  ...reqObj,
                  name:e.target.value.toUpperCase()
                })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{
            setPriceGroupUpdateModal(false)
            setReqObj({
              id:"",
              name:""
            })
            setUpdateSelected(false)
          }}>
            Close
          </Button>
          <Button variant="primary" onClick={updateSelected?updateHandle:handleAddBtn}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  )
}

export default PriceGroup