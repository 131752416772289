import React from "react";
import { Container, Nav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";

const AdminConfig = () => {
  return (
    <Container>
      <Nav variant="tabs" className="my-4">
        <Nav.Item variant="outline-secondary">
          <Nav.Link as={NavLink} to="/config/email">
            Email
          </Nav.Link>
        </Nav.Item>
        <Nav.Item variant="outline-secondary">
          <Nav.Link as={NavLink} to="/config/country">
            Country
          </Nav.Link>
        </Nav.Item>
        <Nav.Item variant="outline-secondary">
          <Nav.Link as={NavLink} to="/config/notification">
            Notification
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Outlet />
    </Container>
  );
};

export default AdminConfig;
