import React, { useEffect, useRef, useState } from "react";
import "./orderItemHistory.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import TruncateCell from "../../SharedComponents/truncateCell/truncateCell";
import moment from "moment";
import {
  orderItemLists,
} from "../../Services/orderService";
import FilterSearch from "../../SharedComponents/filterSearch/filterSearch";
import Pagination from "react-bootstrap/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import TrackingDialog from "../../SharedComponents/trackingDialog/trackingDialog";
import { useLocale } from "../../lib/customHooks/useLocale.jsx";
import TooltipComponent from "../../SharedComponents/tooltip/tooltip.jsx";
import InfiniteScroll from "react-infinite-scroll-component";

const OrderItemHistory = () => {
  const { t } = useLocale("common")
  const [orders, setOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const token = useSelector((state) => state.token);
  const [allExpanded, setAllExpanded] = useState(false);
  const [allExpandedAddress, setAllExpandedAddress] = useState(false);
  const [searchObj, setSearchObj] = useState([]);
  const filterSearchRef1 = useRef(null);
  const filterSearchRef2 = useRef(null);
  const filterSearchRef3 = useRef(null);
  const filterSearchRef4 = useRef(null);
  const filterSearchRef5 = useRef(null);
  const filterSearchRef6 = useRef(null);
  const filterSearchRef7 = useRef(null);
  const filterSearchRef8 = useRef(null);
  const filterSearchRef9 = useRef(null);
  const filterSearchRef10 = useRef(null);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState({
    createdAt: "desc",
  });
  const [tableLoading, setTableLoading] = useState(false);
  const navigate = useNavigate();


  let items = [];
  let paginationNum = [10, 25, 50];
  paginationNum.forEach((number) => {
    items.push(
      <Pagination.Item
        key={number}
        active={number === limit}
        onClick={() => setLimit(number)}
      >
        {number}
      </Pagination.Item>
    );
  });


  const resetFilter = () => {
    if (id) {
      navigate("/orderItemHistory");
    }
    setSearchObj([]);
    setOrderBy({ createdAt: "desc" });
    setLimit(10);
    filterSearchRef1.current && filterSearchRef1.current.resetFilter();
    filterSearchRef2.current && filterSearchRef2.current.resetFilter();
    filterSearchRef3.current && filterSearchRef3.current.resetFilter();
    filterSearchRef4.current && filterSearchRef4.current.resetFilter();
    filterSearchRef5.current && filterSearchRef5.current.resetFilter();
    filterSearchRef6.current && filterSearchRef6.current.resetFilter();
    filterSearchRef7.current && filterSearchRef7.current.resetFilter();
    filterSearchRef8.current && filterSearchRef8.current.resetFilter();
    filterSearchRef9.current && filterSearchRef9.current.resetFilter();
    filterSearchRef10.current && filterSearchRef10.current.resetFilter();
  };

  let { id } = useParams();

  const orderItems = async () => {
    setTableLoading(true);
    try {
      if (id) {
        let newObj = [
          {
            column: "id",
            type: "contains",
            value: id,
          },
        ];
        id = undefined;
        const res = await orderItemLists(token, null, newObj, limit, orderBy);
        const orders = res.data.orders;
        const count = res.data.totalCount;
        setOrders(orders);
        setTotalCount(count);
      } else {
        const res = await orderItemLists(
          token,
          null,
          searchObj,
          limit,
          orderBy
        );
        const orders = res.data.orders;
        const count = res.data.totalCount;
        setOrders(orders);
        setTotalCount(count);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  };

  const handleLoadMore = async () => {
    try {
      const response = await orderItemLists(
        token,
        orders.length,
        searchObj,
        limit,
        orderBy
      );
      const additionalOrders = response.data.orders;
      const totalCount = response.data.totalCount;
      const newTable = [...orders, ...additionalOrders];
      setOrders(newTable);
      setTotalCount(totalCount);
    } catch (error) {
      console.error("Failed to fetch orders.", error);
    }
  };

  const filterSearch = async (obj, isSelected) => {
    let current = searchObj;
    current = current.filter((item) => item.column !== obj.column);
    if (isSelected) {
      setSearchObj(current);
    } else {
      current.push(obj);
      setSearchObj(current);
    }
  };

  useEffect(() => {
    orderItems();
    // eslint-disable-next-line
  }, [searchObj, limit, orderBy]);

  return (
    <Container>
      <div>
        <h4 className="my-4">{t("Order Item History")}</h4>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <Button
              variant="outline-danger"
              id="button-addon2"
              onClick={resetFilter}
            >
              <i className="fa-solid fa-rotate-left"></i>
            </Button>
          </div>
          <div>
            <Pagination>{items}</Pagination>
          </div>
        </div>
        <div className="my-2 text-center"><b>{`${orders.length}/${totalCount}`}</b></div>
      </div>
      <InfiniteScroll
        next={handleLoadMore}
        dataLength={orders.length || 0}
        hasMore={orders.length !== totalCount}
        loader={
          <div className="text-center mt-4">
            <p>Loading...</p>
          </div>
        }
        endMessage={
          <div style={{ textAlign: 'center', marginTop: "20px" }}>
            <b>{orders && (<div>{orders.length} / {totalCount}</div>)}</b>
          </div>
        }
      >
        <Table bordered hover className="mb-2" responsive>
          <thead>
            <tr>
              <FilterSearch
                name={t("EDN")}
                table="orderItem"
                column="id"
                type="contains"
                search={filterSearch}
                ref={filterSearchRef1}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <FilterSearch
                name={t("Product Name")}
                table="orderItem"
                column="productName"
                type="contains"
                search={filterSearch}
                ref={filterSearchRef2}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                toExpand={() => setAllExpanded(!allExpanded)}
              />
              <FilterSearch
                name={t("Shipment Type")}
                table="orderItem"
                column="shipmentType"
                type="contains"
                search={filterSearch}
                ref={filterSearchRef3}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <FilterSearch
                name={t("Consignee Name")}
                table="orderItem"
                column="consigneeName"
                type="contains"
                search={filterSearch}
                ref={filterSearchRef4}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <FilterSearch
                name={t("Address")}
                table="orderItem"
                column="deliveryAddress"
                type="contains"
                search={filterSearch}
                ref={filterSearchRef5}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                toExpand={() => setAllExpandedAddress(!allExpandedAddress)}
              />
              <FilterSearch
                name={t("Pincode")}
                table="orderItem"
                column="pincode"
                type="contains"
                search={filterSearch}
                ref={filterSearchRef6}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <FilterSearch
                name={t("Country")}
                table="orderItem"
                column="country"
                type="contains"
                search={filterSearch}
                ref={filterSearchRef7}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <FilterSearch
                name={t("Phone")}
                table="orderItem"
                column="phone"
                type="contains"
                search={filterSearch}
                ref={filterSearchRef8}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <FilterSearch
                name={t("Tracking ID")}
                table="orderItem"
                column="trackingId"
                type="contains"
                search={filterSearch}
                ref={filterSearchRef9}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <th>{t("Tracking Status")}</th>
              <FilterSearch
                name={t("Document Date")}
                table="orderItem"
                column="createdAt"
                type="date"
                search={filterSearch}
                ref={filterSearchRef10}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <th>{t("Amount")}</th>
            </tr>
          </thead>
          {!tableLoading && orders && orders.length > 0 && (
            <tbody>
              {
                orders?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>
                      <TruncateCell
                        text={item.productName}
                        maxLength={
                          allExpanded ? item.productName.length : 20
                        }
                      />
                    </td>
                    <td>{item.shipmentType}</td>
                    <td>{item.consigneeName}</td>
                    <td>
                      <TruncateCell
                        text={item.deliveryAddress}
                        maxLength={allExpandedAddress ? item.deliveryAddress : 10}
                      />
                    </td>
                    <td>{item.pincode}</td>
                    <td>{item.country}</td>
                    <td>{item.phone}</td>
                    <td>
                      {item?.trackingMetadata?.currentStatus !== 'other' ? (
                        <TrackingDialog item={item} />
                      ) : (
                        <>
                          <TooltipComponent
                            children={<p>{item.trackingId}</p>}
                            content={"not available"}
                          />
                        </>
                      )}
                    </td>
                    <td>
                      {
                        item.trackingId === "canceled"
                          ? "canceled" :
                          !item?.trackingMetadata?.currentStatus
                            ? "pending"
                            : item?.trackingMetadata?.currentStatus === "failed" ||
                              item?.trackingMetadata?.currentStatus === "available for pickup"
                              ? "out for delivery" : item?.trackingMetadata?.currentStatus || "-"
                      }
                    </td>
                    <td>{moment(item.createdAt).format("DD/MM/YY HH:mm")}</td>
                    <td className="text-end">
                      {item.price
                        ? parseFloat(item.price).toFixed(2) + " " + item.client.Wallet.currency.code
                        : "-"}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          )}
        </Table>
      </InfiniteScroll>
    </Container>
  );
};

export default OrderItemHistory;
