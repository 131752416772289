import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import { errorToast, successToast } from "../../../Services/toastService";
import {
  aggregateLedger,
  aggregateLedgerRef,
  dailyAccTrigger,
  getAllClients,
  getAllLedgerEntries,
} from "../../../Services/adminService";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "./ledger.css";
import TruncateCell from "../../../SharedComponents/truncateCell/truncateCell";
import FilterSearch from "../../../SharedComponents/filterSearch/filterSearch";
import * as XLSX from "xlsx"
import { Link } from "react-router-dom";

const Ledger = () => {
  const [tableData, setTableData] = useState(null);
  const [clientList, setClientList] = useState([]);
  const token = useSelector((state) => state.token);
  const [totalCount, setTotalCount] = useState(0);
  const role = useSelector((state) => state.user.role);
  const [summaryModal, setSummaryModal] = useState(false);
  const [reqObj, setReqObj] = useState({
    clientId: "",
    startDate: undefined,
    endDate: undefined,
    startingBalance: "",
    endingBalance: "",
    emailSub: ""
  });
  const [summaryObj, setSummaryObj] = useState(undefined);
  const [summaryView, setSummaryView] = useState(false);
  const [summaryMoreBtn, setSummaryMoreBtn] = useState(false); // for showing show more btn when summary data is more than 5
  const [showMoreBtn, setShowMoreBtn] = useState(false)
  const [orderBy, setOrderBy] = useState({
    createdAt: "desc",
  });
  const [limit, setLimit] = useState(10);
  const [searchObj, setSearchObj] = useState([]);
  const [allExpanded, setAllExpanded] = useState(false)
  const filterSearchRef1 = useRef(null);
  const filterSearchRef2 = useRef(null);
  const filterSearchRef3 = useRef(null);
  const filterSearchRef4 = useRef(null);
  const filterSearchRef5 = useRef(null);
  const filterSearchRef6 = useRef(null);
  const filterSearchRef7 = useRef(null);
  const filterSearchRef8 = useRef(null);
  const [referenceDateModal, setReferenceDateModal] = useState(false);
  const [emailSubModal, setEmailSubModal] = useState(false)
  let items = [];
  let paginationNum = [10, 25, 50];
  paginationNum.forEach((number) => {
    items.push(
      <Pagination.Item
        key={number}
        active={number === limit}
        onClick={() => setLimit(number)}
      >
        {number}
      </Pagination.Item>
    );
  });

  // function to close summary Modal
  const handleSummaryModalClose = () => {
    setSummaryModal(false);

  };

  const emailSubHandleClose = () => {
    setReqObj({ ...reqObj, emailSub: "" })
    setEmailSubModal(false)
  }

  const LedgerList = async () => {
    try {
      const res = await getAllLedgerEntries(
        token,
        limit,
        null,
        searchObj,
        orderBy
      );
      const Ledger = res.data.ledgerInfo;
      setTableData(Ledger);
      setTotalCount(res.data.totalCount);
    } catch {
      errorToast("Something Went Wrong");
    }
  };

  const loadData = async () => {
    try {
      if (role === "admin" || role === "dispatch_manager") {
        const clientData = await getAllClients(token);
        setClientList(clientData.data.clients);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const handleClick = async (id, name) => {
  //   try {
  //     setDropDownValue(name);
  //     setClientId(id);
  //     const res = await getAllLedgerEntries(token, id, undefined);
  //     setTableData(res.data.ledgerInfo);
  //     setTotalCount(res.data.totalCount);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const resetBtn = async () => {
    try {
      const res = await getAllLedgerEntries(token, 10, null, [], orderBy);
      const Ledger = res.data.ledgerInfo;
      setTableData(Ledger);
      setTotalCount(res.data.totalCount);
      setReqObj({
        clientId: "",
        startDate: undefined,
        endDate: undefined,
      });
      setSummaryView(false);
      setSummaryObj(undefined);
      setSummaryMoreBtn(false);
      setLimit(10);
      setSearchObj([]);
      setOrderBy({
        createdAt: "desc",
      });
      setShowMoreBtn(false)
      setReferenceDateModal(false)
      filterSearchRef1.current && filterSearchRef1.current.resetFilter();
      filterSearchRef2.current && filterSearchRef2.current.resetFilter();
      filterSearchRef3.current && filterSearchRef3.current.resetFilter();
      filterSearchRef4.current && filterSearchRef4.current.resetFilter();
      filterSearchRef5.current && filterSearchRef5.current.resetFilter();
      filterSearchRef6.current && filterSearchRef6.current.resetFilter();
      filterSearchRef7.current && filterSearchRef7.current.resetFilter();
      filterSearchRef8.current && filterSearchRef8.current.resetFilter();
    } catch (error) {
      console.log(error);
    }
  };

  // function will call when user ask for client summary data
  const getSummaryBtn = async (e) => {
    e.preventDefault();
    try {
      const res = await aggregateLedger(token, reqObj);
      localStorage.setItem("startDate", JSON.stringify(reqObj.startDate))
      localStorage.setItem("endDate", JSON.stringify(reqObj.endDate))
      // set the client Object
      setSummaryObj(res.data.clientObj);
      // set the table data for ledger table
      setTableData(res.data.ledger);
      // set this true so that we can show the data that we want when user go for ledger table
      setSummaryView(true);
      setTotalCount(res.data.ledger.length);
      handleSummaryModalClose();
    } catch (err) {
      console.log(err);
    }
  };

  const sendInvoice = async (e) => {
    try {
      e?.preventDefault();
      let invoiceObj = reqObj;
      invoiceObj = { ...reqObj, sendLedger: true };
      const res = await (referenceDateModal ? aggregateLedgerRef(token, { ...invoiceObj, }) : aggregateLedger(token, invoiceObj));
      if (res.status === 200) {
        successToast("Success");
        setEmailSubModal(false)
        return;
      }
    } catch (err) {
      return errorToast("Something went wrong");
    }
  };

  const filterSearch = async (obj, isSelected) => {
    let current = searchObj;
    current = current.filter((item) => item.column !== obj.column);
    if (isSelected) {
      setSearchObj(current);
    } else {
      current.push(obj);
      setSearchObj(current);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    LedgerList();
    // eslint-disable-next-line
  }, [searchObj, limit, orderBy]);

  const handleLoadMore = async () => {
    try {
      const response = await getAllLedgerEntries(
        token,
        limit,
        tableData.length,
        searchObj,
        orderBy
      );
      let table = [...tableData];
      table.push(...response.data.ledgerInfo);
      setTableData(table);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadTable = () => {
    if (!tableData || tableData.length === 0) return errorToast("Table is Empty");
    const headers = [
      "Amount",
      "Amount In INR",
      "Type",
      "Order ID",
      "Product Name",
      "Tracking ID",
      "Created At",
      "Reference Date"
    ];
    const data = [
      headers,
      ...tableData.map((row) => [
        row.amountInLocal,
        row.amountInInr,
        row.type,
        row.orderItemId || "-",
        row.orderItem?.productName || "-",
        row.orderItem?.trackingId || "-",
        moment(row.createdAt).format("YYYY-MM-DD HH:mm"),
        row.referenceDate ? moment(row?.referenceDate).format("YYYY-MM-DD HH:mm") : "-",
      ])
    ]
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Table Data");
    XLSX.writeFile(wb, "table_data.xlsx");
  };

  const refernceModalHandler = async (e) => {
    e.preventDefault()
    try {
      const res = await aggregateLedgerRef(token, reqObj);
      localStorage.setItem("startDate", JSON.stringify(reqObj.startDate))
      localStorage.setItem("endDate", JSON.stringify(reqObj.endDate))
      setSummaryObj(res.data.clientObj);
      setTableData(res.data.ledger);
      setSummaryView(true);
      setTotalCount(res.data.ledger.length);
      handleSummaryModalClose();
    } catch (error) {
      errorToast(error?.response?.data?.message)
    }
  }

  const dateValues = () => {
    const endDate = JSON.parse(localStorage.getItem('endDate'));
    const startDate = JSON.parse(localStorage.getItem('startDate'));
    let temp = { ...reqObj };
    if (endDate) {
      temp.endDate = new Date(endDate)
    }
    if (startDate) {
      temp.startDate = new Date(startDate)
    }
    setReqObj(temp)
  }

  const handleSendInvoice = (e) => {
    e.preventDefault();
    if (referenceDateModal) {
      if (!reqObj.startingBalance) {
        return errorToast("Please enter starting balance");
      }
      setEmailSubModal(true);
    }
    else {
      if (
        window.confirm(
          "Please confirm you want to send invoice to the client"
        ) === true
      )
        sendInvoice();
    }
  }

  const dailyAccountingTrigger = (e) => {
    e.preventDefault();
    const text = "Are you sure you want to send daily accounting?";
    if (window.confirm(text) === true) {
      try {
        dailyAccTrigger(token).then((res) => {
          if (res.status === 200) {
            successToast("Mails sent successfully!");
          }
        })
      } catch (error) {
        errorToast(error?.response?.data?.message || "Something went wrong");
      }
    }
  }

  return (
    <Container>
      {summaryView && tableData?.length > 0 ? (
        <div>
          <div className="d-flex justify-content-between">
            <div>
              <Button onClick={resetBtn} variant="outline-secondary">
                {<i className="fa-solid fa-angle-left"></i>} Go Back
              </Button>
              <Button variant="dark" className="ms-2" onClick={handleSendInvoice}>
                Send Invoice
              </Button>
            </div>
            <Button
              variant="outline-secondary"
              onClick={handleDownloadTable}
            >
              <i className="fa-solid fa-download"></i> View
            </Button>
          </div>
          <div className="mt-4 text-center">
            {referenceDateModal ? (
              <h4>Reference Date Summary</h4>
            ) : (
              <h4>Client Summary</h4>
            )}
          </div>
          {summaryObj && (
            <div className="row my-4 fw-bold fs-6">
              <div className="col-sm border-end border-1">
                <span className="d-block">Email : {summaryObj.email}</span>
                <span className="d-block">
                  Total Credited Amount : {summaryObj.totalCreditedAmount}{" "}
                  {summaryObj?.currency}
                </span>
                <span>
                  Total Order Amount : {summaryObj.totalOrderAmount}{" "}
                  {summaryObj?.currency}
                </span>
                {!isNaN(summaryObj.adjustedPriceOfOldOrder) &&
                  parseFloat(summaryObj.adjustedPriceOfOldOrder) !== 0 && (
                    <span className="d-block">
                      Amount Adjusted for Previous Statements :{" "}
                      {summaryObj.adjustedPriceOfOldOrder}{" "}
                      {summaryObj?.currency}
                    </span>
                  )}
              </div>
              <div className="col-sm border-end border-1">
                <span className="d-block">
                  Order Count : {summaryObj.orderCount}
                </span>
                {
                  referenceDateModal && (
                    <>
                      <span className="d-block mt-2">
                        Starting Balance :
                        <input
                          type="number"
                          placeholder=" Enter Starting Balance"
                          onChange={(e) =>
                            setReqObj({
                              ...reqObj,
                              startingBalance: e.target.value,
                              endingBalance: Number(e.target.value) - Number(summaryObj.totalOrderAmount) + Number(summaryObj.totalCreditedAmount)
                            })
                          }
                        />
                        {" " + summaryObj?.currency}
                      </span>
                      {
                        reqObj?.startingBalance &&
                        <span>
                          end Balance: {reqObj.endingBalance} {summaryObj?.currency}
                        </span>
                      }
                    </>
                  )
                }
                {
                  summaryObj?.startingBalance &&
                  <span className="d-block">
                    Starting Balance : {summaryObj.startingBalance}{" "}
                    {summaryObj?.currency}
                  </span>
                }
                {
                  summaryObj?.balance &&
                  <span>
                    End Balance : {summaryObj.balance} {summaryObj?.currency}
                  </span>
                }
              </div>
            </div>
          )}
          <Table bordered responsive>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Amount In INR</th>
                <th>Type</th>
                <th>Order Id</th>
                <th>
                  Product Name
                  <span
                    onClick={() => {
                      setAllExpanded(!allExpanded)
                    }}
                    className="readButton"
                  >
                    <u>{allExpanded ? " Read Less" : " Read More"}</u>
                  </span>
                </th>
                <th>Tracking Id</th>
                <th>Created At</th>
                <th>Reference Date</th>
              </tr>
            </thead>
            <tbody>
              {!summaryMoreBtn
                ? tableData?.map(
                  (item, index) =>
                    (showMoreBtn ? true : index < 5) && (
                      <tr key={index}>
                        {summaryView && (
                          <td>
                            {item.amountInLocal} {summaryObj?.currency}
                          </td>
                        )}
                        <td>{item.amountInInr}</td>
                        <td>{item.type}</td>
                        <td>{item.orderItemId}</td>
                        <td>
                          {item.orderItem?.productName ? (
                            <TruncateCell
                              text={item.orderItem?.productName}
                              maxLength={allExpanded ? item.orderItem?.productName.length : 10}
                            />
                          ) : "-"}
                        </td>
                        <td>{item.orderItem?.trackingId || "-"}</td>
                        <td>
                          {moment(item.createdAt).format("DD/MM/YYYY HH:mm")}
                        </td>
                        <td>
                          {item.referenceDate ? moment(item.referenceDate).format("DD/MM/YYYY HH:mm") : "-"}
                        </td>
                      </tr>
                    )
                )
                : tableData?.map((item, index) => (
                  <tr key={index}>
                    {summaryView && (
                      <td>
                        {item.amountInLocal} {summaryObj?.currency}
                      </td>
                    )}
                    <td>{item.amountInInr}</td>
                    <td>{item.type}</td>
                    <td>{item.orderItemId}</td>
                    <td>
                      {moment(item.createdAt).format("DD/MM/YYYY HH:mm")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {tableData.length > 5 && !summaryMoreBtn && (
            <Button
              onClick={() => setShowMoreBtn(true)}
              variant="dark"
              hidden={showMoreBtn}
            >
              Show More
            </Button>
          )}
        </div>
      ) : (
        tableData?.length > 0 && (
          <div>
            <div className="my-2 mb-2 d-flex justify-content-between">
              <div>
                {/* <InputGroup className="d-inline me-2">
                  <DropdownButton
                    id="dropdown-basic-button"
                    variant="outline-secondary"
                    title={dropDownValue}
                  >
                    {clientList.map((client) => (
                      <Dropdown.Item
                        key={client.id}
                        value={dropDownValue}
                        onClick={() => handleClick(client.id, client.name)}
                      >
                        {client.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </InputGroup> */}
                <Button
                  onClick={() => {
                    setReferenceDateModal(false)
                    setSummaryModal(true)
                    setReqObj({
                      clientId: "",
                      startDate: undefined,
                      endDate: undefined,
                      endingBalance: "",
                      emailSub: ""
                    })
                    dateValues();
                  }}
                  // className="d-inline"
                  variant="outline-secondary"
                >
                  Client Summary
                </Button>
                <Button
                  onClick={() => {
                    setReferenceDateModal(true)
                    setSummaryModal(true)
                    setReqObj({
                      clientId: "",
                      startDate: undefined,
                      endDate: undefined,
                      startingBalance: "",
                      endingBalance: "",
                      emailSub: ""
                    })
                    dateValues();
                  }}

                  className="ms-2"
                  variant="outline-secondary"
                >
                  Reference Date Summary
                </Button>
                <Button
                  onClick={dailyAccountingTrigger}
                  className="ms-2"
                  variant="outline-secondary"
                >
                  Daily Accounting trigger
                </Button>
              </div>
              <div>
                <Button variant="danger" onClick={resetBtn} className="">
                  <i className="fa-solid fa-rotate-left"></i>
                </Button>
              </div>
            </div>
            <Table bordered responsive>
              <thead>
                <tr>
                  <FilterSearch
                    name="Client ID"
                    table="ledgerEntry"
                    column="clientId"
                    type="contains"
                    search={filterSearch}
                    ref={filterSearchRef1}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  {/* <th>Amount</th> */}
                  <FilterSearch
                    name="Amount"
                    table="ledgerEntry"
                    column="amountInLocal"
                    search={filterSearch}
                    ref={filterSearchRef2}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  {/* <th>Amount In INR</th> */}
                  <FilterSearch
                    name="Amount In INR"
                    table="ledgerEntry"
                    column="amountInInr"
                    search={filterSearch}
                    ref={filterSearchRef3}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  <FilterSearch
                    name="Type"
                    table="ledgerEntry"
                    column="type"
                    type="equal"
                    search={filterSearch}
                    ref={filterSearchRef4}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  <FilterSearch
                    name="Order ID"
                    table="ledgerEntry"
                    column="orderItemId"
                    type="contains"
                    search={filterSearch}
                    ref={filterSearchRef5}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  <FilterSearch
                    name="Remark"
                    table="ledgerEntry"
                    column="remark"
                    type="contains"
                    search={filterSearch}
                    ref={filterSearchRef6}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  <FilterSearch
                    name="Created At"
                    table="ledgerEntry"
                    column="createdAt"
                    type="date"
                    search={filterSearch}
                    ref={filterSearchRef7}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  <FilterSearch
                    name="Reference Date"
                    table="ledgerEntry"
                    column="referenceDate"
                    type="date"
                    search={filterSearch}
                    ref={filterSearchRef8}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.clientId}</td>
                    <td>
                      {item.amountInLocal} {item.Wallet.currency.code}
                    </td>
                    <td>{item.amountInInr}</td>
                    <td>{item.type}</td>
                    <td className="text-primary">
                      <Link to={`/orderItemHistory/${item.orderItemId}`}
                        className="text-primary"
                      >
                        {item.orderItemId}
                      </Link>
                    </td>
                    <td>
                      <TruncateCell text={item.remark || "-"} maxLength={15} />
                    </td>
                    <td>{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                    <td>{item.referenceDate ? moment(item.referenceDate).format("DD/MM/YYYY HH:mm") : "-"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {tableData && !!tableData.length && tableData.length > 0 && (
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {tableData.length} / {totalCount}
                </div>
                <div>
                  <Pagination>{items}</Pagination>
                </div>
              </div>
            )}
            <div className="text-center my-2">
              {tableData && totalCount > tableData.length && (
                <Button variant="dark" onClick={handleLoadMore}>
                  Load More
                </Button>
              )}
            </div>
          </div>
        )
      )}
      {tableData?.length === 0 && (
        <>
          <Button onClick={resetBtn}>Go Back</Button>
          <h4 className="text-center">No Data Available</h4>
        </>
      )}

      {/* Modal for summary Ledger */}

      <Modal show={summaryModal} onHide={handleSummaryModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{referenceDateModal ? "Reference Date Summary" : "Ledger Summary"}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={referenceDateModal ? refernceModalHandler : getSummaryBtn}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Select Client</Form.Label>
              <Form.Select
                required
                value={reqObj?.clientId}
                onChange={(e) =>
                  setReqObj({ ...reqObj, clientId: e.target.value })
                }
              >
                <option value="">Select Client</option>
                {clientList.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              {/* <Form.Label>Start Date</Form.Label> */}
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="date-picker-style w-100"
                selected={reqObj?.startDate}
                onChange={(date) => {
                  if (date) {
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                  }
                  setReqObj({ ...reqObj, startDate: date });
                }}
                disabledKeyboardNavigation
                required
                placeholderText=" Start Date"
              />
            </Form.Group>
            <Form.Group className="mb-3 w-100" controlId="formBasicPassword">
              {/* <Form.Label>End Date</Form.Label> */}
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="date-picker-style w-100"
                selected={reqObj?.endDate}
                onChange={(date) => {
                  if (date) {
                    date.setHours(23);
                    date.setMinutes(59);
                    date.setSeconds(59);
                  }
                  setReqObj({ ...reqObj, endDate: date });
                }}
                required
                disabledKeyboardNavigation
                placeholderText=" End Date"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSummaryModalClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Get Summary
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={emailSubModal} onHide={emailSubHandleClose}>
        <Form onSubmit={sendInvoice}>
          <Modal.Header closeButton>
            <Modal.Title>Reference Invoice Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder=" Enter email subject"
                autoFocus
                value={reqObj?.emailSub}
                onChange={(e) => setReqObj({ ...reqObj, emailSub: e.target.value })}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={emailSubHandleClose}>
              cancel
            </Button>
            <Button variant="primary" type="submit">
              Confirm
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default Ledger;
