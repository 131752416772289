import React from 'react'

const TrackingOrderCard = ({location,time,status}) => {
  return (
    <div className='bg-light text-dark text-start p-2 mt-2 m-2 flag_custom'>
      <span className='pt-1 flag_custom'><span className='fw-bold'>Location</span>: {location}</span><br />
      <span className='flag_custom'><span className='fw-bold'>Time</span>: {time}</span><br />
      <span className='flag_custom'><span className='fw-bold'>Status</span>: {status}</span>
    </div>
  )
}

export default TrackingOrderCard
