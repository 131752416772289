import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Button, Container, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { fetchPrice, getAllClients, getPriceSlabs, historyData } from '../../../Services/adminService';
import { useSelector } from "react-redux";
import moment from "moment";
import FilterSearch from '../../../SharedComponents/filterSearch/filterSearch';
import { JSONTree } from 'react-json-tree';
import DatePicker from "react-datepicker";
import { errorToast } from '../../../Services/toastService';

const History = () => {
    const [tableData, setTableData] = useState(null)
    const [tableCount, setTableCount] = useState()
    const token = useSelector((state) => state.token);
    const [reqObj, setReqObj] = useState({
        filterArr: [],
        limit: 10,
        offSet: null,
    })
    const [orderBy, setOrderBy] = useState({ "updatedAt": "desc" })
    const filterSearchRef1 = useRef(null);
    const filterSearchRef2 = useRef(null);
    const [valueModal, setValueModal] = useState(false)
    const [value, setValue] = useState(null) // value for modal
    const [calculatePriceModal, setCalculatePriceModal] = useState(false)
    const [clientList, setClientList] = useState([])
    const [priceSlabData, setPriceSlabData] = useState({
        productName: "",
        walletId: "",
        shipSlabId: "",
        calDate: ""
    })
    const [priceSlabList, setPriceSlabList] = useState(null)
    const [fetchBtnLoading, setFetchBtnLoading] = useState(false)
    const [fetchObj, setFetchObj] = useState(null);
    const [loading, setLoading] = useState(false)

    const getHistoryData = async () => {
        try {
            const res = await historyData(token, reqObj, orderBy)
            setTableCount(res.data.totalCount)
            setTableData(res.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleLoadMore = async () => {
        let newObj = { ...reqObj, offSet: tableData.length };
        const res = await historyData(token, newObj, orderBy)
        let newData = res.data.data
        let newTable = [...tableData, ...newData]
        setTableData(newTable)
    }

    const filterSearch = async (obj, isSelected) => {
        let current = reqObj.filterArr;
        current = current.filter((item) => item.column !== obj.column);
        if (isSelected) {
            setReqObj({ ...reqObj, filterArr: current })
        } else {
            current.push(obj);
            setReqObj({ ...reqObj, filterArr: current })
        }
    };

    const fetchInitialData = async () => {
        try {
            setLoading(true)
            const [clientsResponse, priceSlabsResponse] = await Promise.all([
                getAllClients(token),
                getPriceSlabs(token)
            ]);
            const clientData = clientsResponse.data.clients;
            const priceSlabsData = priceSlabsResponse.data;
            setClientList(clientData)
            setPriceSlabList(priceSlabsData)
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    };

    const handleFetchPrice = async () => {
        setFetchObj(null)
        setFetchBtnLoading(true)
        try {
            if (!priceSlabData.calDate || !priceSlabData.productName || !priceSlabData.shipSlabId || !priceSlabData.walletId) {
                return errorToast("All fields are required !!!")
            }
            const res = await fetchPrice(token, priceSlabData)
            if (res.status === 200) {
                const data = res.data
                setFetchObj({ ...data })
            }
        } catch (error) {
            errorToast(error.response?.data?.message || "Something went wrong")
        } finally {
            setFetchBtnLoading(false)
        }
    }

    const handleModalClose = () => {
        setCalculatePriceModal(false)
        setPriceSlabData({
            productName: "",
            walletId: "",
            shipSlabId: "",
            calDate: ""
        })
        setFetchObj(null)
    }

    useLayoutEffect(() => {
        fetchInitialData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getHistoryData()
        // eslint-disable-next-line
    }, [orderBy, reqObj.limit, reqObj.filterArr])

    return (
        <Container>
            {!loading &&
                (
                    <Button
                        className='mb-2'
                        variant="outline-secondary"
                        onClick={() => setCalculatePriceModal(true)}
                    >
                        Calculate Price
                    </Button>
                )}
            {
                loading ? (
                    <div className='text-center'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <>
                        {tableData?.length ? (
                            <Table bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <FilterSearch
                                            name="Type"
                                            table="revisionHistory"
                                            column="type"
                                            type="equal"
                                            search={filterSearch}
                                            ref={filterSearchRef2}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                        />
                                        <FilterSearch
                                            name="Updated At"
                                            table="products"
                                            column="updatedAt"
                                            type="date"
                                            search={filterSearch}
                                            ref={filterSearchRef1}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                        />
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData?.map((item, i) => (
                                        <tr key={i} onClick={() => {
                                            setValue(item.value);
                                            setValueModal(true);
                                        }}>
                                            <td>{item.foreignKey}</td>
                                            <td>{item.name}</td>
                                            <td>{item.type}</td>
                                            <td>{moment(item.updatedAt).format("DD/MM/YY HH:mm")}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <h1 className='mt-2 text-center'>No Data Available</h1>
                        )}

                        {!!tableData && (
                            <>
                                {tableData.length + " / " + tableCount}
                                {tableData.length < tableCount && (
                                    <div className='mt-2 text-center'>
                                        <Button onClick={handleLoadMore}>
                                            Load More
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )
            }
            <Modal
                show={valueModal}
                onHide={() => {
                    setValueModal(false)
                    setValue(null)
                }}
                dialogClassName="modal-90w"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Sub-Products</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {value ? <pre>{JSON.stringify(value, null, 2)}</pre> : "No data available"} */}
                    {value ? <JSONTree data={value} /> : "No data available"}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setValueModal(false)
                        setValue(null)
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* test modal */}
            <Modal
                show={calculatePriceModal}
                onHide={handleModalClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Calculate Old Price</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Product</Form.Label>
                            <Form.Control
                                type="test"
                                placeholder=" Enter Product"
                                value={priceSlabData.productName}
                                onChange={(e) =>
                                    setPriceSlabData({
                                        ...priceSlabData,
                                        productName: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Select Price Slab</Form.Label>
                            <Form.Select
                                required
                                value={priceSlabData.shipSlabId}
                                onChange={(e) =>
                                    setPriceSlabData({ ...priceSlabData, shipSlabId: e.target.value })
                                }
                            >
                                <option value="">Select</option>
                                {priceSlabList?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item?.country?.name}&nbsp;,{item?.shipmentType?.code}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Select Client</Form.Label>
                            <Form.Select
                                required
                                value={priceSlabData.walletId}
                                onChange={(e) =>
                                    setPriceSlabData({ ...priceSlabData, walletId: e.target.value })
                                }
                            >
                                <option value="">Select Client</option>
                                {clientList?.map((client) => (
                                    <option key={client.id} value={client.Wallet?.id}>
                                        {client.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Select Date</Form.Label>
                            <DatePicker
                                className="date-picker-style w-100"
                                selected={priceSlabData.calDate}
                                onChange={(date) => {
                                    if (date) {
                                        date.setHours(23);
                                        date.setMinutes(59);
                                        date.setSeconds(59);
                                    }
                                    setPriceSlabData({ ...priceSlabData, calDate: date });
                                }}
                                disabledKeyboardNavigation
                                required
                                placeholderText=" Date"
                            />
                        </Form.Group>
                    </Form>
                    {fetchObj && (
                        <span>Calculated Price : {fetchObj?.finalPrice} {fetchObj?.currency}</span>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button onClick={handleFetchPrice} disabled={fetchBtnLoading}>
                        Fetch
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default History;