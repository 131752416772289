import React, { useEffect, useRef, useState } from "react";
import "./anonymousOrder.css";
import {
  Button,
  Container,
  Form,
  Spinner,
  Table,
  Modal,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  placeMiscellaneousOrder,
  uploadMiscellaneousOrder,
} from "../../Services/orderService";
import { successToast, errorToast } from "../../Services/toastService";
import { getAllClients } from "../../Services/adminService";
import DatePicker from "react-datepicker";
import moment from "moment";

function AnonymousOrder() {
  const [orderFile, setOrderFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const token = useSelector((state) => state.token);
  const [sCount, setSCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [orderModal, setOrderModal] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(undefined);
  const role = useSelector((state) => state.user.role);
  const [fileName, setFileName] = useState("")
  const [confirmShow, setConfirmShow] = useState(true)
  const [orderDate, setOrderDate] = useState(null)

  const loadData = async () => {
    try {
      // const response = await getConfigByKey(token, "sendRawFile");
      if (["admin", "dispatch_manager", "accountant"].includes(role)) {
        const clientData = await getAllClients(token);
        setClientList(clientData.data.clients);
      }
      // if (response.status === 200) {
      //   const keyValue = response.data.value;
      //   if (keyValue === "yes") {
      //     setSendFile(true);
      //   } else if (keyValue === "no") {
      //     setSendFile(false);
      //   }
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const fileInputRef = useRef(null);

  async function handleUpload() {
    const formData = new FormData();
    formData.append("xlsx", orderFile);
    if (["admin", "dispatch_manager", "accountant"].includes(role)) {
      if (!selectedClient) {
        errorToast("Select client to continue");
        return;
      }
      formData.append("clientID", selectedClient);
    }
    try {
      setIsLoading(true);
      let response = await uploadMiscellaneousOrder(token, formData);
      const orderList = response.data.orderList;
      orderList.forEach(order => {
        if (!order.productCode) {
          setConfirmShow(false)
        }
      })
      setTableData(orderList);
      setTotalCount(orderList.length);
      const [s, t] = updateSuccessCount(orderList);
      if (response.status === 200) {
        setIsLoading(false);
        if (s === t) {
          successToast(`All items are valid`);
        } else {
          errorToast(`${t - s} / ${t} are invalid, edit to check it again`);
        }
      }
    } catch (err) {
      setIsLoading(false);
      if (!err.response) {
        return errorToast("Something went wrong");
      }
      if (err.response?.status === 500) {
        errorToast("Invalid File");
      }
    }
  }

  const updateSuccessCount = (orderList) => {
    let successCount = 0;
    orderList.forEach((item) => {
      item.productCode && (successCount += 1);
    });
    setSCount(successCount);
    return [successCount, orderList.length];
  };

  const successOrder = () => setOrderModal(!orderModal);

  const orderSubmit = async () => {
    try {
      setIsLoading(true);
      if (!fileName.trim()) {
        return errorToast("Filename is required!!!")
      }
      if (!orderDate) {
        return errorToast("Order Date is required!!!")
      }
      let reqObj = {};
      reqObj.uploadedFilename = fileName.trim();
      if (["admin", "dispatch_manager", "accountant"].includes(role)) {
        reqObj.clientID = selectedClient;
        reqObj.orderDate = orderDate;
        for (let i = 0; i < clientList.length; i++) {
          if (clientList[i].id === selectedClient) {
            reqObj.clientEmail = clientList[i].email
            break;
          }
        }
      }
      reqObj.orderList = tableData;
      const response = await placeMiscellaneousOrder(token, reqObj);
      if (response.status === 201) {
        setOrderModal(true);
        setTableData(null);
        setSCount();
        setTotalCount();
        setSelectedClient(undefined);
        setFileName("")
      }
    } catch (err) {
      errorToast(err.response?.data?.message || "Something went wrong");
      if(err.response?.data?.message !== "Filename already exists" ){
        setTableData(null)
      }
      setFileName("")
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <h4 className="my-4">Place Anonymous Order</h4>

      {tableData ? (
        <>
          <Button
            variant="dark"
            className='mb-2'
            onClick={() => {
              setTableData(null);
              setOrderFile(null);
              setSelectedClient(undefined);
              setConfirmShow(true)
              setFileName("")
            }}
          >
            <i className="fa-solid fa-arrow-left"></i> Go Back
          </Button>
        </>
      ) : (
        <>
          <div className="mb-3">
            <Form.Group controlId="formFile" className="file-inp">
              <Form.Label>Order File</Form.Label>
              <Form.Control
                ref={fileInputRef}
                type="file"
                accept=".xlsx, .tsv, .xls, .txt"
                onChange={(e) => {
                  setOrderFile(e.target.files[0]);
                }}
              />
            </Form.Group>
            <small className="text-danger">
              Upload only xls, xlsx or tsv file <br />
              <a
                className="text-primary download_Link"
                href="/Sample Order File.xlsx"
                download="Sample File"
              >
                Download Sample File
              </a>
            </small>
          </div>
          {["admin", "dispatch_manager", "accountant"].includes(role) && (
            <>
              <Form.Label>Order on behalf of</Form.Label>
              <Form.Select
                onChange={(e) =>
                  setSelectedClient(
                    e.target.value !== "null" ? e.target.value : undefined
                  )
                }
                className="mb-3 w-25"
              >
                <option defaultValue value="null">
                  Select client
                </option>
                {clientList.map((client) => (
                  <option value={client.id} key={client.id}>
                    {client.name}
                  </option>
                ))}
              </Form.Select>
            </>
          )}
          <Button variant="dark" onClick={handleUpload} disabled={!orderFile}>
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Preview Order"
            )}
          </Button>
        </>
      )}

      {tableData && confirmShow && (
        <div>
          <div className="mb-4 w-100">
            <Form.Control
              type="text"
              placeholder=" Enter file name"
              className="w-25 my-2"
              onChange={(e) => setFileName(e.target.value)}
            />
            <DatePicker
              className="date-picker-style w-25"
              selected={orderDate}
              onChange={(date) => {
                  if (date) {
                    date = moment(date).set({ hour: 12 }).toDate()
                    setOrderDate(date)
                  }
              }}
              disabledKeyboardNavigation
              required
              placeholderText="   Order Date"
              dateFormat="dd/MM/yyyy"
          />
          </div>
          <Button variant="success" onClick={orderSubmit}>
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              `Confirm ${sCount}/${totalCount}`
            )}
          </Button>
        </div>
      )}

      {tableData && (
        <>
          <h6 className="mt-2">
            <span className="text-danger">
              * Invalid product name, shipment type or country, edit to check it again
            </span>
          </h6>
          <h6 className="mt-2">
            <span>* Valid Input</span>
          </h6>
          <Table bordered variant="secondary" className="my-4 mb-4" responsive>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Consignee Name</th>
                <th>Country</th>
                <th>Delivery Address</th>
                <th>Phone</th>
                <th>Pincode</th>
                {/* <th>Price</th>
                <th>Shipment Price</th> */}
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                return (
                  <tr key={index} >
                    <td className={`${(item.productCode) ? '' : 'text-danger border-danger'}`}>
                      {item.displayName}
                    </td>
                    <td className={`${(item.productCode) ? '' : 'text-danger border-danger'}`}>
                      {item.consigneeName}
                    </td>
                    <td className={`${(item.productCode) ? '' : 'text-danger border-danger'}`}>
                      {item.country}
                    </td>
                    <td className={`${(item.productCode) ? '' : 'text-danger border-danger'}`}>
                      {item.deliveryAddress}
                    </td>
                    <td className={`${(item.productCode) ? '' : 'text-danger border-danger'}`}>
                      {item.phone}
                    </td>
                    <td className={`${(item.productCode) ? '' : 'text-danger border-danger'}`}>
                      {item.pincode}
                    </td>
                    {/* <td className={`${(item.productCode) ? '' : 'text-danger border-danger'}`}>
                      {item.price}
                    </td>
                    <td className={`${(item.productCode) ? '' : 'text-danger border-danger'}`}>
                      {item.weightPrice}
                    </td> */}
                  </tr>)
              })}
            </tbody>
          </Table>
        </>
      )
      }
      <Modal show={orderModal} onHide={successOrder}>
        <Modal.Header closeButton>
          <Modal.Title>Order Created Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Order Created Successfully check your mail for order details!!!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={successOrder}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Container >
  );
}

export default AnonymousOrder;
