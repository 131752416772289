import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    mode: "light",
    token: null,
    table:[
        { key: "EDN", value: "id", type: "contains",disabled: false },
        { key: "Product Name", value: "productName", type: "contains", disabled: false },
        { key: "Shipment Type", value: "shipmentType", type: "contains", disabled: false },
        { key: "Consignee Name", value: "consigneeName", type: "contains", disabled: false },
        { key: "Address", value: "deliveryAddress", type: "contains", disabled: false },
        { key: "Pincode", value: "pincode", type: "contains", disabled: false },
        { key: "Country", value: "country", type: "contains", disabled: false },
        { key: "Phone", value: "phone", type: "contains", disabled: false },
        { key: "Tracking Id", value: "trackingId", type: "contains", disabled: false },
        { key: "Document Date", value: "createdAt", type: "date", disabled: false },
        { key: "Price", value: "price", type: undefined, disabled: false }
      ],
      productTable:[
        { key: "ID", value: "id", type: "contains",disabled: false },
        { key: "Name", value: "name", type: "contains", disabled: false },
        { key: "Composition Name", value: "compositionName", type: "contains", disabled: false },
        { key: "Weight", value: "weight", type: null, disabled: false },
        { key: "Box Number", value: "boxNumber", type: "contains", disabled: false },
        { key: "Price", value: "price", type: null, disabled: false },
        { key: "Unit", value: "unitOfMeasure", type: "equal", disabled: false },
        { key: "Is Assembly", value: "isAssembly", type: "boolean", disabled: false },
        { key: "Sale UOM", value: "saleUOM", type: "equal", disabled: false },
        { key: "Is Orderable", value: "isOrderable", type: null, disabled: false },
      ],
}

export const authSlice = createSlice({
    name:"auth",
    initialState,
    reducers:{
        setLogin:(state,action)=>{
            state.user = action.payload.user
            state.token = action.payload.token
        },
        setLogout: (state) => {
            state.user = null;
            state.token = null;
        },
        setMode:(state)=>{
            state.mode = state.mode === "light"?"dark":"light"
        },
        setTable: (state, action) => {
            state.table = action.payload ? action.payload.table : initialState.table;
        },
        setProductTable: (state, action) => {
            state.productTable = action.payload ? action.payload.productTable : initialState.productTable;
        },
    }
})

export const {setLogin,setLogout,setMode,setTable,setProductTable} = authSlice.actions;
export default authSlice.reducer;
