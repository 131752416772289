import React, { useRef, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import "./labelTemplate.css";
import { errorToast, successToast } from "../../Services/toastService";
import { LabelHtmlTemplate } from "../../utils/labelTemplate";
import * as XLSX from 'xlsx';
// import { InvoiceTemplate } from "../../utils/invoiceTemplate";
import { generalTemplate, inVoiceTemplate } from "../../Services/adminService";
import { useSelector } from "react-redux";

const Label = () => {
  const token = useSelector((state) => state.token);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectDocFile, setSelectDocFile] = useState(null);
  const fileInputRef = useRef(null)
  const [uploadBtnLoading, setUploadBtnLoading] = useState(false)
  const [labelValue, setLabelValue] = useState("label")

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDocFileChange = (event) => {
    setSelectDocFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    try {
      setUploadBtnLoading(true);
      if (!selectedFile) {
        return errorToast("Please Select File");
      } if (labelValue === "") {
        return errorToast("Please Select File Type");
      }
      const fileReader = new FileReader();
      fileReader.onload = async (event) => {
        try {
          const data = event.target.result;
          const readdData = XLSX.read(data, { type: 'binary' });
          const wsName = readdData.SheetNames[0];
          const ws = readdData.Sheets[wsName];
          const dataParse = XLSX.utils.sheet_to_json(ws);
          let htmlContent = "";
          if (labelValue === "label") {
            dataParse.forEach((item) => {
              htmlContent += LabelHtmlTemplate(item);
            });
            const blob = new Blob([htmlContent], { type: "text/html" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "label";
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(url);
          } else if (labelValue === "invoice") {
            try {
              const formData = new FormData()
              formData.append("xlsxFile", selectedFile)
              const res = await inVoiceTemplate(token, formData)
              successToast(res?.data?.message)
            } catch (err) {
              errorToast(err?.response?.data?.message || "Sonething went wrong")
            }
          } else if (labelValue === "general") {
            try{
              const formData = new FormData();
              formData.append("xlsxFile", selectedFile)
              formData.append("docFile",selectDocFile)
              const res = await generalTemplate(token, formData)
              successToast(res?.data?.message)
            }catch(err){
              errorToast(err?.response?.data?.message || "Sonething went wrong")
            }
          }
        } catch (error) {
          errorToast(error.message || "Something Went Wrong");
        } finally {
          setUploadBtnLoading(false);
        }
      };
      fileReader.readAsBinaryString(selectedFile);
    } catch (error) {
      errorToast(error.message || "Something Went Wrong");
    } finally {
      setUploadBtnLoading(false);
    }
  };


  return (
    <Container>
      <h4 className="my-4">Label</h4>
      <Form.Group controlId="formFile" className="mb-4 file">
        <Form.Label className="mt-2">Select Type</Form.Label>
        <Form.Select
          value={labelValue}
          onChange={(e) =>
            setLabelValue(e.target.value)
          }
        >
          <option value="label">Label</option>
          <option value="invoice">In Voice</option>
          <option value="general">General</option>
        </Form.Select>
        <Form.Label className="mt-2">Upload Excel</Form.Label>
        <Form.Control
          type="file"
          accept=".xlsx, .tsv, .xls, .numbers"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        {
          labelValue === "general" &&
          <>
            <Form.Label className="mt-2">Upload Document</Form.Label>
            <Form.Control
              type="file"
              accept=".doc, .docx"
              onChange={handleDocFileChange}
            />
          </>
        }
        <small className="text-danger">
          Upload only xls or xlsx file <br />
        </small>
        {
          labelValue !== "general" &&
          <small className="mt-2">
            <a
              className="text-primary download_Link"
              href={labelValue === "label" ? "/Label Generator Data Excel (1).xlsx" : "/invoice_template.xlsx"}
              download={labelValue === "label" ? "Label Demo File" : "Invoice Demo File"}
            >
              Download Sample File
            </a>
          </small>
        }
      </Form.Group>

      <Button variant="primary" onClick={handleFileUpload} disabled={uploadBtnLoading}>
        Upload File
      </Button>
    </Container>
  );
};
export default Label;
