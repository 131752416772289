import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Modal, Table, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { errorToast, successToast } from "../../Services/toastService";
import "./products.css";
import {
  searchProduct,
  fetchBasedProductById,
  triggerMSBCProductsCron,
} from "../../Services/adminService";
import { TruncateCell } from "../../SharedComponents/truncateCell/truncateCell";
import moment from "moment";
import FilterSearch from "../../SharedComponents/filterSearch/filterSearch";
import Pagination from "react-bootstrap/Pagination";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";

const Products = () => {
  const [products, setProducts] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [cronTriggerLoading, setCronTriggerLoading] = useState(false);
  const token = useSelector((state) => state.token);
  const [allExpanded, setAllExpanded] = useState(false);
  const [allSubExpanded, setAllSubExpanded] = useState(false);
  const [baseProduct, setBaseProduct] = useState(null);
  const [baseProductModal, setBaseProductModal] = useState(false);
  const [searchObj, setSearchObj] = useState([]);
  const filterSearchRef1 = useRef(null);
  const filterSearchRef2 = useRef(null);
  const filterSearchRef3 = useRef(null);
  const filterSearchRef4 = useRef(null);
  const filterSearchRef5 = useRef(null);
  const filterSearchRef6 = useRef(null);
  const filterSearchRef7 = useRef(null);
  const filterSearchRef8 = useRef(null);
  const filterSearchRef10 = useRef(null);
  const [reqObj, setReqObj] = useState({
    currencyID: "",
    limit: 10,
    offSet: null,
  });
  const [orderBy, setOrderBy] = useState({ name: "asc" });
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [errorModal, setErrorModal] = useState(false)
  const [errorModalData, setErrorModalData] = useState([])
  const navigate = useNavigate();
  let items = [];
  let paginationNum = [10, 25, 50];
  paginationNum.forEach((number) => {
    items.push(
      <Pagination.Item
        key={number}
        active={number === reqObj.limit}
        onClick={() => setReqObj({ ...reqObj, limit: number })}
      >
        {number}
      </Pagination.Item>
    );
  });

  let { id } = useParams();

  const searchProductBtn = async () => {
    try {
      if (id) {
        let newObj = [
          {
            column: "id",
            type: "contains",
            value: id,
          },
        ];
        const response = await searchProduct(token, newObj, reqObj, orderBy);
        const data = response.data.products;
        if (data.length === 0) {
          errorToast("No product found with this name");
        }
        setProducts(data);
        setTotalCount(response.data.productCount);
      } else {
        const response = await searchProduct(token, searchObj, reqObj, orderBy);
        const data = response.data.products;
        if (data.length === 0) {
          errorToast("No product found with this name");
        }
        setProducts(data);
        setTotalCount(response.data.productCount);
      }
    } catch (err) {
      if (!err.response) {
        return errorToast("Something went wrong");
      }
      errorToast(err.response?.data.message);
    }
  };

  const handleLoadMore = async () => {
    try {
      setLoadMoreLoading(true);
      let newObj = { ...reqObj, offSet: products.length };
      const response = await searchProduct(token, searchObj, newObj, orderBy);
      let table = [...products];
      table.push(...response.data.products);
      setProducts(table);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadMoreLoading(false);
    }
  };

  useEffect(() => {
    searchProductBtn();
    // eslint-disable-next-line
  }, [searchObj, reqObj.currencyID, reqObj.limit, orderBy]);

  async function updateProductList() {
    try {
      if (
        window.confirm("Do you really want to update products from MSBC?") ===
        true
      ) {
        setCronTriggerLoading(true);
        let res = await triggerMSBCProductsCron(token);
        successToast(res.data.message || "Successfully Updated!");
        setCronTriggerLoading(false);
        resetFilter();
      }
    } catch (err) {
      if (err.response?.status === 400) {
        setErrorModal(true);
        setErrorModalData(err.response?.data?.message || "Something went wrong");
      } else {
        errorToast(err.response?.data?.message || "Something went wrong!");
      }
      setCronTriggerLoading(false);
    }
  }

  const getFetchBasedProductById = async (item, productIDCache) => {
    try {
      const response = await fetchBasedProductById(
        token,
        item.id,
        productIDCache
      );
      let table = response.data.assemblyFetchData;
      setBaseProduct(table);
    } catch (err) {
      console.log(err);
    }
    setBaseProductModal(true);
  };

  const filterSearch = async (obj, isSelected) => {
    let current = searchObj;
    current = current.filter((item) => item.column !== obj.column);
    if (isSelected) {
      setSearchObj(current);
    } else {
      current.push(obj);
      setSearchObj(current);
    }
  };

  const resetFilter = () => {
    if (id) {
      navigate("/products");
    }
    setSearchObj([]);
    setOrderBy({ name: "asc" });
    setReqObj({
      currencyID: "",
      limit: 10,
      offSet: null,
    });
    filterSearchRef1.current && filterSearchRef1.current.resetFilter();
    filterSearchRef2.current && filterSearchRef2.current.resetFilter();
    filterSearchRef3.current && filterSearchRef3.current.resetFilter();
    filterSearchRef4.current && filterSearchRef4.current.resetFilter();
    filterSearchRef5.current && filterSearchRef5.current.resetFilter();
    filterSearchRef6.current && filterSearchRef6.current.resetFilter();
    filterSearchRef7.current && filterSearchRef7.current.resetFilter();
    filterSearchRef8.current && filterSearchRef8.current.resetFilter();
    filterSearchRef10.current && filterSearchRef10.current.resetFilter();
    // setDispatchStatus("Select Status");
  };

  const downloadTableData = () => {
    if (!products) {
      errorToast("Table is empty");
      return;
    }
    let headers = [
      "id",
      "name",
      "weight",
      "boxNumber",
      "unitOfMeasure",
      "saleUOM",
      "isAssembly",
      "isOrderable",
      "updatedAt",
    ];

    // Map products and extract values based on headers
    const data = [
      headers,
      ...products.map((product) => {
        return headers.map((header) => product[header]);
      }),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
    const excelData = XLSX.write(workbook, { type: "binary" });

    const blob = new Blob([stringToArrayBuffer(excelData)], {
      type: "application/octet-stream",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "products.xlsx";

    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
  };

  const stringToArrayBuffer = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  return (
    <Container>
      <>
        <h4 className="my-4">"Product Lists"</h4>
        <div className="row mb-3">
          <div className="col-md-12 mt-1">
            <Button variant="outline-secondary" onClick={downloadTableData}>
              <i className="fa-solid fa-download"></i> View
            </Button>
            <Button
              className="m-1"
              variant="outline-warning"
              onClick={updateProductList}
              disabled={cronTriggerLoading}
            >
              <i className="fa-solid fa-arrows-rotate"></i>
              {cronTriggerLoading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                " Update From MSBC"
              )}
            </Button>
            <Button
              variant="outline-danger"
              id="button-addon2"
              onClick={resetFilter}
              className="m-1"
            >
              <i className="fa-solid fa-rotate-left"></i>
            </Button>
          </div>
        </div>
        {products && !!products.length && (
          <Table bordered responsive hover>
            <thead>
              <tr>
                <FilterSearch
                  name="ID"
                  table="products"
                  column="id"
                  type="contains"
                  search={filterSearch}
                  ref={filterSearchRef1}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
                <FilterSearch
                  name="Name"
                  table="products"
                  column="name"
                  type="contains"
                  search={filterSearch}
                  ref={filterSearchRef2}
                  toExpand={() => setAllExpanded(!allExpanded)}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
                <FilterSearch
                  name="Weight (Gram)"
                  table="products"
                  column="weight"
                  search={filterSearch}
                  ref={filterSearchRef8}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
                <FilterSearch
                  name="Box number"
                  table="products"
                  column="boxNumber"
                  type="contains"
                  search={filterSearch}
                  ref={filterSearchRef3}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
                <FilterSearch
                  name="Unit"
                  table="products"
                  column="unitOfMeasure"
                  type="equal"
                  search={filterSearch}
                  ref={filterSearchRef4}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
                <FilterSearch
                  name="Sale Unit"
                  table="products"
                  column="saleUOM"
                  type="equal"
                  search={filterSearch}
                  ref={filterSearchRef10}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
                <FilterSearch
                  name="Is Assembly"
                  table="products"
                  column="isAssembly"
                  type="boolean"
                  search={filterSearch}
                  ref={filterSearchRef5}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
                <FilterSearch
                  name="Is Orderable"
                  table="products"
                  column="isOrderable"
                  type="boolean"
                  search={filterSearch}
                  ref={filterSearchRef6}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
                <FilterSearch
                  name="Updated At"
                  table="products"
                  column="updatedAt"
                  type="date"
                  search={filterSearch}
                  ref={filterSearchRef7}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
              </tr>
            </thead>
            <tbody>
              {products.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>
                    <TruncateCell
                      text={item.name}
                      maxLength={allExpanded ? item.name.length : 20}
                    />
                  </td>
                  <td>{item.weight || "N/A"}</td>
                  <td>{item.boxNumber || "N/A"}</td>
                  <td>{item.unitOfMeasure}</td>
                  <td>{String(item.saleUOM)}</td>
                  <td
                    onClick={() => {
                      if (item.isAssembly) {
                        getFetchBasedProductById(item);
                      }
                    }}
                    role="button"
                    className={item.isAssembly ? "assembly-button" : ""}
                    style={{
                      cursor: item.isAssembly ? "pointer" : "default",
                    }}
                  >
                    {item.isAssembly ? (
                      <span className="text-primary">
                        {String(item.isAssembly)}
                      </span>
                    ) : (
                      String(item.isAssembly)
                    )}
                  </td>
                  <td>{String(item.isOrderable)}</td>
                  <td>{moment(item.updatedAt).format("DD/MM/YY HH:mm")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {products && !!products.length && (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {products.length} / {totalCount}
            </div>
            <div className="mt-2">
              <Pagination>{items}</Pagination>
            </div>
          </div>
        )}
        <div className="text-center my-2">
          {products && totalCount > products.length ? (
            <Button
              variant="dark"
              onClick={() => {
                setReqObj({ ...reqObj, offSet: products.length });
                handleLoadMore();
              }}
              disabled={loadMoreLoading}
            >
              {loadMoreLoading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Load More"
              )}
            </Button>
          ) : null}
        </div>
      </>

      {/* Modal start from here fetch product by id */}
      <Modal
        show={baseProductModal}
        onHide={() => setBaseProductModal(false)}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Sub-Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>Product Id</th>
                <th>Assembly Id</th>
                <th>
                  Name{" "}
                  <span
                    onClick={() => setAllSubExpanded(!allSubExpanded)}
                    className="readButton"
                  >
                    <u>{allSubExpanded ? "Read Less" : "Read More"}</u>
                  </span>
                </th>
                <th>Quantity</th>
                <th>Unit</th>
                <th>Created At</th>
                <th>Updated At</th>
              </tr>
            </thead>
            <tbody>
              {baseProduct &&
                baseProduct.map((dataItem, index) => (
                  <tr key={index}>
                    <td className="text-primary">
                      <Link to={`/products/${dataItem.productId}`}
                        className="text-primary"
                        onClick={() => {
                          setBaseProductModal(false);
                        }}
                      >
                        {dataItem.productId}
                      </Link>
                    </td>
                    <td>{dataItem.APId}</td>
                    <td>
                      <TruncateCell
                        text={dataItem.name}
                        maxLength={allSubExpanded ? dataItem.name.length : 20}
                      />
                    </td>
                    <td>{dataItem.quantity}</td>
                    <td>{dataItem.unitOfMeasure}</td>
                    <td>
                      {moment(dataItem.createdAt).format("DD/MM/YYYY HH:mm")}
                    </td>
                    <td>
                      {moment(dataItem.updatedAt).format("DD/MM/YYYY HH:mm")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setBaseProductModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={errorModal}
        onHide={() => {
          setErrorModal(false)
        }}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Validation Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorModalData?.map((data, i) => (
            <li key={i}>{data}</li>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setErrorModal(false)
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Products;
