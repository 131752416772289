export const LabelHtmlTemplate = ({ s_no, description_booking, net_weight, total_weight, date, contents, hsn, country_origin, consignee, fullAddress, country, consignee_contact, parcel_value }) => {
    return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Document</title>
        <style>
        * {
            box-sizing: border-box;
        }
        table tr td {
            text-align: left;
            width: 100px;
            height: 25px;
            padding: 5px;
        }
        </style>
    </head>
    <body>
        <table border="1" cellspacing="0" align="center">
        <tr style="height: 150px;">
            <td colspan="4" style="width: 60%; margin: 0;">
            <p style="line-height: 1.5rem;margin:0">
                ${s_no}
                <br />
                ${description_booking}
                <br />
                A-06/05
                <br />
                Wt: ${net_weight} 
                <br />
                Dt. ${date}
                <br />
                Contains:${contents}
            </p>
            </td>
            <td style="width: 40%" colspan="4">
            <p
                style="
                display: flex;
                justify-content: center;
                align-items: center;
                "
            >
                Barcode
            </p>
            </td>
        </tr>
        <tr>
            <td colspan="8">
            <p style="line-height: 1.5rem;margin:0">
                TO
                <br />
                <span style="font-weight: bold; text-decoration: underline"
                >${consignee}
                </span>
                <br />
                ${fullAddress}
                <br />
    
                Country:
                <span style="font-weight: bold; text-decoration: underline"
                >${country}</span
                >
                <br />
    
                Phone:${consignee_contact}
            </p>
            </td>
        </tr>
        <tr>
            <td colspan="4" rowspan="6">
            <p style="line-height: 1.5rem; font-weight: bold; text-align: center">
                If Undelivered Kindly Return to:
                <br />
                Cyno Medicament
                
                <br />
                Cyno Medicaments 1F/2, B - Block Market, Surajmal Vihar, Delhi - 110
                092, INDIA Tel .: +91 98682 64733
                <br />
    
                Delhi - 110092
                <br />
                Phone:+91-9868264733
    
            </p>
            </td
            >
        </tr>
        <tr>
            <td colspan="2">
            <p style="text-align: center">CUSTOMS DECLARATION</p>
            </td>
            <td colspan="2">
            <p style="text-align: center">May be opened officially</p>
            </td>
        </tr>
        <tr>
            <td colspan="2">
            <p style="text-align: center">Postal Administration</p>
            </td>
            <td colspan="2">
            <p style="text-align: center">
                Important See Instructions on the back
            </p>
            </td>
        </tr>
        <tr>
            <td style="">
            <input type="checkbox" /> <br /><input type="checkbox" />
            </td>
            <td style=""><p style="text-align: center">Gift Documents</p></td>
            <td>
            <input type="checkbox" /><br />
            <input type="checkbox" />
            </td>
            <td><p style="text-align: center">Commercial sample Other</p></td>
        </tr>
        <tr>
            <td colspan="2">
            <p style="text-align: center">
                Quantity and detailed description of contents (1)
            </p>
            </td>
            <td><p style="text-align: center">Weight (in kg)(2)</p></td>
            <td><p style="text-align: center">Value (3)</p></td>
        </tr>
        <tr>
            <td colspan="2">${contents}</td>
            <td>${total_weight}</td>
            <td>${parcel_value}</td>
        </tr>
        <tr>
            <td colspan="4" rowspan="6">
            <div style="display: flex; justify-content: center;">
                <img
                    src="https://www.cyno.co.in/wp-content/uploads/2017/08/cyno-logo.png"
                    width="150"
                    height="100"
                />
            </div>
            <p style="text-align: center">
                Cyno Medicaments 1F/2, B - Block Market, Surajmal Vihar, Delhi - 110
                092, INDIA Tel .: +91 98682 64733
            </p>
            </td>
        </tr>
        <tr>
            <td colspan="2">
            <p style="text-align: center">
                For commercial items only If known, HS tariff number (4) and country
                of origin of goods (5)
            </p>
            </td>
            <td>
            <p style="text-align: center">Total Weight (in kg) (6)</p>
            </td>
            <td><p style="text-align: center">Total value (7)</p></td>
        </tr>
        <tr>
            <td colspan="2">${hsn} (${country_origin})</td>
            <td>${total_weight}</td>
            <td>${parcel_value}</td>
        </tr>
        <tr>
            <td colspan="4">
            I, the undersigned whose name and address are given on the item,
            certify that the particulars given in this declaration are correct and
            that this item does not contain any dangerous article, or articles
            prohibited by legislation or by postal or customs regulations. Date
            and sender's signature (8)
            </td>
        </tr>
        </table>
    </body>
    </html>
    `;
}