import React, { useEffect, useRef, useState } from 'react'
import { Button, Container, Form, ListGroup, Modal, Spinner, Table } from 'react-bootstrap'
import { deletePriceEntity, downloadEntities, entitiesData, getCurrencyCode, getPriceGroup, previewFile, searchProduct, updatePriceEntities, upsertPriceEntities } from '../../../Services/adminService';
import { useSelector } from 'react-redux';
import { errorToast, successToast } from '../../../Services/toastService';
import FilterSearch from '../../../SharedComponents/filterSearch/filterSearch';
import * as XLSX from "xlsx";
import Pagination from "react-bootstrap/Pagination";
import { getCountryList, getShipment } from '../../../Services/shipmentService';
import TruncateCell from '../../../SharedComponents/truncateCell/truncateCell';
import moment from 'moment';
import DatePicker from "react-datepicker";
import useDebounce from '../../../lib/customHooks/useDebounce';
import "./priceEntities.css"

const PriceEntities = () => {
    const token = useSelector((state) => state.token);
    const [downloadBtnLoading, setDownloadBtnLoading] = useState(false)
    const [previewBtnLoading, setPreviewBtnLoading] = useState(false)
    const [allExpanded, setAllExpanded] = useState(false);
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null)
    const [previewTable, setPreviewTable] = useState(null)
    const [updateBtn, setUpdateBtn] = useState(true)
    const [entitiesTable, setEntitiesTable] = useState(null)
    const [entitiesTableCount, setEntitiesTableCount] = useState(null)
    const [filterArr, setFilterArr] = useState([])
    const [limit, setLimit] = useState(10)
    const [orderBy, setOrderBy] = useState({ id: "desc" })
    const filterSearchRef1 = useRef(null);
    const filterSearchRef2 = useRef(null);
    const filterSearchRef3 = useRef(null);
    const filterSearchRef4 = useRef(null);
    const filterSearchRef5 = useRef(null);
    const filterSearchRef6 = useRef(null);
    const filterSearchRef7 = useRef(null);
    const [uploadFileModal, setUploadFileModal] = useState(false)
    const [reqObj, setReqObj] = useState({
        productId: "",
        country: "",
        price: "",
        priceGroupId: "",
        shipmentTypeId: "",
        effectiveDate: new Date()
    })
    const [shipmentTypeList, setShipmentTypeList] = useState([]);
    const [countryList, setCountryList] = useState([])
    const [priceGroupList, setPriceGroupList] = useState([])
    const [updateReq, setUpdateReq] = useState(false)
    const [reqModal, setReqModal] = useState(false)
    const [searchName, setSearchName] = useState("")
    const [prodNameToSearch, setProdNameToSearch] = useState("")
    const deboucerValue = useDebounce(prodNameToSearch, 250)
    const [productRes, setProductRes] = useState([])
    const [currencyData,setCurrencyData] = useState([])
    const [selectedCurrency,setSelectedCurrency] = useState({})
    const [currencyValue,setCurrencyValue] = useState("")
    let items = [];
    let paginationNum = [10, 25, 50];
    paginationNum.forEach(number => {
        items.push(
            <Pagination.Item
                key={number}
                active={number === limit}
                onClick={() => setLimit(number)}
            >
                {number}
            </Pagination.Item>
        );
    });

    const downloadTableData = () => {
        if (!entitiesTable) {
            errorToast("Table is empty");
            return;
        }
        let headers = [
            "productId",
            "productName",
            "countryId",
            "price",
            "priceGroupId",
            "shipmentTypeId",
            "effectiveDate",
        ];

        const data = [
            headers,
            ...entitiesTable.map((product) => {
            return headers.map((header) => product[header]);
            }),
        ];

        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
        const excelData = XLSX.write(workbook, { type: "binary" });

        const blob = new Blob([stringToArrayBuffer(excelData)], {
            type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "entities.xlsx";

        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
    };

    const stringToArrayBuffer = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    };

    const previewFileHandler = async () => {
        try {
            setPreviewBtnLoading(true)
            const formData = new FormData();
            formData.append("xlsx", file)
            const res = await previewFile(token, formData)
            let falseArr = []
            let trueArr = []
            const data = res.data.data
            data.forEach((item) => {
                if (!item.isValid) {
                    falseArr.push(item)
                } else {
                    trueArr.push(item)
                }
            })
            const newArr = falseArr.concat(trueArr)
            setPreviewTable(newArr)
            const hasInvalidItems = newArr?.some((item) => item.isValid === false);
            setUpdateBtn(!hasInvalidItems)
            handleModalClose()
        } catch (err) {
            return errorToast(err.response?.data?.message || "Something went wrong");
        } finally {
            setPreviewBtnLoading(false)
        }
    }

    const updateBtnHandler = async () => {
        try {
            const res = await updatePriceEntities(token, previewTable)
            successToast(res.data.message)
            setUpdateBtn(false)
            setPreviewTable(null)
            setFile(null)
            getEntitiesData()
        } catch (err) {
            return errorToast(err.response?.data?.message || "Something went wrong");
        }
    }

    const getEntitiesData = async () => {
        try {
            const res = await entitiesData(token, filterArr, limit, null, orderBy)
            setEntitiesTable(res.data.data)
            setEntitiesTableCount(res.data.count)
        } catch (err) {
            return errorToast(err.response?.data?.message || "Something went wrong");
        }
    }

    const filterSearch = async (obj, isSelected) => {
        let current = filterArr;
        current = current.filter((item) => item.column !== obj.column);
        if (isSelected) {
            setFilterArr(current)
        } else {
            current.push(obj);
            setFilterArr(current);
        }
    };

    const getShipmentList = async () => {
        try {
            const res = await getShipment(token);
            let codeArr = [];
            res.data.forEach((item) => {
                codeArr.push(item.code);
            });
            setShipmentTypeList(codeArr);
        } catch (err) {
            errorToast("something went wrong");
        }
    };

    const getAllCountryList = async () => {
        try {
            const res = await getCountryList(token);
            let countryArr = [];
            res.data.countries.map((item) => countryArr.push(item.name));
            setCountryList(countryArr);
        } catch (err) {
            errorToast("something went wrong");
        }
    };

    const priceGroupData = async () => {
        try {
            const res = await getPriceGroup(token)
            let arr = [];
            res?.data?.data?.map((item) => arr.push(item.name));
            setPriceGroupList(arr)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getShipmentList()
        getAllCountryList()
        priceGroupData()
        currencyList()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getEntitiesData();
        // eslint-disable-next-line
    }, [filterArr, orderBy, limit])

    const handleLoadMore = async () => {
        try {
            const res = await entitiesData(token, filterArr, limit, entitiesTable.length, orderBy)
            setEntitiesTableCount(res.data.count)
            let table = [...entitiesTable]
            table.push(...res.data.data)
            setEntitiesTable(table)
        } catch (err) {
            return errorToast(err.response?.data?.message || "Something went wrong");
        }
    }

    const handleModalClose = () => {
        setFile(null)
        setUploadFileModal(false)
    }

    const goBackBtnHandle = () => {
        setPreviewTable(null)
        setUpdateBtn(false)
    }

    const handleDownloadData = async () => {
        try {
            setDownloadBtnLoading(true);
            const res = await downloadEntities(token);
            const data = res.data.data;
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'price-entities.xlsx');
            document.body.appendChild(link);
            link.click();
            // Delay removal of the link to ensure download is complete
            setTimeout(() => {
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }, 100);
        } catch (error) {
            console.log(error);
        } finally {
            setDownloadBtnLoading(false);
        }
    }

    const updateHandler = (item) => {
        setReqObj({
            productId: item.productId,
            country: item.countryId,
            price: item.price,
            priceGroupId: item.priceGroupId,
            shipmentTypeId: item.shipmentTypeId,
            effectiveDate: item.effectiveDate
        })
        setReqModal(true)
        setUpdateReq(true)

    }
    const deleteHandler = async (entityId) => {
        try {
            if (window.confirm("Please confirm you want to delete this entity") === true) {
                const res = await deletePriceEntity(token, entityId);
                const newList = entitiesTable.filter(item => item.id !== entityId)
                setEntitiesTable(newList);
                setEntitiesTableCount(entitiesTableCount - 1);
                successToast(res.data.message);
            }

        } catch (err) {
            errorToast(err.response?.data?.message || "Something went wrong")
        }
    }

    const handleReqModal = () => {
        setReqModal(false)
        setReqObj({
            productId: "",
            country: "",
            price: "",
            priceGroupId: "",
            shipmentTypeId: "",
            effectiveDate: new Date()
        })
        setUpdateReq(false)
        setProductRes([])
        setSearchName("")
    }

    const reqBtnHandler = async () => {
        try {
            if (!reqObj.productId || !reqObj.country || !reqObj.price || !reqObj.priceGroupId || !reqObj.shipmentTypeId || !reqObj.effectiveDate) {
                return errorToast("All Fields are required")
            }
            const res = await upsertPriceEntities(token, reqObj);
            if (res.status === 200) {
                successToast(res.data.message)
                getEntitiesData()
                handleReqModal()
            }
        } catch (err) {
            return errorToast(err.response?.data?.message || "Something went wrong");
        }
    };

    const resetBtnHandler = () => {
        setFilterArr([])
        setOrderBy({ id: "desc" })
        setLimit(10)
        filterSearchRef1.current && filterSearchRef1.current.resetFilter();
        filterSearchRef2.current && filterSearchRef2.current.resetFilter();
        filterSearchRef3.current && filterSearchRef3.current.resetFilter();
        filterSearchRef4.current && filterSearchRef4.current.resetFilter();
        filterSearchRef5.current && filterSearchRef5.current.resetFilter();
        filterSearchRef6.current && filterSearchRef6.current.resetFilter();
        filterSearchRef7.current && filterSearchRef7.current.resetFilter();
    }

    const fetchProduct = async (searchName) => {
        try {
            const res = await searchProduct(token, [{ column: "name", type: "contains", value: searchName }], {limit:5}, { name: "asc" })
            setProductRes(res.data.products)
        } catch (error) {
            console.log(error)
        }
    }

    const handleProductName =(item)=>{
        setSearchName(item.name)
        setReqObj({...reqObj, productId:item.id})
        setProductRes([])
    }
    
    useEffect(() => {
        if (deboucerValue) {
            fetchProduct(deboucerValue);
        }
        // eslint-disable-next-line
    }, [deboucerValue])

    const currencyList = async () => {
        try {
          const res = await getCurrencyCode(token);
          setCurrencyData(res.data);
          setSelectedCurrency(res.data[0]);
        } catch {
          errorToast("Something Went Wrong");
        }
      };

    const handleCurrencyConversion =(value)=>{
        setCurrencyValue(value);
        const conversionRate = Number(value) * Number(selectedCurrency.rate)
        setReqObj({...reqObj,price:conversionRate})
    }

    return (
        <Container>
            <h4 className='mb-4'>Price Entities</h4>
            {!previewTable && (
                <div className="mb-3">
                    <div className='mb-3 d-flex gap-2'>
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                setUploadFileModal(true)
                            }}
                        >
                            Update Prices
                        </Button>
                        <Button
                            variant="outline-secondary"
                            onClick={handleDownloadData}
                        >
                            <i className="fa-solid fa-download"></i>
                            {downloadBtnLoading ? (
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ) : (
                                " Price list"
                            )}
                        </Button>
                        <Button variant="outline-secondary" onClick={downloadTableData}>
                            <i className="fa-solid fa-download"></i> View
                        </Button>
                        <Button
                            variant="outline-secondary"
                            onClick={() => setReqModal(true)}
                        >
                            <i className="fa-solid fa-plus"></i>
                            {downloadBtnLoading ? (
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ) : (
                                " Add Price Entity"
                            )}
                        </Button>
                        <Button
                            variant="outline-danger"
                            onClick={resetBtnHandler}
                        >
                            <i className="fa-solid fa-rotate-left"></i>
                        </Button>
                    </div>
                    {
                        !!entitiesTable ? (
                            <>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <FilterSearch
                                                name="Product ID"
                                                table="priceEntities"
                                                column="productId"
                                                type="contains"
                                                search={filterSearch}
                                                ref={filterSearchRef1}
                                                orderBy={orderBy}
                                                setOrderBy={setOrderBy}
                                            />
                                            <FilterSearch
                                                name="Product Name"
                                                table="priceEntities"
                                                column="productName"
                                                type="contains"
                                                search={filterSearch}
                                                ref={filterSearchRef6}
                                                toExpand={() => setAllExpanded(!allExpanded)}
                                                orderBy={orderBy}
                                                setOrderBy={setOrderBy}
                                            />
                                            <FilterSearch
                                                name="Country"
                                                table="priceEntities"
                                                column="countryId"
                                                type="equal"
                                                search={filterSearch}
                                                ref={filterSearchRef2}
                                                orderBy={orderBy}
                                                setOrderBy={setOrderBy}
                                            />
                                            <FilterSearch
                                                name="Price(INR)"
                                                table="priceEntities"
                                                column="price"
                                                // type="equal"
                                                search={filterSearch}
                                                ref={filterSearchRef3}
                                                orderBy={orderBy}
                                                setOrderBy={setOrderBy}
                                            />
                                            <FilterSearch
                                                name="Price Group"
                                                table="priceEntities"
                                                column="priceGroupId"
                                                type="equal"
                                                search={filterSearch}
                                                ref={filterSearchRef4}
                                                orderBy={orderBy}
                                                setOrderBy={setOrderBy}
                                            />
                                            <FilterSearch
                                                name="Shipment Type"
                                                table="priceEntities"
                                                column="shipmentTypeId"
                                                type="equal"
                                                search={filterSearch}
                                                ref={filterSearchRef5}
                                                orderBy={orderBy}
                                                setOrderBy={setOrderBy}
                                            />
                                            <FilterSearch
                                                name="Effective Date"
                                                table="priceEntities"
                                                column="effectiveDate"
                                                type="date"
                                                search={filterSearch}
                                                ref={filterSearchRef7}
                                                orderBy={orderBy}
                                                setOrderBy={setOrderBy}
                                            />
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            entitiesTable?.map((item, i) => (
                                                <tr key={i}>
                                                    <td>{item.productId}</td>
                                                    <td>
                                                        <TruncateCell
                                                            text={item.productName}
                                                            maxLength={allExpanded ? item.name?.length : 15}
                                                        />
                                                    </td>
                                                    <td>{item.countryId}</td>
                                                    <td>{item.price}</td>
                                                    <td>{item.priceGroupId}</td>
                                                    <td>{item.shipmentTypeId}</td>
                                                    <td>{moment(item.effectiveDate).format("DD/MM/YYYY")}</td>
                                                    <td>
                                                        <Button
                                                            onClick={() => updateHandler(item)}
                                                        >
                                                            Update
                                                        </Button>
                                                        <Button
                                                            variant='danger'
                                                            className='ms-1'
                                                            onClick={() => deleteHandler(item.id)}
                                                        >
                                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='mb-2'>
                                        {entitiesTable.length}/{entitiesTableCount}
                                    </div>
                                    <div className="mt-2">
                                        <Pagination>{items}</Pagination>
                                    </div>
                                </div>
                                {
                                    entitiesTable.length !== entitiesTableCount && (
                                        <div className='text-center'>
                                            <Button onClick={handleLoadMore}>
                                                Load More
                                            </Button>
                                        </div>
                                    )}
                            </>
                        ) : ("No data Available")
                    }
                </div>
            )
            }
            {
                !!previewTable && (
                    <div>
                        <Button className="mb-2 me-2" onClick={goBackBtnHandle}>
                            Go Back
                        </Button>
                        {updateBtn && (
                            <Button className="mb-2" onClick={updateBtnHandler}>
                                Update Prices
                            </Button>
                        )}
                    </div>
                )
            }
            {
                !!previewTable && (
                    <Table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product ID</th>
                                {/* <td>Product Name</td> */}
                                <th>Price(INR)</th>
                                <th>Country</th>
                                <th>Price Group</th>
                                <th>Remark</th>
                                <th>Shipment Type</th>
                                <th>Effective Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {previewTable?.map((item, index) => (
                                <tr key={index}>
                                    <td className={`${!item.isValid ? "text-danger" : ""}`}>{item.rowNum || "-"}</td>
                                    <td className={`${!item.isValid ? "text-danger" : ""}`}>{item.productId}</td>
                                    <td className={`${!item.isValid ? "text-danger" : ""}`}>{item.price}</td>
                                    <td className={`${!item.isValid ? "text-danger" : ""}`}>{item.countryId}</td>
                                    <td className={`${!item.isValid ? "text-danger" : ""}`}>{item.priceGroupId}</td>
                                    <td className={`${!item.isValid ? "text-danger" : ""}`}>{item.remark || '-'}</td>
                                    <td className={`${!item.isValid ? "text-danger" : ""}`}>{item.shipmentTypeId}</td>
                                    <td className={`${!item.isValid ? "text-danger" : ""}`}>{item.effectiveDate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )
            }

            <Modal show={uploadFileModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Price Entities</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formFile" className="file-inp">
                        <Form.Label>Upload File</Form.Label>
                        <Form.Control
                            ref={fileInputRef}
                            type="file"
                            accept=".xlsx, .tsv, .xls, .txt"
                            onChange={(e) => {
                                setFile(e.target.files[0]);
                            }}
                        />
                    </Form.Group>
                    <small className="text-danger">
                        Upload only xls, xlsx <br />
                        <a
                            className="text-primary download_Link"
                            href="/price-entities (8).xlsx"
                            download="Price entitie sample file"
                        >
                            Download Sample File
                        </a>
                    </small>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={previewFileHandler} disabled={previewBtnLoading}>
                        Preview File
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Add single price entity modal */}

            <Modal show={reqModal} onHide={handleReqModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{updateReq ? "Update" : "Add"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="custom_list mb-3">
                            <Form.Label>Product Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder=" Product Name"
                                value={searchName}
                                onKeyUp={(e) => {setProdNameToSearch(e.target.value)}}
                                onChange={(e) => setSearchName(e.target.value)}
                            />
                        {
                            productRes.length > 0 && searchName && (
                                <ListGroup className='search-results'>
                                    {productRes.map((item, index) => (
                                        <ListGroup.Item
                                            style={{ cursor: "pointer" }}
                                            key={index}
                                            onClick={()=>handleProductName(item)}
                                        >
                                            {item.name}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            )
                        }
                        </Form.Group>
                        <Form.Group className="mb-3 mt-3">
                            <Form.Label>Product ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder=" Product ID"
                                value={reqObj.productId}
                                onChange={(e) => setReqObj({ ...reqObj, productId: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Country</Form.Label>
                            <Form.Select
                                aria-label="Country List"
                                value={reqObj.country}
                                onChange={(e) =>
                                    setReqObj({ ...reqObj, country: e.target.value })
                                }
                            >
                                <option value="">Select Country</option>
                                {countryList.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Currency to convert to INR</Form.Label>
                            <Form.Select
                                aria-label="Currency Rate"
                                onChange={(e) =>
                                    setSelectedCurrency(currencyData[e.target.value])
                                }
                            >
                                {currencyData.map((item, idx) => (
                                    <option key={item.name} value={idx}>
                                        {item.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Price ({selectedCurrency.name || "-"})</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder={` Price in ${selectedCurrency.name || "-"}`}
                                value={currencyValue}
                                onChange={(e)=>handleCurrencyConversion(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Price(INR)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder=" Price"
                                value={reqObj.price}
                                onChange={(e) => setReqObj({ ...reqObj, price: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Price Group</Form.Label>
                            <Form.Select
                                aria-label="Shipment Code List"
                                value={reqObj.priceGroupId}
                                onChange={(e) =>
                                    setReqObj({ ...reqObj, priceGroupId: e.target.value })
                                }
                            >
                                <option value="">Select Price Group</option>
                                {priceGroupList.map((key) => (
                                    <option key={key} value={key}>
                                        {key}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Shipment Type</Form.Label>
                            <Form.Select
                                aria-label="Shipment Code List"
                                value={reqObj.shipmentTypeId}
                                onChange={(e) =>
                                    setReqObj({ ...reqObj, shipmentTypeId: e.target.value })
                                }
                            >
                                <option value="">Select Shipment Type</option>
                                {shipmentTypeList.map((key) => (
                                    <option key={key} value={key}>
                                        {key}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Select Date</Form.Label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className="date-picker-style w-100"
                                selected={new Date(reqObj.effectiveDate)}
                                onChange={(date) => {
                                    if (date) {
                                        date.setHours(0);
                                        date.setMinutes(0);
                                        date.setSeconds(0);
                                    }
                                    setReqObj({ ...reqObj, effectiveDate: date });
                                }}
                                disabledKeyboardNavigation
                                required
                                placeholderText=" Date"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleReqModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={reqBtnHandler}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default PriceEntities;
