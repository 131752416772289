import React, { useEffect, useState } from "react";
import "./userList.css";
import {
  InputGroup,
  Table,
  Container,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  adminCreateUser,
  changePasswordByEmail,
  getUser,
  logoutAllDevice,
  updateUser,
} from "../../Services/userService";
import { errorToast, successToast } from "../../Services/toastService";
import { getCountries, getCurrencyCode, getPriceGroup } from "../../Services/adminService";
import Select from "react-select";
import TruncateCell from "../../SharedComponents/truncateCell/truncateCell";
import Pagination from "react-bootstrap/Pagination";
import TooltipComponent from "../../SharedComponents/tooltip/tooltip";

const UserList = () => {
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    userId: "",
    password: "",
    role: "",
    active: "false",
    currencyCode: "INR",
    creditLimit: "",
    accountantEmail: "",
    countryArr: [],
    priceGroup: "",
    defaultPriceGroup: "",
    logoutFromDevices: false
  });
  const [searchText, setSearchText] = useState("");
  const token = useSelector((state) => state.token);
  const [tableData, setTableData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [passwordModal, setPasswordModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [countryOption, setCountryOption] = useState([])
  const [limit, setLimit] = useState(10);
  const [allExpanded, setAllExpanded] = useState(false)
  const [priceGroupList, setPriceGroupList] = useState([])

  let items = [];
  let paginationNum = [10, 25, 50];
  paginationNum.forEach(number => {
    items.push(
      <Pagination.Item
        key={number}
        active={number === limit}
        onClick={() => setLimit(number)}
      >
        {number}
      </Pagination.Item>
    );
  });

  const switchPasswordDialog = () => {
    if (passwordModal) {
      setSelectedUser(null);
    }
    setPasswordModal(!passwordModal);
  };

  const handleShow = () => {
    if (show) {
      resetForm();
    }
    setShow(!show);
  };

  const updateUserDialog = (user) => {
    setSelectedUser(user);
    setUser({
      name: user.name,
      email: user.email,
      userId: user.id,
      password: "",
      role: user.role,
      active: user.active ? "true" : "false",
      accountantEmail: user.Wallet?.accountantEmail,
      countryArr: user.Wallet?.countries
    });
    setShow(true);
  };

  const submitUser = async (e) => {
    e.preventDefault();
    try {
      const response = await updateUser(
        selectedUser.id,
        user.email,
        user.name,
        user.role,
        user.active === "true",
        token,
        user.accountantEmail,
        user.countryArr
      );
      if (response.status === 200) {
        successToast("User Updated Successfully");
        const updateUser = tableData.map((user) => {
          if (user.id === response.data.id) {
            return { ...user, ...response.data, ...response.data.Wallet };
          }
          return user;
        });
        setTableData(updateUser);
        handleShow();
      }
    } catch (err) {
      if (err.response?.status === 401) {
        errorToast("Invalid Credentials");
      } else if (err.response?.status === 400) {
        return errorToast(err.response?.data.message);
      } else {
        errorToast("Something went wrong");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (user.password.length < 8) {
        let temp = { ...errors };
        temp.password = "Password must be 8 length or more";
        setErrors(temp);
        return;
      } else if (user.role === "") {
        errorToast("Please Select Role");
        return;
      } else {
        let temp = { ...errors };
        if (temp.password) {
          delete temp.password;
        }
        setErrors(temp);
      }
      if (user.role !== "client") {
        user.accountantEmail = ""
      }
      const response = await adminCreateUser(
        user.userId,
        user.name,
        user.email,
        user.password,
        user.role,
        user.currencyCode,
        user.creditLimit,
        token,
        user.accountantEmail,
        user.countryArr,
        user.priceGroup,
        user.defaultPriceGroup
      );
      if (response.status === 201) {
        successToast("User Created Successfully");
        handleShow();
        search();
      }
    } catch (err) {
      if (err.response?.status === 401) {
        errorToast(err.response.data.message);
      } else if (err.response?.status === 400) {
        errorToast(err.response.data.message);
      } else {
        return errorToast("Something went wrong");
      }
    }
  };

  const resetForm = () => {
    setUser({
      name: "",
      email: "",
      userId: "",
      password: "",
      role: "",
      active: false,
      currencyCode: "INR",
      creditLimit: "",
      accountantEmail: "",
      countryArr: null,
      defaultPriceGroup: "",
      logoutFromDevices: false
    });
    setSelectedUser(null);
  };

  const search = async () => {
    try {
      const response = await getUser(searchText, token, undefined, limit);
      setTableData(response.data.user);
      setTotalCount(response.data.userCount);
      if (response.data.userCount === 0) {
        errorToast("No User Found");
      }
    } catch (err) {
      console.log(err);
      errorToast("No User Found");
    }
  };

  const changePass = (user) => {
    setSelectedUser(user);
    setUser({
      ...user,
      accountantEmail: user.Wallet?.accountantEmail,
      countryArr: user.Wallet?.countries,
      password: "",
      logoutFromDevices:false
    });
    switchPasswordDialog();
  };

  const searchOnEnter = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  const newPassword = async () => {
    try {
      if (user.password.length < 8) {
        return errorToast("Password Should be greater than 8");
      }
      const response = await changePasswordByEmail(
        user.email,
        token,
        user.password,
        user.logoutFromDevices
      );
      if (response.status === 200) {
        successToast("Password Changed Successfully");
        switchPasswordDialog();
        setSelectedUser(null);
      }
    } catch (err) {
      console.log(err);
      errorToast("Something Went Wrong");
    }
  };

  const handleLoadMore = async () => {
    try {
      const response = await getUser(searchText, token, tableData.length, limit);
      let table = [...tableData];
      table.push(...response.data.user);
      setTableData(table);
    } catch (err) {
      console.log(err);
    }
  };

  let currencyList = async () => {
    const res = await getCurrencyCode(token);
    setCountryList(res.data);
  };

  const handleChange = (selectedOptions) => {
    setUser({
      ...user,
      countryArr: selectedOptions.map(item => item.label)
    })
  };

  const getCountryOptions = async () => {
    try {
      const res = await getCountries(token)
      const formattedOptions = res.data.countries.map((country) => ({
        value: country.id,
        label: country.name,
      }));
      setCountryOption(formattedOptions);
    } catch (err) {
      console.log(err)
    }
  }

  const getPriceGroups = async () => {
    try {
      const res = await getPriceGroup(token)
      setPriceGroupList(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    currencyList();
    getCountryOptions()
    getPriceGroups()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [limit])

  const handleLogutUser = async (userId) => {
    try {
      const res = await logoutAllDevice(token, userId);
      if (res.status === 200) {
        successToast("Success")
      }
    } catch (error) {
      errorToast("Something went wrong")
    }
  }

  return (
    <Container>
      <h4 className="my-4">User Lists</h4>
      <div className="App">
        <div className="my-3">
          <div className="user-search">
            <InputGroup>
              <Form.Control
                placeholder="Name or Email"
                aria-describedby="basic-addon2"
                onKeyDown={searchOnEnter}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <Button
                variant="outline-secondary"
                id="button-addon2"
                onClick={() => search()}
              >
                Search
              </Button>
            </InputGroup>
          </div>
          <Button
            variant="outline-secondary"
            id="button-addon1"
            onClick={() => handleShow(null)}
          >
            Add User
          </Button>
        </div>
        {tableData && !!tableData.length && (
          <Table bordered responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Active</th>
                <th>Account Email</th>
                <th>
                  Countries
                  <span
                    onClick={() => {
                      setAllExpanded(!allExpanded)
                    }}
                    className="readButton"
                  >
                    <u>{allExpanded ? " Read Less" : " Read More"}</u>
                  </span>
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.role}</td>
                  <td>{item.active === false ? "False" : "True"}</td>
                  <td>{item.Wallet?.accountantEmail ? item.Wallet?.accountantEmail : "-"}</td>
                  <td>{item.Wallet?.countries ?
                    <TruncateCell
                      text={item.Wallet?.countries.join(", ")}
                      maxLength={allExpanded ? item.Wallet?.countries : 7}
                    /> :
                    "-"}
                  </td>
                  <td key={item.id} className="w-25">
                    <Button
                      className="m-1"
                      size="sm"
                      variant="primary"
                      onClick={() => updateUserDialog(item)}
                    >
                      Update
                    </Button>
                    <Button
                      size="sm"
                      variant="dark"
                      onClick={() => changePass(item)}
                      className="m-1"
                    >
                      Change Pass
                    </Button>
                    <TooltipComponent
                      children={
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={() => handleLogutUser(item.id)}
                          className="m-1"
                        >
                          <i className="fa-solid fa-right-from-bracket"></i>
                        </Button>
                      }
                      content={"Logout User from all devices"}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {tableData && !tableData.length && (
          <div className="text-center mt-5">
            <h5>No User Found</h5>
          </div>
        )}
        {tableData && !!tableData.length && (
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div>
              {tableData.length} / {totalCount}
            </div>
            <div className="mt-2">
              <Pagination>{items}</Pagination>
            </div>
          </div>
        )}
      </div>
      <div className="text-center my-2">
        {tableData && totalCount > tableData.length ? (
          <Button variant="dark" onClick={handleLoadMore}>
            Load More
          </Button>
        ) : null}
      </div>
      <Modal show={show} onHide={handleShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedUser ? "Update User" : "Create User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={selectedUser ? submitUser : handleSubmit}
            autoComplete="off"
          >
            {selectedUser === null && (
              <Form.Group className="mb-3">
                <Form.Label>Enter ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User ID"
                  required
                  value={user.userId}
                  onChange={(e) => setUser({ ...user, userId: e.target.value })}
                />
              </Form.Group>
            )}
            <div className="d-flex gap-3 justify-content-between">
              <Form.Group className="mb-3 flex-grow-1" controlId="formGroupName">
                <Form.Label>Enter Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  required
                  value={user.name}
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-3 flex-grow-1" controlId="formGroupEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  placeholder="Enter email"
                  required
                  type="text"
                  pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  onBlur={(e) =>
                    !user.accountantEmail.trim() && (
                      setUser({ ...user, accountantEmail: e.target.value })
                    )}
                />
              </Form.Group>
            </div>
            {/* {selectedUser ? null : (
              <Form.Group className="mb-3" controlId="formGroupPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  value={user.password}
                  required
                  onChange={(e) =>
                    setUser({ ...user, password: e.target.value })
                  }
                />
                {!!errors.password && (
                  <p className="text-danger">{errors.password}</p>
                )}
              </Form.Group>
            )} */}
            {!selectedUser && (
              <div className="d-flex gap-3 justify-content-between">
                <Form.Group className="mb-3 flex-grow-1" controlId="formGroupPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter Password"
                    value={user.password}
                    required
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                  />
                  {!!errors.password && (
                    <p className="text-danger">{errors.password}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 flex-grow-1" controlId="formGroupRole" required>
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    value={user.role}
                    onChange={(e) => setUser({ ...user, role: e.target.value })}
                  >
                    <option value="">Select Role</option>
                    <option value="client">Client</option>
                    <option value="admin">Admin</option>
                    <option value="dispatch">Dispatch</option>
                    <option value="dispatch_manager">Dispatch Manager</option>
                    <option value="accountant">Accountant</option>
                    <option value="tracker">Tracker</option>
                  </Form.Select>
                </Form.Group>
              </div>
            )}
            {!!selectedUser && (
              <>
                <Form.Group className="mb-3" controlId="formGroupActive">
                  <Form.Label>Active</Form.Label>
                  <Form.Select
                    value={user.active}
                    onChange={(e) =>
                      setUser({ ...user, active: e.target.value })
                    }
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </Form.Select>
                </Form.Group>
              </>
            )}

            {
              user.role === "client" && (
                <Form.Group className="mb-3" controlId="formGroupCountry" required>
                  <Form.Label>Country</Form.Label>
                  <Select
                    isMulti
                    options={countryOption}
                    onChange={handleChange}
                    value={countryOption.filter((option) =>
                      user.countryArr?.includes(option.label)
                    )}
                  />
                </Form.Group>
              )
            }

            {user.role === "client" && !selectedUser && (
              <>
                <div className="d-flex gap-3 justify-content-between">
                  <Form.Group className="mb-3 flex-fill" controlId="formGroupCurrency" required >
                    <Form.Label>Currency</Form.Label>
                    <Form.Select
                      value={user.currencyCode}
                      onChange={(e) =>
                        setUser({ ...user, currencyCode: e.target.value })
                      }
                    >
                      {countryList.map((item, i) => (
                        <option key={i} value={item.code}>
                          {item.code}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3 flex-fill" controlId="formGroupCreditLimit" required>
                    <Form.Label>Credit Limit</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Credit Limit"
                      required
                      value={user.creditLimit}
                      onChange={(e) =>
                        setUser({ ...user, creditLimit: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="d-flex gap-3 justify-content-between">
                  <Form.Group className="mb-2 flex-fill">
                    <Form.Label>Price Group</Form.Label>
                    <Form.Select
                      value={user.priceGroup}
                      onChange={(e) =>
                        setUser({ ...user, priceGroup: e.target.value })
                      }
                    >
                      <option value="">Select Price Group</option>
                      {
                        priceGroupList.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))
                      }

                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2 flex-fill">
                    <Form.Label>Default Price Group</Form.Label>
                    <Form.Select
                      value={user.defaultPriceGroup}
                      onChange={(e) =>
                        setUser({ ...user, defaultPriceGroup: e.target.value })
                      }
                    >
                      <option value="">Select Price Group</option>
                      {
                        priceGroupList.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))
                      }

                    </Form.Select>
                  </Form.Group>
                </div>
              </>
            )}
            {user.role === "client" && (
              <Form.Group className="mb-3" controlId="formGroupAccountEmail">
                <Form.Label>Account email address</Form.Label>
                <Form.Control
                  placeholder="Enter Account email"
                  required
                  type="text"
                  pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                  value={user.accountantEmail}
                  onChange={(e) =>
                    setUser({ ...user, accountantEmail: e.target.value })
                  }
                />
              </Form.Group>
            )}

            <Modal.Footer>
              <Button variant="secondary" onClick={handleShow}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                {selectedUser ? "Update" : "Submit"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {/* CHANGE PASSWORD MODEL  */}
      <Modal show={passwordModal} onHide={switchPasswordDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="enter new password"
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
              />
            </Form.Group>
            <Form.Check
              type='checkbox'
              label="Logout from all devices"
              checked={user.logoutFromDevices}
              onChange={(e) =>
                setUser({ ...user, logoutFromDevices: e.target.checked })
              }
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={switchPasswordDialog}>
            Close
          </Button>
          <Button variant="primary" onClick={newPassword}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
export default UserList;
