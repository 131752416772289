import axios from "axios";

export const getTrackingDetails = (token, id) => {
    let url = process.env.REACT_APP_API_URL + "/api/v1/tracking/get-status";
    let params = { id };
    return axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
        params,
    })
}

export const createCountryTracker = (token, data) => {
    let url = process.env.REACT_APP_API_URL + "/api/v1/tracking/createCountryTracker";
    return axios.post(url, data, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const updateCountryTracker = (token, data) => {
    let url = process.env.REACT_APP_API_URL + "/api/v1/tracking/updateCountryTracker";
    return axios.patch(url, data, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const getCountryTracker = (token, name, offSet) => {
    let url = process.env.REACT_APP_API_URL + "/api/v1/tracking/getCountryTracker";
    let params = {};
    if (offSet) {
        params.offSet = offSet;
    }
    if (name) {
        params.name = name
    }
    return axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
        params
    })
}

export const trackingFilter = (token, filterArr, orderBy, limit, offSet) => {
    let url = process.env.REACT_APP_API_URL + "/api/v1/tracking/filter";
    let data = {filterArr};
    if(orderBy){
        data.orderBy = orderBy
    }
    if (offSet) {
        data.offSet = offSet;
    }
    if(limit){
        data.limit = limit;
    }
    return axios.post(url,data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const updateTrackingData = (token, id) => {
    let url = process.env.REACT_APP_API_URL + "/api/v1/tracking/update";
    return axios.post(url,
        { "trackingId": id }
        , {
            headers: { Authorization: `Bearer ${token}` }
        })
}

export const updateTrackingDataManually = (token, data) => {
    let url = process.env.REACT_APP_API_URL + "/api/v1/tracking/manual-update";
    return axios.post(url,
        data,
        {
            headers: { Authorization: `Bearer ${token}` }
        })
}

export const addTrackingComment = (token,edn,remark) => {
    let url = process.env.REACT_APP_API_URL + "/api/v1/tracking/add-remark";
    return axios.post(url,
        {
            edn:edn,
            remark:remark
        },
        {
            headers: { Authorization: `Bearer ${token}` }
        })
}

export const trackingDownload = (token, filterArr, orderBy) => {
    let url = process.env.REACT_APP_API_URL + "/api/v1/tracking/download-tracking-data";
    let data = {filterArr};
    if(orderBy){
        data.orderBy = orderBy
    }
    return axios.post(url,data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
