import React, { useState } from "react";
import "./truncateCell.css"

export const TruncateCell = ({ text, maxLength }) => {
    const [isShowMore, setIsShowMore] = useState(false);
    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };
    return (
        <div>
            {text?.length > maxLength ? (
                <div>
                    {isShowMore ? (
                        <div>
                            {text}
                            <span onClick={toggleReadMoreLess} className="readButton">{"  "}<u>Read Less</u></span>
                        </div>
                    ) : (
                        <div>
                            {text.slice(0, maxLength)}
                            <span onClick={toggleReadMoreLess} className="readButton">{"  "}<u>Read More</u></span>
                        </div>
                    )}
                </div>
            ) : (
                <div>{text}</div>
            )}
        </div>
    );
};

export default TruncateCell;