import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TooltipComponent = ({ children, position, content }) => {
    return (
        <OverlayTrigger
            placement={position}
            overlay={
                <Tooltip id={"button-tooltip"}>
                    {content}
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    )
}

export default TooltipComponent;