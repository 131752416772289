import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./navigation.css";
import { useSelector, useDispatch } from "react-redux";
import { setMode, setLogout } from "../../State/store.js";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { changePassword, logout } from "../../Services/userService";
import { errorToast, successToast } from "../../Services/toastService";
import { ToastContainer } from "react-toastify";
import { useLocale } from "../../lib/customHooks/useLocale.jsx"

function Navigation() {
  const { i18n } = useLocale();
  const { t } = useLocale("common")
  const user = useSelector((state) => state.user);
  const theme = useSelector((state) => state.mode);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(theme === "dark" ? true : false);

  const [show, setShow] = useState(false);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [currentPassword, setCurrentPassword] = useState();
  const [logoutOtherDevices, setLogoutOtherDevices] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const savePassword = async () => {
    try {
      if (confirmPassword === password && password === currentPassword) {
        return errorToast("old password and new password is same");
      }
      if (confirmPassword !== password) {
        return errorToast("Password and Confirm password are not same");
      }
      if (password.length < 8) {
        return errorToast("Password Should be greater than 8 digit");
      }
      const response = await changePassword(currentPassword, password, token, logoutOtherDevices);
      if (response.status === 200) {
        successToast("Password Changed Successfully");
        setCurrentPassword();
        // dispatch(setLogout());
      }
      handleClose();
    } catch (err) {
      console.log(err.response);
      errorToast(err.response?.data.message || "Something Went Wrong");
    }
  };

  const logoutUser = async () => {
    try {
      const res = await logout(token)
      if (res.status === 200) {
        successToast(res.data.message)
      }
      dispatch(setLogout());
      navigate("/");
    } catch (err) {
      dispatch(setLogout());
      errorToast(err.response?.data?.message || "Something went wrong")
    }
  };

  const languageOptions = {
    "en-US": "English",
    "jp-JP": "Japan"
  }

  const changeLanguage = (key) => {
    i18n.changeLanguage(key)
  }

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary"
      bg="dark"
      data-bs-theme="dark"
    >
      <ToastContainer />
      <Container fluid>
        <Navbar.Brand>Cyno</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <div className="me-auto"></div>
          <Nav>
            {user?.role === "admin" && (
              <>
                <Nav.Link as={NavLink} to="/" eventKey={"1"}>
                  Users
                </Nav.Link>
                <Nav.Link as={NavLink} to="/placeOrder" eventKey={"2"}>
                  Place Order
                </Nav.Link>
                <Nav.Link as={NavLink} to="/dispatch" eventKey={"3"}>
                  Dispatch
                </Nav.Link>
                <Nav.Link as={NavLink} to="/anonymous-order" eventKey={"4"}>
                  A. Order
                </Nav.Link>
                <Nav.Link as={NavLink} to="/products" eventKey={"5"}>
                  Products
                </Nav.Link>
                <Nav.Link as={NavLink} to="/orderHistory" eventKey={"6"}>
                  Order History
                </Nav.Link>
                <Nav.Link as={NavLink} to="/orderItemHistory" eventKey={"7"}>
                  Order Item History
                </Nav.Link>
                <Nav.Link as={NavLink} to="/accounts/wallet" eventKey={"8"}>
                  Accounts
                </Nav.Link>
                <Nav.Link as={NavLink} to="/tracking" eventKey={"10"}>
                  Tracking
                </Nav.Link>
                <Nav.Link as={NavLink} to="/config/email" eventKey={"9"}>
                  Config
                </Nav.Link>
                <Nav.Link as={NavLink} to="/label" eventKey={"11"}>
                  Label
                </Nav.Link>
              </>
            )}

            {user?.role === "accountant" && (
              <>
                <Nav.Link as={NavLink} to="/" eventKey={"1"}>
                  Users
                </Nav.Link>
                <Nav.Link as={NavLink} to="/placeOrder" eventKey={"2"}>
                  Place Order
                </Nav.Link>
                <Nav.Link as={NavLink} to="/anonymous-order" eventKey={"4"}>
                  A. Order
                </Nav.Link>
                <Nav.Link as={NavLink} to="/products" eventKey={"5"}>
                  Products
                </Nav.Link>
                <Nav.Link as={NavLink} to="/orderHistory" eventKey={"6"}>
                  Order History
                </Nav.Link>
                <Nav.Link as={NavLink} to="/orderItemHistory" eventKey={"7"}>
                  Order Item History
                </Nav.Link>
                <Nav.Link as={NavLink} to="/accounts/wallet" eventKey={"8"}>
                  Accounts
                </Nav.Link>
                <Nav.Link as={NavLink} to="/config/email" eventKey={"9"}>
                  Config
                </Nav.Link>
              </>
            )}

            {user?.role === "client" && (
              <>
                <Nav.Link as={NavLink} to="/" eventKey={"2"}>
                  {t("Place Order")}
                </Nav.Link>
                <Nav.Link as={NavLink} to="/orderHistory" eventKey={"6"}>
                  {t("Order History")}
                </Nav.Link>
                <Nav.Link as={NavLink} to="/orderItemHistory" eventKey={"7"}>
                  {t("Order Item History")}
                </Nav.Link>
                <Nav.Link as={NavLink} to="/support" eventKey={"10"}>
                  {t("Support")}
                </Nav.Link>
                {/* language selector dropdown */}

                <NavDropdown title={languageOptions[i18n.language] || languageOptions["en-US"]} id="collapsible-nav-dropdown">
                  {
                    Object.keys(languageOptions).map((key) => (
                      <NavDropdown.Item key={key} onClick={() => changeLanguage(key)}>{languageOptions[key]}</NavDropdown.Item>
                    ))
                  }
                </NavDropdown>
              </>
            )}
            {user?.role === "dispatch" && (
              <>
                <Nav.Link as={NavLink} to="/" eventKey={"3"}>
                  Dispatch
                </Nav.Link>
                <Nav.Link as={NavLink} to="/label" eventKey={"11"}>
                  Label
                </Nav.Link>
              </>
            )}
            {user?.role === "dispatch_manager" && (
              <>
                <Nav.Link as={NavLink} to="/" eventKey={"3"}>
                  Dispatch
                </Nav.Link>
                <Nav.Link as={NavLink} to="/placeOrder" eventKey={"2"}>
                  Place Order
                </Nav.Link>
                <Nav.Link as={NavLink} to="/anonymous-order" eventKey={"4"}>
                  A. Order
                </Nav.Link>
                <Nav.Link as={NavLink} to="/products" eventKey={"5"}>
                  Products
                </Nav.Link>
                <Nav.Link as={NavLink} to="/orderHistory" eventKey={"6"}>
                  Order History
                </Nav.Link>
                <Nav.Link as={NavLink} to="/orderItemHistory" eventKey={"7"}>
                  Order Item History
                </Nav.Link>
                <Nav.Link as={NavLink} to="/label" eventKey={"11"}>
                  Label
                </Nav.Link>
              </>
            )}

            <NavDropdown title={user ? user.name : "Login"} align="end" id="collapsible-nav-dropdown">
              {/* <NavDropdown.Item>Action</NavDropdown.Item>
              <NavDropdown.Item>
                Another action
              </NavDropdown.Item> */}
              <NavDropdown.Item onClick={handleShow}>
                {t("change password")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <Container>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label={t("Dark Mode")}
                  checked={toggle}
                  onChange={() => {
                    setToggle(!toggle);
                    dispatch(setMode());
                  }}
                />
              </Container>
              {/* {user?.role === "client" && (
                <NavDropdown.Item as={NavLink} to="/wallet-details">
                  Wallet
                </NavDropdown.Item>
              )} */}
              <NavDropdown.Item onClick={logoutUser}>{t("Logout")}</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("change password")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>{t("Current Password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("enter current password")}
                autoFocus
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("New Password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("enter new password")}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("Confirm Password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("Confirm Password")}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Check
              type='checkbox'
              label="Logout from other devices"
              checked={logoutOtherDevices}
              onChange={(e) =>
                setLogoutOtherDevices(e.target.checked)
              }
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button variant="primary" onClick={savePassword}>
            {t("Save Changes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Navbar>
  );
}

export default Navigation;
