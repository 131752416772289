import React, { useEffect, useState } from "react";
import  Button from "react-bootstrap/Button";
import  Container from "react-bootstrap/Container";
import  Form from "react-bootstrap/Form";
import  Modal from "react-bootstrap/Modal";
import  Table  from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { errorToast, successToast } from "../../../Services/toastService";
import "./shipment.css";
import { addShipment, getShipment, updateShipment } from "../../../Services/shipmentService.js";

const Shipment = () => {
  const [modalShow, setModalShow] = useState(false);
  // const [priceModal, setPriceModal] = useState(false);
  const [updateSelected, setUpdatedSelected] = useState(false);
  const token = useSelector((state) => state.token);
  // const [priceSlabTable, setPriceSlabTable] = useState(null);
  const [shipment, setShipment] = useState({
    id:"",
    name: "",
    code: "",
  });
  const [shipmentTable, setShipmentTable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleModal = () => {
    setModalShow(!modalShow);
  };

  // const handlePriceModal = (item) => {
  //   setPriceModal(true);
  //   setPriceSlabTable(item);
  // };

  // const handlePriceModalClose = (item) => {
  //   setPriceModal(false);
  //   setPriceSlabTable(null);
  // };

  const getShipmentList = async () => {
    try {
      const res = await getShipment(token);
      setShipmentTable(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const addShipmentBtn = async () => {
    try {
      setIsLoading(true);
      if (
        !shipment.code.trim() |
        !shipment.name.trim()
      ) {
        setIsLoading(false);
        return errorToast("Please fill all Fields");
      }
      const res = await addShipment(token, shipment);
      if (res.status === 200) {
        successToast("Added Successfully");
        handleModal();
        getShipmentList();
        setShipment({
          name: "",
          code: "",
        });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if(err.response?.status === 400){
        errorToast(err.response?.data?.message)
      }else{
        errorToast("Something went wrong");
      }
    }
  };

  const updateDialog = async () => {
    try {
      setIsLoading(true);
      if (!shipment.name.trim()) {
        setIsLoading(false);
        return errorToast("Please Fill up all fields");
      }
      const res = await updateShipment(token,shipment);
      if (res.status === 200) {
        successToast("Updated Successfully");
        handleModalClose();
        getShipmentList();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      errorToast("Something went wrong");
    }
  };

  const handleUpdateBtn = async (item) => {
    setUpdatedSelected(true);
    setShipment({
      id: item.id,
      name: item.name,
      code: item.code,
    });
    handleModal();
  };

  const handleModalClose = () => {
    setModalShow(false);
    setUpdatedSelected(false);
    setShipment({
      id: "",
      name: "",
      code: "",
    });
  };
  // const generateTsvFile = () => {
  //   const headerRow = ["Start", "End", "Price"].join("\t");
  //   const dataRows = priceSlabTable.map((item) =>
  //     [item.start, item.end, item.price].join("\t")
  //   );
  //   const tsvContent = [headerRow, ...dataRows].join("\n");
  //   const blob = new Blob([tsvContent], { type: "text/tsv" });
  //   const url = URL.createObjectURL(blob);
  //   return url;
  // };

  // const handleDownloadFile = () => {
  //   const downloadLink = document.createElement("a");
  //   const url = generateTsvFile();
  //   downloadLink.href = url;
  //   downloadLink.download = "Price_Slab_Data.tsv";
  //   downloadLink.click();
  //   URL.revokeObjectURL(url);
  // };

  useEffect(() => {
    getShipmentList();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className="mb-2">
        <Button
          className="me-2"
          variant="outline-secondary"
          id="button-addon1"
          onClick={handleModal}
        >
          <i className="fa-solid fa-plus"></i> Add
        </Button>
      </div>

      {shipmentTable && shipmentTable.length && (
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              {/* <th>Price Slab</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {shipmentTable.map((item, index) => (
              <tr key={index}>
                <td>{item.code}</td>
                <td>{item.name}</td>
                {/* <td
                  className="price_slab"
                  onClick={() => handlePriceModal(item.priceSlabs)}
                  role="button"
                >
                  View Price Slabs
                </td> */}
                <td key={item.id}>
                  <Button
                    className="me-2"
                    size="sm"
                    variant="primary"
                    onClick={() => handleUpdateBtn(item)}
                  >
                    Update
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* modal for adding and updating data */}

      <Modal show={modalShow} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {updateSelected ? "Update Shipment" : "Add Shipment"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Form.Group className="mb-3">
              <Form.Label>Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Code"
                value={shipment.code}
                onChange={(e) =>
                  setShipment({
                    ...shipment,
                    code: e.target.value.toUpperCase(),
                  })
                }
                disabled={updateSelected}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={shipment.name}
                onChange={(e) =>
                  setShipment({ ...shipment, name: e.target.value })
                }
              />
            </Form.Group>
            {/* <Form.Group controlId="formFile" className="file-inp">
              <Form.Label>Price slab file</Form.Label>
              <Form.Control
                type="file"
                accept=".xlsx, .tsv, .xls, .txt"
                onChange={(e) => {
                  setShipment({ ...shipment, shipmentFile: e.target.files[0] });
                }}
              />
            </Form.Group> */}
          </Form>
            {/* <div className="mt-2">
              <a
                className="text-danger download_Link"
                href="/Book 6.xlsx"
                download="Price Slab Sample File"
              >
                Download Sample File
              </a>
            </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={updateSelected ? updateDialog : addShipmentBtn}
            disabled={isLoading}
          >
            {updateSelected?"Update":"Add"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* price shipment Modal */}
      {/* <Modal show={priceModal} onHide={handlePriceModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Price Slabs</Modal.Title>
        </Modal.Header>
        <Modal.Body className="price_modal">
          {
            <div className="mb-2">
              <small
                className="text-danger download_Link"
                download="Price Slab Sample File"
                onClick={handleDownloadFile}
              >
                Download File
              </small>
            </div>
          }
          {priceSlabTable && priceSlabTable.length && (
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>Start</th>
                  <th>end</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {priceSlabTable.map((item, index) => (
                  <tr key={index}>
                    <td>{item.start}</td>
                    <td>{item.end}</td>
                    <td>{item.price}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handlePriceModalClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal> */}
    </Container>
  );
};

export default Shipment;
