import React, { useEffect, useState } from "react";
import  Button from "react-bootstrap/Button";
import  Container from "react-bootstrap/Container";
import  Form from "react-bootstrap/Form";
import  Modal from "react-bootstrap/Modal";
import  Table  from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { errorToast, infoToast, successToast } from "../../../Services/toastService";
import "./shipmentPriceSlab.css";
import {
  addPriceSlab,
  getCountryList,
  getPriceSlabList,
  getShipment,
  updatePriceSlab,
} from "../../../Services/shipmentService";

const ShipmentPriceSlab = () => {
  const [modalShow, setModalShow] = useState(false);
  const [priceModal, setPriceModal] = useState(false);
  const [updateSelected, setUpdatedSelected] = useState(false);
  const token = useSelector((state) => state.token);
  const [priceSlabTable, setPriceSlabTable] = useState(null);
  const [shipment, setShipment] = useState({
    countryId: "",
    shipmentTypeId: "",
    slabs: null,
    applicableTax: 0,
  });
  const [shipmentTable, setShipmentTable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [codeList, setCodeList] = useState({});
  const [countryList, setCountryList] = useState({});

  const handleModal = () => {
    setModalShow(!modalShow);
  };

  const handlePriceModal = (item) => {
    setPriceModal(true);
    setPriceSlabTable(item);
  };

  const handlePriceModalClose = () => {
    setPriceModal(false);
    setPriceSlabTable(null);
  };

  const getShipmentList = async () => {
    try {
      const res = await getShipment(token);
      let codeObj = {};
      res.data.forEach((item) => {
        codeObj[item.id] = item.name;
      });
      setCodeList(codeObj);
    } catch (err) {
      errorToast("something went wrong");
    }
  };

  const addShipmentBtn = async () => {
    try {
      setIsLoading(true);
      if (!shipment.countryId | !shipment.shipmentTypeId | !shipment.slabs) {
        setIsLoading(false);
        return errorToast("Please select all Field");
      }
      const formData = new FormData();
      formData.append("slabs", shipment.slabs);
      formData.append("countryId", shipment.countryId);
      formData.append("shipmentTypeId", shipment.shipmentTypeId);
      formData.append("applicableTax", shipment.applicableTax);
      const res = await addPriceSlab(token, formData);
      if (res.status === 200) {
        successToast("Added Successfully");
        handleModal();
        getPriceSlabs();
        setShipment({
          countryId: "",
          shipmentTypeId: "",
          slabs: null,
          applicableTax: 0,
        });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      infoToast(err.response?.data?.message || "Something Went Wrong");
    }
  };

  const updateDialog = async () => {
    try {
      setIsLoading(true);
      if (!shipment.countryId | !shipment.shipmentTypeId) {
        setIsLoading(false);
        return errorToast("Please Fill up all fields");
      }
      const formData = new FormData();
      formData.append("slabs", shipment.slabs);
      formData.append("countryId", shipment.countryId);
      formData.append("shipmentTypeId", shipment.shipmentTypeId);
      formData.append("applicableTax", shipment.applicableTax);
      const res = await updatePriceSlab(token, formData);
      if (res.status === 200) {
        successToast("Updated Successfully");
        handleModalClose();
        getPriceSlabs();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      infoToast(err.response?.data?.message || "Something Went Wrong");
    }
  };

  const handleUpdateBtn = async (item) => {
    setUpdatedSelected(true);
    setShipment({
      countryId: item.countryId,
      shipmentTypeId: item.typeId,
      slabs: null,
      applicableTax: item.applicableTax,
    });
    handleModal();
  };

  const handleModalClose = () => {
    setModalShow(false);
    setShipment({
      countryId: "",
      shipmentTypeId: "",
      slabs: null,
      applicableTax: 0,
    });
    setUpdatedSelected(false);
  };
  const generateTsvFile = () => {
    const headerRow = ["Start", "End", "Price"].join("\t");
    const dataRows = priceSlabTable.map((item) =>
      [item.start, item.end, item.price].join("\t")
    );
    const tsvContent = [headerRow, ...dataRows].join("\n");
    const blob = new Blob([tsvContent], { type: "text/tsv" });
    const url = URL.createObjectURL(blob);
    return url;
  };

  const handleDownloadFile = () => {
    const downloadLink = document.createElement("a");
    const url = generateTsvFile();
    downloadLink.href = url;
    downloadLink.download = "Price_Slab_Data.tsv";
    downloadLink.click();
    URL.revokeObjectURL(url);
  };

  const getAllCountryList = async () => {
    try {
      const res = await getCountryList(token);
      let countryObj = {};
      res.data.countries.map((item) => (countryObj[item.id] = item.name));
      setCountryList(countryObj);
    } catch (err) {
      errorToast("something went wrong");
    }
  };

  const getPriceSlabs = async () => {
    try {
      const res = await getPriceSlabList(token);
      setShipmentTable(res.data.data);
    } catch (error) {
      errorToast("something went wrong");
    }
  };

  useEffect(() => {
    getShipmentList();
    getAllCountryList();
    getPriceSlabs();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className="mb-2">
        <Button
          className="me-2"
          variant="outline-secondary"
          id="button-addon1"
          onClick={handleModal}
        >
          <i className="fa-solid fa-plus"></i> Add
        </Button>
      </div>

      {!!shipmentTable && !!shipmentTable.length && (
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Shipment Type</th>
              <th>Country</th>
              <th>Applicable Tax</th>
              <th>Price Slab</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {shipmentTable.map((item, index) => (
              <tr key={index}>
                <td>{item.shipmentType?.name}</td>
                <td>{item.country?.name}</td>
                <td>{item.applicableTax}</td>
                <td
                  className="price_slab"
                  onClick={() => handlePriceModal(item.priceSlabs)}
                  role="button"
                >
                  View Price Slabs
                </td>
                <td key={item.id}>
                  <Button
                    className="me-2"
                    size="sm"
                    variant="primary"
                    onClick={() => handleUpdateBtn(item)}
                  >
                    Update
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* modal for adding and updating data */}

      <Modal show={modalShow} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {updateSelected ? "Update Shipment" : "Add Shipment"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Form.Group className="mb-3">
              <Form.Label>Shipment Type</Form.Label>
              <Form.Select
                aria-label="Shipment Code List"
                value={shipment.shipmentTypeId}
                onChange={(e) =>
                  setShipment({ ...shipment, shipmentTypeId: e.target.value })
                }
              >
                <option value="">Select Shipment Type</option>
                {Object.keys(codeList).map((key) => (
                  <option key={key} value={key}>
                    {codeList[key]}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Select
                aria-label="Country List"
                value={shipment.countryId}
                onChange={(e) =>
                  setShipment({ ...shipment, countryId: e.target.value })
                }
              >
                <option value="">Select Country</option>
                {Object.keys(countryList).map((key) => (
                  <option key={key} value={key}>
                    {countryList[key]}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Applicable Tax (Optional)</Form.Label>
              <Form.Control
                required
                type="text"
                value={shipment.applicableTax}
                onChange={(e) =>
                  setShipment({
                    ...shipment,
                    applicableTax: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="file-inp">
              <Form.Label>Price slab file</Form.Label>
              <Form.Control
                type="file"
                accept=".xlsx, .tsv, .xls, .txt"
                onChange={(e) => {
                  setShipment({ ...shipment, slabs: e.target.files[0] });
                }}
              />
            </Form.Group>
          </Form>
          <div className="mt-2">
            <a
              className="text-danger download_Link"
              href="/Book 6.tsv"
              download="Price Slab Sample File"
            >
              Download Sample File
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={updateSelected ? updateDialog : addShipmentBtn}
            disabled={isLoading}
          >
            {updateSelected ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* price shipment Modal */}
      <Modal show={priceModal} onHide={handlePriceModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Price Slabs</Modal.Title>
        </Modal.Header>
        <Modal.Body className="price_modal">
          {
            <div className="mb-2">
              <small
                className="text-danger download_Link"
                download="Price Slab Sample File"
                onClick={handleDownloadFile}
              >
                Download File
              </small>
            </div>
          }
          {priceSlabTable && priceSlabTable.length && (
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>Start</th>
                  <th>end</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {priceSlabTable.map((item, index) => (
                  <tr key={index}>
                    <td>{item.start}</td>
                    <td>{item.end}</td>
                    <td>{item.price}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handlePriceModalClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ShipmentPriceSlab;
